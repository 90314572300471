/* eslint-disable max-len */
const pathUrl = {
  home: () => '/',
  videoDetail: (videoId = ':videoID') => `/videos/${videoId}`,
  videoByCategory: (videoCategory = ':category', videoID = ':videoID') => `/categories/${videoCategory}/${videoID}`,
  videoCategory: (videoCategory = ':category') => `/categories/${videoCategory}`,
  overview: () => `/overview`,
  upgradeAccount: () => `/upgrade-account`,
  subscriptionsPlans: (planId = ':planId') => `/plans/${planId}`,
  registrationSLO: () => `/slo/registration`,
  classroom: () => `/slo/classroom`,
  lessons: (id?: string) => `/slo/classroom/${id ?? ':id'}`,
  learnVideo: (classId = ':classID', videoId = ':videoID') => `/slo/classroom/${classId}/${videoId}`,
  signup: () => '/signup',
  emailVerification: () => '/email-verification',
  passwordReset: () => '/password-reset',
  forgetPassword: () => '/forget-password',
}

export default pathUrl
