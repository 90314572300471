import React from 'react'

type Props = {
  title: string
  renderRight?: () => React.ReactNode
}

const ViewTitle: React.FC<Props> = ({ title, renderRight }) => {
  return (
    <div className='bg-[#F9F9F9] flex flex-row sm:flex-col justify-between py-4 px-7 rounded-t-[10px]'>
      <div className='font-medium text-lg self-center text-[#030303] h-[32px] flex justify-center items-center'>
        {title}
      </div>
      <div className='flex justify-center'>{renderRight && renderRight()}</div>
    </div>
  )
}

export default ViewTitle
