import * as React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { ButtonProps } from 'antd/lib/button'

function PrimaryBtn(props: ButtonProps) {
  return (
    <Button type='primary' {...props}>
      {props.children || 'Submit'}
    </Button>
  )
}

export default PrimaryBtn
