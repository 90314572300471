import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'

import userInfoAtom from '@recoil/userInfoAtom'
import appModalAtom from '@recoil/appModalAtom'
import { ModalType } from 'types/enum'
import { UserType } from 'types'
import pathUrl from '@routes/path'

const SignUp: React.FC = () => {
  const history = useHistory()
  const [userInfo] = useRecoilState(userInfoAtom)
  const [, setAppModal] = useRecoilState(appModalAtom)

  useEffect(() => {
    if (!userInfo?.email) {
      setAppModal({
        mode: ModalType.signUp,
        data: {
          onCancel: async (userData?: UserType, params?: any) => {
            if (userData?.email || params?.isCloseSignUpSuccess) {
              setAppModal({ mode: ModalType.close })
              history.push(pathUrl.home())
            }
          },
        },
      })
    } else {
      history.push(pathUrl.home())
    }
  }, [])

  return <></>
}

export default SignUp
