import { useMemo } from 'react'
import userInfoAtom from '@recoil/userInfoAtom'
import { useRecoilState } from 'recoil'

import { isSameDay } from '@utils/time'
import { DEMO_ACCOUNT } from '@constants/constants'
import demoAccountAtom from './recoil/demoAccountAtom'

const currentDateTime = new Date().toISOString()

export const useVideoDemoAccount = () => {
  const [userInfo] = useRecoilState(userInfoAtom)
  const [demoAccountState, seDemoAccountState] = useRecoilState(demoAccountAtom)

  const isDemoAccount = userInfo?.email === DEMO_ACCOUNT.email

  const updateMinutesUsed = (minutes: number) => {
    seDemoAccountState(({ date, numberOfMinutesUsed }) => {
      let currentDateData = date ?? currentDateTime
      let currentMinutesUsed = numberOfMinutesUsed ?? 0

      if (isSameDay(currentDateData, currentDateTime)) {
        currentMinutesUsed += minutes
      } else {
        currentDateData = currentDateTime
        currentMinutesUsed = minutes
      }

      return { date: currentDateData, numberOfMinutesUsed: currentMinutesUsed }
    })
  }

  const isAvailableUpdate = useMemo(() => {
    if (!userInfo?.customer?.minutes_limit) {
      return true
    }

    return (
      userInfo?.customer?.minutes_limit > 0 &&
      userInfo?.customer?.minutes_limit > (demoAccountState?.numberOfMinutesUsed ?? 0)
    )
  }, [userInfo?.customer, demoAccountState?.numberOfMinutesUsed])

  return {
    updateMinutesUsed,
    isAvailableUpdate,
    isDemoAccount,
  }
}
