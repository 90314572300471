export default {
  'login.sign_in': 'Đăng nhập',
  'login.customer_code': 'Mã thành viên',
  'login.emailLogin': 'Email',
  'login.emailLoginPlaceholder': 'Nhập email',
  'login.passwordLogin': 'Mật khẩu',
  'login.passwordLoginPlaceholder': 'Nhập Mật khẩu',
  'login.rePasswordLogin': 'Xác nhận mật khẩu',
  'login.rePasswordLoginPlaceholder': 'Nhập Lại Mật khẩu',
  'login.notMatch': 'Mật khẩu mới bạn nhập không khớp!',
  'login.passMin8': 'Mật khẩu bao gồm tối thiểu 8 kí tự.',
  'login.forgetPassword': 'Quên mật khẩu?',
  'login.no_user_code': 'Bạn chưa có tài khoản?',
  'login.reset_password': 'Đặt lại mật khẩu',
  'login.sign_up': 'Đăng ký',
  'login.last_name': 'Họ',
  'login.first_name': 'Tên',
  'login.email_address': 'Địa chỉ email',
  'login.phone': 'Số điện thoại',
  'login.referral_code': 'Mã giới thiệu',
  'login.referral_code_sentences': 'Nhập mã giới thiệu để được thưởng điểm hoặc bỏ qua',
  'login.have_user_code': 'Bạn đã có tài khoản?',
  'login.user_not_found': 'Địa chỉ email không tồn tại!',
  'login.invalid_authentication': 'Thông tin đăng nhập không chính xác',
  'auth.passwordNotStrongEnough': 'Mật khẩu không đủ mạnh',
  'login.sign_up_button': 'Đăng ký',
  'login.signUpSuccess': 'Đăng ký thành công',
  'login.ok': 'Đồng ý',
  'login.checkEmail': 'Vui lòng kiểm tra email để lấy mã đăng nhập',
  'login.inSpam': 'Trong phần hộp thư đến hoặc trong Spam',
  'login.referralCodeOrSkip': 'Nhập mã giới thiệu để được thưởng điểm hoặc bỏ qua',
  'login.email': 'Email không chính xác',
  'thank.noti': 'Chúng tôi sẽ gửi thông báo điểm thưởng và giá trị quy đổi vào email của bạn.',
  'thank.close': 'Đóng',
  'thank.more': 'Xem thêm video',
  'history.history_purchase': 'Lịch sử mua hàng',
  'history.empty': 'Bạn chưa đặt sản phẩm nào',
}
