import React from 'react'
import { SvgIconProps } from './type'

const VideoPlay: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#030303' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7503 0C13.1977 0.0610873 13.6467 0.111861 14.0921 0.184762C18.7788 0.952409 22.7397 4.73682 23.7157 9.38621C24.4086 12.6876 23.8351 15.7586 22.0103 18.5961C21.7811 18.9524 21.4606 19.1248 21.0386 19.0652C20.6522 19.0108 20.3608 18.7221 20.2723 18.3363C20.2046 18.0411 20.2978 17.7853 20.4583 17.5405C21.1101 16.5459 21.5745 15.4708 21.8523 14.3131C22.7923 10.3965 21.2489 6.21014 17.9876 3.84123C16.0602 2.4412 13.9033 1.79 11.5268 1.88517C6.88097 2.0713 2.9012 5.54413 2.0517 10.1398C1.11762 15.1934 4.07682 20.1382 8.98176 21.6568C11.8136 22.5336 14.5339 22.2018 17.1127 20.7385C17.3643 20.5957 17.6148 20.4854 17.9108 20.5509C18.2962 20.6363 18.5842 20.9333 18.6487 21.3109C18.7145 21.6954 18.5419 22.0669 18.1842 22.284C17.2037 22.8791 16.1568 23.3215 15.0472 23.6083C8.68293 25.2533 2.24134 21.5738 0.454662 15.2534C0.245943 14.5149 0.176464 13.7371 0.0422407 12.9777C0.0288325 12.9018 0.0140646 12.8261 0 12.7503C0 12.2502 0 11.7503 0 11.2502C0.0135489 11.1823 0.0300983 11.1147 0.0401779 11.0463C0.122128 10.491 0.17351 9.92943 0.2887 9.38119C1.26591 4.73218 5.22064 0.955878 9.90894 0.184621C10.3541 0.111439 10.803 0.0610404 11.2502 0C11.7503 0 12.2503 0 12.7503 0Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.01582 12.0055C8.01582 11.1231 8.01404 10.2409 8.01624 9.35859C8.02027 7.74234 9.62392 6.80315 11.0223 7.60511C12.5446 8.4781 14.063 9.35789 15.5771 10.2449C17.0132 11.0862 17.0068 12.9234 15.5672 13.7631C14.0648 14.6393 12.5592 15.5102 11.0517 16.3776C9.62518 17.1984 8.01929 16.2713 8.01614 14.6289C8.01436 13.7544 8.01582 12.8799 8.01582 12.0055ZM9.89086 11.9792C9.89086 12.8457 9.88997 13.7122 9.89143 14.5787C9.89185 14.8346 9.93432 14.858 10.1598 14.7277C11.6382 13.8731 13.1164 13.0179 14.5938 12.1615C14.828 12.0258 14.8271 11.9738 14.5896 11.8362C13.1119 10.9802 11.6338 10.125 10.1552 9.27055C9.93268 9.142 9.89176 9.16619 9.89138 9.42657C9.89002 10.2774 9.89086 11.1283 9.89086 11.9792Z'
        fill='black'
      />
    </svg>
  )
}

export default VideoPlay
