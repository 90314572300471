/* eslint-disable max-len */
export default {
  'video.related_video': 'Video liên quan',
  'video.only_score_message': 'Bạn nhận được <highlight>{{scoreCorrect}}</highlight> điểm/mỗi lượt bấm đúng',
  'video.only_score_message_sponsor': 'Bạn nhận được <highlight>{{scoreCorrect}}</highlight> đồng/mỗi lượt bấm đúng',
  'video.score_message_sponsor':
    'Bạn nhận được <highlight>{{scoreCorrect}}</highlight> đồng/mỗi lượt bấm đúng và <highlight>{{scoreIncorrect}}</highlight> đồng cho mỗi câu trả lời quiz đúng',
  'video.score_message':
    'Bạn nhận được <highlight>{{scoreCorrect}}</highlight> điểm/mỗi lượt bấm đúng và <highlight>{{scoreIncorrect}}</highlight> điểm cho mỗi câu trả lời quiz đúng',
  'video.minus_score': 'Sai rồi! Bạn vừa bị trừ {{score}}',
  'video.play_video': 'Ấn xem video để có thể nhận thưởng',
  'video.bonus_score': 'Thật tuyệt! Bạn vừa nhận thêm {{score}}',
  'video.quiz_label': 'Câu hỏi',
  'video.quiz_submit': 'Trả lời',
  'video.product': 'Bạn có {{currency}} VNĐ (Sử dụng ngay)',
  'video.other_video': 'Video khác',
  'video.change_question': 'Đổi câu hỏi',
  'video.previous_results': '(Kết quả trước: Mức độ tập trung {{concentrationLevel}}%, {{point}} DV)',
  'video.watched_message': 'Bạn đã xem xong nội dung',
  'video.total_score': 'Tổng điểm đạt được là:',
  'video.score': 'điểm',
  'video.Score': 'Điểm',
  'video.vnd': 'đồng',
  'video.continue': 'Tiếp tục',
  'video.more_detail': 'Xem chi tiết',
  'video.hidden_detail': 'Ẩn chi tiết',
  'video.result': 'Kết quả:  {{score1}} điểm + {{score2}} điểm (Trả lời câu hỏi Quiz)',
  'video.warning_message': 'Bạn có thể xem lại hoặc báo điểm.',
  'video.watch_again': 'Xem lại video',
  'video.re_watch': 'Xem lại',
  'video.skip': 'bỏ qua',
  'video.watch_other_video': 'xem video khác',
  'video.register': 'đăng ký',
  'video.login': 'đăng nhập',
  'video.suggestLogin': 'Để ghi nhận điểm, vui lòng đăng ký tài khoản hoặc ',
  'video.point_sended': 'Điểm của bạn đã được ghi nhận',
  'video.error_demo_account': 'Vui lòng đăng nhập tài khoản khác demo',
  'video.stt': 'Số thứ tự',
  'video.time': 'Thời điểm',
  'video.warning_score': 'Câu hỏi này đã từng được tính điểm. Nếu bạn Đồng ý điểm mới sẽ được ghi đè!',
  'video.cancel': 'Hủy',
  'video.agree': 'Đồng ý',
  'video.completed_message': 'Chúng tôi sẽ gửi thông báo điểm thưởng và giá trị quy đổi vào email của bạn',
  'video.close': 'Đóng',
  'video.watch_more': 'Xem thêm video',
  'video.concentrationWarning':
    'Video này yêu cầu mức độ tập trung {{concentrationLevel}}%, nếu thấp hơn mức này, bạn cần xem lại video',
  'video.scored': 'Câu hỏi này đã được tính điểm. Bạn có thể',
  'video.previousResult': '(Kết quả trước: Mức độ tập trung {{concentrationLevel}}%, {{score}} DV)',
  'video.resulted': 'Kết quả đã được tính',
  'video.correct': 'Chính xác.',
  'video.incorrect': 'Không chính xác.',
  'video.reportScore': 'Báo điểm',
  'video.resultReport': 'Kết quả',
  'video.quiz': 'trả lời Quiz',
  'video.youReceive': 'Bạn nhận được',
  'video.concentration_level': 'Mức độ tập trung',
  'video.showRequire': 'Mức độ tập trung của bạn thấp hơn mức độ yêu cầu ({{concentrationLevel}}%)',
  'video.canReview': 'bạn có thể xem lại để cải thiện mức độ tập trung',
  'video.continueVideoQuestion': 'Bạn muốn xem tiếp video?',
  'video.continueVideoNote': 'Tổng điểm đã đạt vẫn được ghi nhận khi xem tiếp.',
  'video.okContinue': 'Xem tiếp',
  'video.cancelContinue': 'Xem từ đầu',
}
