import React from 'react'
import { SvgIconProps } from './type'

const Registration: React.FC<SvgIconProps> = ({ height = 72, width = 176, color = '#E2EFFC' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 176 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1977_32048)'>
        <path
          d='M36 38.5C42.2109 38.5 47.25 33.4609 47.25 27.25C47.25 21.0391 42.2109 16 36 16C29.7891 16 24.75 21.0391 24.75 27.25C24.75 33.4609 29.7891 38.5 36 38.5ZM46 41H41.6953C39.9609 41.7969 38.0312 42.25 36 42.25C33.9688 42.25 32.0469 41.7969 30.3047 41H26C20.4766 41 16 45.4766 16 51V52.25C16 54.3203 17.6797 56 19.75 56H52.25C54.3203 56 56 54.3203 56 52.25V51C56 45.4766 51.5234 41 46 41Z'
          fill={color}
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68 36C68 35.6211 68.1505 35.2578 68.4184 34.9899C68.6863 34.722 69.0496 34.5715 69.4285 34.5715H103.121L94.1298 25.5835C93.8615 25.3153 93.7108 24.9515 93.7108 24.5722C93.7108 24.1928 93.8615 23.829 94.1298 23.5608C94.398 23.2926 94.7618 23.1419 95.1411 23.1419C95.5205 23.1419 95.8843 23.2926 96.1525 23.5608L107.58 34.9886C107.713 35.1213 107.819 35.279 107.891 35.4525C107.963 35.6261 108 35.8121 108 36C108 36.1879 107.963 36.3739 107.891 36.5475C107.819 36.721 107.713 36.8787 107.58 37.0114L96.1525 48.4392C95.8843 48.7074 95.5205 48.8581 95.1411 48.8581C94.7618 48.8581 94.398 48.7074 94.1298 48.4392C93.8615 48.171 93.7108 47.8072 93.7108 47.4278C93.7108 47.0485 93.8615 46.6847 94.1298 46.4165L103.121 37.4285H69.4285C69.0496 37.4285 68.6863 37.278 68.4184 37.0101C68.1505 36.7422 68 36.3789 68 36Z'
        fill={color}
      />
      <g clipPath='url(#clip1_1977_32048)'>
        <path
          d='M120 20.4444V51.5556C120 52.7343 120.468 53.8648 121.302 54.6983C122.135 55.5317 123.266 56 124.444 56H155.556C158 56 160 54 160 51.5556V20.4444C160 18 158 16 155.556 16H124.444C123.266 16 122.135 16.4683 121.302 17.3017C120.468 18.1352 120 19.2657 120 20.4444ZM146.667 29.3333C146.667 33.0222 143.689 36 140 36C136.311 36 133.333 33.0222 133.333 29.3333C133.333 25.6444 136.311 22.6667 140 22.6667C143.689 22.6667 146.667 25.6444 146.667 29.3333ZM126.667 47.1111C126.667 42.6667 135.556 40.2222 140 40.2222C144.444 40.2222 153.333 42.6667 153.333 47.1111V49.3333H126.667V47.1111Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1977_32048'>
          <rect width='40' height='40' fill='white' transform='translate(16 16)' />
        </clipPath>
        <clipPath id='clip1_1977_32048'>
          <rect width='40' height='40' fill='white' transform='translate(120 16)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Registration
