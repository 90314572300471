import { apis, IVideoParams } from '@apis'
import Logger from '@managers/Logger'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { VideoType } from 'types'

const ITEM_PER_PAGE = 12

interface IParams {
  category?: string
}

export const useCategoryPage = () => {
  const params = useParams<IParams>()
  const { category } = params ?? {}

  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [videos, setVideos] = useState<VideoType[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const getApi = async (page = 1) => {
    try {
      setHasMore(false)
      if (page === 1) {
        setIsLoading(true)
      }

      const paramSearch: IVideoParams = {
        page_limit: ITEM_PER_PAGE,
        page,
      }

      if (category) {
        paramSearch.category = category
      }

      const response = await apis.getVideoList(paramSearch)

      setVideos((_data) => [..._data, ...response.videos])

      if (response?.videos?.length < ITEM_PER_PAGE) {
        setHasMore(false)
      } else {
        setCurrentPage(page + 1)
        setHasMore(true)
      }
    } catch (error) {
      Logger.error('getApi', error)
    } finally {
      if (page === 1) {
        setIsLoading(false)
      }
    }
  }

  const fetchMoreData = async () => {
    if (!hasMore || videos.length === 0) {
      return
    }

    await getApi(currentPage)
  }

  useEffect(() => {
    getApi(1)
  }, [])

  return {
    videos,
    hasMore,
    fetchMoreData,
    isLoading,
  }
}
