import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

interface AtomType {
  accessToken?: string
}

const authAtom = atom<AtomType>({
  key: 'authAtom',
  default: {
    accessToken: undefined,
  },
  effects_UNSTABLE: [persistAtom],
})

export default authAtom
