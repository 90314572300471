import { Col, Input, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { DatePickerBase } from '../../../components/Picker'
import Svg from '../../../components/Svg'
import ViewTitle from './ViewTitle'

import './HistoryDeepView.scss'
import SelectBase from '../../../components/Select/SelectBase'
import PaginationBase from '../../../components/Pagination/PaginationBase'
import EmptyBase from '../../../components/Empty/EmptyBase'

const dataSource = [
  {
    key: '1',
    name: 'Bài 10: VIDEO ĐÀO TẠO CỦA BIOSCOPE - LENTINEX (PART 10)',
    question: 'Hãy bấm vào nút "DEEPVIEW" ĐỂ NHẬN THƯỞNG mỗi khi nghe từ "EMAIL" và từ "TÀI KHOẢN"',
    point: '2418.2',
    time: '11-12-2022 12:03:11',
  },
  {
    key: '2',
    name: 'Bài 9: VIDEO ĐÀO TẠO CỦA BIOSCOPE - LENTINEX (PART 9)',
    question: 'Bài 9: VIDEO ĐÀO TẠO CỦA BIOSCOPE - LENTINEX (PART 9)',
    point: '2418.2',
    time: '11-12-2022 12:03:11',
  },
  {
    key: '3',
    name: 'Bài 8: VIDEO ĐÀO TẠO CỦA BIOSCOPE - LENTINEX (PART 8)',
    question: 'Hãy bấm vào nút "DEEPVIEW" ĐỂ NHẬN THƯỞNG mỗi khi nghe từ "EMAIL" và từ "TÀI KHOẢN"',
    point: '2418.2',
    time: '11-12-2022 12:03:11',
  },
]

const columns: ColumnsType<any> = [
  {
    title: 'Video',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Câu hỏi',
    dataIndex: 'question',
    sorter: (a: any, b: any) => a.question.length - b.question.length,
    key: 'question',
  },
  {
    title: 'Điểm số',
    dataIndex: 'point',
    sorter: (a: any, b: any) => a.point - b.point,
    key: 'point',
    width: '100px',
  },
  {
    title: 'Thời gian',
    dataIndex: 'time',
    sorter: (a: any, b: any) => a.time - b.time,
    key: 'time',
  },
]

const HistoryDeepView = () => {
  const renderPagination = () => {
    return (
      <div className='bg-[white] p-7 flex  justify-between w-full'>
        <div>
          <span>Số dòng trên trang</span>
          <SelectBase className='ml-3' />
        </div>
        <div className='w-[300px]'>
          <Input
            className='rounded-[10px]'
            placeholder='Tìm kiếm'
            suffix={
              <>
                <Svg.Search width={16} height={16} color='#606060' />
              </>
            }
          />
        </div>
      </div>
    )
  }

  const renderData = () => {
    return (
      <div className='mx-7 pb-7'>
        <EmptyBase description='Chưa có lịch sử xem'>
          <Table className='table-info' dataSource={dataSource} columns={columns} pagination={false} />
        </EmptyBase>

        <div className='flex justify-between mt-7'>
          <div className='flex justify-center items-center'>Hiển thị từ 1 đến 10 trong số 120 bài.</div>
          <div>
            <PaginationBase defaultCurrent={6} pageSize={4} total={500} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Row
      className='mt-5'
      gutter={[
        { xs: 16, lg: 30 },
        { xs: 16, lg: 0 },
      ]}
    >
      <Col span={24} className='gutter-row text-left mt-7'>
        <div className='bg-[#ffffff] rounded-[10px]'>
          <ViewTitle title='Lịch sử xem Deepview' renderRight={() => <DatePickerBase />} />
          {renderPagination()}
          {renderData()}
        </div>
      </Col>
    </Row>
  )
}

export default HistoryDeepView
