import request from '@utils/request'

type VerifyAccountResponse = {
  message: string
}

type RequestPasswordResetResponse = {
  message: 'PASSWORD_RESET_LINK_SENT' | 'EMAIL_NOT_FOUND'
}

export type IBodyUpdatePassword = {
  password: string
  password_confirmation: string
  reset_token: string
}

export type UpdatePasswordResponse = {
  message:
    | 'UPDATED_SUCCESSFULLY'
    | 'TOKEN_EXPIRED'
    | 'INVALID_TOKEN'
    | 'PASSWORD_CONFIRMATION_NOT_MATCH'
    | 'PASSWORD_NOT_STRONG_ENOUGH'
}

export const authApis = {
  verifyAccount: async (token: string): Promise<VerifyAccountResponse> => request.get(`/users/verify_account/${token}`),
  requestPasswordReset: async (email: string): Promise<RequestPasswordResetResponse> =>
    request.get(`/users/request_password_reset/${email}`),
  updatePassword: async (body: IBodyUpdatePassword): Promise<UpdatePasswordResponse> =>
    request.post(`/users/update_password`, body, false, { handleError: () => null }),
}
