import { CONFIG_APP } from '@constants/constants'
import VI from './vi'

const EN: typeof VI = {
  'home.home': 'Home',
  'home.about_us': 'About Us',
  'home.help': 'Help',
  'home.classroom': 'Classroom',
  'home.dashboard': 'Dashboard',
  'home.response': 'Feedback',
  'home.team': 'Team',
  'home.recruit': 'Recruit',
  'home.overview': 'Overview',
  'home.store': 'Store',
  'home.phone': 'Phone: 0779611553',
  'home.email': 'Email: admin@deepview.win',
  'home.chat': 'Chat: m.me/ilovedeepview',
  'home.deepview': `${CONFIG_APP.nameApp} in the newspaper`,
  'home.search': 'Search',
  'home.free': 'Free',
  'home.sponsor': 'Sponsor',
  'home.language': 'Language',
  'home.en': 'English',
  'home.vi': 'Vietnamese',
  'home.yourVideos': 'Your videos',
  'home.all': 'All',
}

export default EN
