import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { E_LANG } from '@lang/AppI18n'
import { useHistory } from 'react-router-dom'
import pathUrl from '@routes/path'

import MainButton from '@components/Button/MainButton'
import { formatNumber } from '@utils/format'
import userInfoAtom from '@recoil/userInfoAtom'

import { CONFIG_APP } from '@constants/constants'

import UsePointModal from '../../../../components/Modal/UsePointModal'
import detailVideoAtom from '../../../../recoil/detailVideoAtom'

type Props = {
  exchangePoints: boolean
}

const BoxUsedPointBase: React.FC<Props> = ({ exchangePoints }) => {
  const { i18n } = useTranslation()
  const history = useHistory()

  const [userInfo] = useRecoilState(userInfoAtom)
  const [detailVideo] = useRecoilState(detailVideoAtom)
  const [showUsePoint, setShowUsePoint] = useState(false)

  const { products = [] } = detailVideo ?? {}

  const bannerProduct = i18n.language === E_LANG.VI ? CONFIG_APP.bannerVI : CONFIG_APP.bannerEN
  const photoProduct = products[0]?.product_banner ?? bannerProduct

  const handleOpenUsePointModal = () => {
    setShowUsePoint(true)
  }

  const handleClickBanner = () => {
    history.push(pathUrl.registrationSLO())
  }

  const handleClose = () => {
    setShowUsePoint(false)
  }

  const renderPurchaseNow = () => {
    if (!detailVideo?.is_sponsor) return <></>

    return (
      <div className='timeBox'>
        <div className='countdownTime'>
          <div>
            Bạn có <span className='points'>{formatNumber(userInfo?.amount)}</span> VNĐ
          </div>
        </div>
        <MainButton onClick={handleOpenUsePointModal} className='pointBtn'>
          SỬ DỤNG NGAY
        </MainButton>
      </div>
    )
  }

  const renderBoxExchangePoint = () => {
    return (
      <div className='banner exchangePoints'>
        <div className='md:flex md:items-center md:justify-center'>
          <div className='exchangeImageWrapper min-h-[120px]' onClick={handleClickBanner}>
            <img className='exchangeImage min-h-[120px] md:h-[120px] cursor-pointer' src={photoProduct} alt='' />
          </div>
          {renderPurchaseNow()}
        </div>
      </div>
    )
  }

  const renderImageFocus = () => {
    return (
      <div className='banner' onClick={handleClickBanner}>
        <img className='bannerImage constant-tilt-shake' src={bannerProduct} alt='' />
      </div>
    )
  }

  return (
    <>
      {exchangePoints ? renderBoxExchangePoint() : renderImageFocus()}
      <UsePointModal open={showUsePoint} onCancel={handleClose} />
    </>
  )
}

const BoxUsedPoint = React.memo(BoxUsedPointBase)

export default BoxUsedPoint
