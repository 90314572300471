import Svg from '@components/Svg'
import React from 'react'

type Props = {
  icon: React.ReactNode
  title: React.ReactNode
  content: React.ReactNode
  onClose?: () => void
}

const MessageModal: React.FC<Props> = ({ icon, title, onClose, content }) => {
  return (
    <>
      <div>
        <div className='flex w-full'>
          <div>{icon}</div>
          <div className='flex-1 font-bold ml-3 mb-4'>{title}</div>
          <div className='cursor-pointer' onClick={onClose}>
            <Svg.Close />
          </div>
        </div>
        <div className='text-[#606060] text-center leading-7'>{content}</div>
      </div>
    </>
  )
}

export default MessageModal
