import { BaseModalProps } from '@components/Modal/BaseModal'
import React, { createRef } from 'react'
import ModalGlobal from './ModalGlobal'

export interface ModalProps {
  content: React.ReactNode
  onCancel?: () => void
  config?: BaseModalProps
}

export interface ModalMethods {
  showModal: (props: ModalProps) => void
  hideModal: () => void
}

export const modalRef = createRef<ModalMethods>()

export function showModal(modalContent: ModalProps) {
  modalRef.current?.showModal(modalContent)
}

export function hideModal() {
  modalRef.current?.hideModal()
}

export function ModalManager() {
  return <ModalGlobal />
}
