import React from 'react'
import { SvgIconProps } from './type'

const Date: React.FC<SvgIconProps> = ({ height = 25, width = 24, color = '#606060' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.625 3.5C9.24727 3.5 9.75 4.00273 9.75 4.625V5.75H14.25V4.625C14.25 4.00273 14.7527 3.5 15.375 3.5C15.9973 3.5 16.5 4.00273 16.5 4.625V5.75H18.1875C19.1191 5.75 19.875 6.50586 19.875 7.4375V9.125H4.125V7.4375C4.125 6.50586 4.88086 5.75 5.8125 5.75H7.5V4.625C7.5 4.00273 8.00273 3.5 8.625 3.5ZM4.125 10.25H19.875V19.8125C19.875 20.7441 19.1191 21.5 18.1875 21.5H5.8125C4.88086 21.5 4.125 20.7441 4.125 19.8125V10.25ZM6.375 13.0625V14.1875C6.375 14.4969 6.62812 14.75 6.9375 14.75H8.0625C8.37187 14.75 8.625 14.4969 8.625 14.1875V13.0625C8.625 12.7531 8.37187 12.5 8.0625 12.5H6.9375C6.62812 12.5 6.375 12.7531 6.375 13.0625ZM10.875 13.0625V14.1875C10.875 14.4969 11.1281 14.75 11.4375 14.75H12.5625C12.8719 14.75 13.125 14.4969 13.125 14.1875V13.0625C13.125 12.7531 12.8719 12.5 12.5625 12.5H11.4375C11.1281 12.5 10.875 12.7531 10.875 13.0625ZM15.9375 12.5C15.6281 12.5 15.375 12.7531 15.375 13.0625V14.1875C15.375 14.4969 15.6281 14.75 15.9375 14.75H17.0625C17.3719 14.75 17.625 14.4969 17.625 14.1875V13.0625C17.625 12.7531 17.3719 12.5 17.0625 12.5H15.9375ZM6.375 17.5625V18.6875C6.375 18.9969 6.62812 19.25 6.9375 19.25H8.0625C8.37187 19.25 8.625 18.9969 8.625 18.6875V17.5625C8.625 17.2531 8.37187 17 8.0625 17H6.9375C6.62812 17 6.375 17.2531 6.375 17.5625ZM11.4375 17C11.1281 17 10.875 17.2531 10.875 17.5625V18.6875C10.875 18.9969 11.1281 19.25 11.4375 19.25H12.5625C12.8719 19.25 13.125 18.9969 13.125 18.6875V17.5625C13.125 17.2531 12.8719 17 12.5625 17H11.4375ZM15.375 17.5625V18.6875C15.375 18.9969 15.6281 19.25 15.9375 19.25H17.0625C17.3719 19.25 17.625 18.9969 17.625 18.6875V17.5625C17.625 17.2531 17.3719 17 17.0625 17H15.9375C15.6281 17 15.375 17.2531 15.375 17.5625Z'
        fill={color}
      />
    </svg>
  )
}

export default Date
