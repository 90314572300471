import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import classroomEN from '@containers/SLO/Classroom/lang/en'
import classroomVI from '@containers/SLO/Classroom/lang/vi'
import PlanEN from '@containers/SubscriptionsPlans/lang/en'
import PlanVI from '@containers/SubscriptionsPlans/lang/vi'
import demoAccountVI from '@managers/DemoAccount/lang/vi'
import demoAccountEN from '@managers/DemoAccount/lang/en'
import AuthEN from '@containers/Auth/lang/en'
import AuthVI from '@containers/Auth/lang/vi'

import commonVI from './commonVI'
import commonEN from './commonEN'

import HomeVI from '../containers/HomePage/lang/vi'
import HomeEN from '../containers/HomePage/lang/en'
import modalEN from '../components/Modal/lang/en'
import modalVI from '../components/Modal/lang/vi'
import videoEN from '../containers/VideoQuiz/lang/en'
import videoVI from '../containers/VideoQuiz/lang/vi'
import registrationEN from '../containers/SLO/Registration/lang/en'
import registrationVI from '../containers/SLO/Registration/lang/vi'

const viLang = {
  ...HomeVI,
  ...commonVI,
  ...modalVI,
  ...videoVI,
  ...registrationVI,
  ...classroomVI,
  ...PlanVI,
  ...demoAccountVI,
  ...AuthVI,
}

const enLang = {
  ...HomeEN,
  ...commonEN,
  ...modalEN,
  ...videoEN,
  ...registrationEN,
  ...classroomEN,
  ...PlanEN,
  ...demoAccountEN,
  ...AuthEN,
}

const VI_TIME = -420

export enum E_LANG {
  VI = 'vi',
  EN = 'en',
}

i18n.use(initReactI18next).init({
  resources: {
    [E_LANG.EN]: { translation: enLang },
    [E_LANG.VI]: { translation: viLang },
  },
  lng: new Date().getTimezoneOffset() === VI_TIME ? E_LANG.VI : E_LANG.EN,
  // lng: E_LANG.EN,
  fallbackLng: E_LANG.EN,

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
