import { atom } from 'recoil'
import { IClass } from '../api'
import { ETabType } from '../constants'

interface IClassesAtomType {
  classes: IClass[]
  total: number
  loading: boolean
  filter: {
    page: number
    page_limit: number
    status: ETabType
    search?: string
  }
}

export const CLASSES_LIMIT_PAGE = 20

const classesAtom = atom<IClassesAtomType>({
  key: 'classesAtom',
  default: {
    classes: [],
    total: 0,
    loading: false,
    filter: {
      status: ETabType.PUBLIC,
      page: 1,
      page_limit: CLASSES_LIMIT_PAGE,
    },
  },
})

export default classesAtom
