import * as React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { ButtonProps } from 'antd/lib/button'
import Svg from '../Svg'

type Props = ButtonProps & {
  title: string
  className?: string
}

const StrokeBlueBtn: React.FC<Props> = ({ title = 'Click me!', className, ...props }) => {
  return (
    <Button className={`strokeBlueBtn ${className ?? ''}`} loading={false} {...props}>
      {title}
    </Button>
  )
}

export default StrokeBlueBtn
