import { forwardRef } from 'react'
import deepviewButton from '../../assets/imgs/deepviewButton.png'
import PointMessage from './PointMessage'

interface Props {
  onClick: () => void
  className?: string
}

const ReceivePrizeBtn = forwardRef((props: Props, ref) => {
  const { onClick, className } = props
  return (
    <div className={`controlBtn ${className}`}>
      <div className='controlBtnItem flex flex-col' onClick={onClick}>
        <PointMessage ref={ref} />
        <img src={deepviewButton} className='self-center min-h-[120px]' alt='' />
      </div>
    </div>
  )
})

export default ReceivePrizeBtn
