import React from 'react'
import { SvgIconProps } from './type'

const NextNext: React.FC<SvgIconProps> = ({ width = 20, height = 21, color = '#1F91FA' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.93359 6.26736L13.2001 10.4993L8.93359 14.7312L10.3225 16.132L16.0016 10.4993L10.3225 4.86658L8.93359 6.26736Z'
        fill={color}
      />
      <path
        d='M5.38894 4.86658L4 6.26736L8.26646 10.4993L4 14.7312L5.38894 16.132L11.068 10.4993L5.38894 4.86658Z'
        fill={color}
      />
    </svg>
  )
}

export default NextNext
