import React from 'react'
import { Pagination, PaginationProps } from 'antd'

import './PaginationBase.scss'

type Props = PaginationProps

const PaginationBase: React.FC<Props> = (props) => {
  return (
    <Pagination
      className='pagination-base'
      defaultCurrent={6}
      pageSize={4}
      total={500}
      showSizeChanger={false}
      {...props}
    />
  )
}

export default PaginationBase
