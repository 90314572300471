import { message } from 'antd'
import { isEmpty } from 'lodash'
import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean> // Return success

export const copyHelper = async (text?: string | number) => {
  if (!text) {
    return
  }

  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported')
    return false
  }

  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text?.toString())
    message.info('Đã sao chép')
    return true
  } catch (error) {
    console.warn('Copy failed', error)
    return false
  }
}

function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      message.info('Đã sao chép')
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  return [copiedText, copy]
}

export default useCopyToClipboard
