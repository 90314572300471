import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { getCategories } from '../../apis'
import categoriesAtom, { CategoryType } from '../../recoil/categoriesAtom'
import userInfoAtom from '../../recoil/userInfoAtom'
import videosAtom from '../../recoil/videosAtom'
import { categoryTotal } from './constant'
import imagesAssets from '../../assets/imgs'

function Arrow({
  children,
  disabled,
  onClick,
  style = {},
}: {
  children: React.ReactNode
  disabled: boolean
  onClick: VoidFunction
  style?: React.CSSProperties
}) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
        ...style,
      }}
      className='icon-scroll'
    >
      {children}
    </button>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <Arrow style={{ position: 'absolute', left: 0 }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <img style={{ transform: 'rotate(-180deg)' }} src={imagesAssets.solidNext} alt='next' />
    </Arrow>
  )
}

interface ICardProps {
  onClick: (item: CategoryType) => void
  item: CategoryType
  itemId: string | number
  index: number
}

const Card: React.FC<ICardProps> = ({ onClick, item, itemId, index }) => {
  const [videosStore] = useRecoilState(videosAtom)
  const { filter } = videosStore
  const { t } = useTranslation()

  const isActiveCategory = item.slug === filter?.category || (!filter?.category && index === 0)
  const activeStyle = isActiveCategory ? 'bg-[#030303] text-white' : 'bg-[#F2F2F2]'

  const style = `item cursor-pointer rounded-[10px] py-1 px-4 mr-3 w-max ${activeStyle}`

  return (
    <div key={item?._id} className={style} onClick={() => onClick(item)}>
      {t(item?.name)}
    </div>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <img src={imagesAssets.solidNext} alt='next' />
    </Arrow>
  )
}

const Categories: React.FC = () => {
  const setCategoriesStore = useSetRecoilState(categoriesAtom)
  const categoriesState = useRecoilValue(categoriesAtom)

  const userInfo = useRecoilValue(userInfoAtom)
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)

  const handleSelectStore = (item: CategoryType) => {
    setVideosStore((value) => ({
      ...value,
      filter: {
        ...value?.filter,
        category: item.slug,
      },
    }))
  }

  const renderCategories = () => {
    if (categoriesState?.loading) {
      return <></>
    }

    // eslint-disable-next-line no-unsafe-optional-chaining
    const categoriesAll = [categoryTotal, ...categoriesState?.categories]

    return (
      <div className='p-3 bg-white border-t border-[#E5E5E5] homePageScrolling'>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {categoriesAll.map((item, index) => (
            <Card key={item._id} index={index} item={item} itemId={item._id} onClick={handleSelectStore} />
          ))}
        </ScrollMenu>
      </div>
    )
  }

  React.useEffect(() => {
    const getCT = () => {
      setCategoriesStore({ loading: true, categories: [] })
      getCategories({ customer_id: userInfo?.customer_id }).then((result) => {
        if (result) {
          setCategoriesStore({ loading: false, categories: result.categories })
        }
      })
    }

    getCT()
  }, [userInfo?.customer_id])

  return renderCategories()
}

export default Categories
