import React from 'react'
import { SvgIconProps } from './type'

const Info: React.FC<SvgIconProps> = ({ width = 17, height = 17, color = '#1F91FA' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.47557 16.4993C4.00175 16.4993 0.465549 12.9296 0.492339 8.40217C0.519128 4.23642 3.82092 0.485909 8.51576 0.499303C13.1771 0.506001 16.5325 4.26991 16.5057 8.60309C16.4789 12.9497 12.8891 16.506 8.47557 16.4993ZM8.48227 15.0593C12.0653 15.0593 15.0457 12.1192 15.0457 8.583C15.0524 4.93294 12.139 1.97272 8.56934 1.93923C4.8858 1.90575 1.93897 4.91285 1.92557 8.47584C1.91218 12.0991 4.8724 15.0593 8.48227 15.0593Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.45942 9.51393C9.45942 9.92916 9.46612 10.3377 9.45942 10.7529C9.45272 10.9405 9.5063 11.0275 9.71392 11.0543C10.2363 11.1146 10.4841 11.3825 10.464 11.8111C10.4439 12.2264 10.1359 12.5009 9.64025 12.5076C8.88345 12.521 8.11995 12.521 7.36315 12.5076C6.82067 12.5009 6.51929 12.2197 6.52599 11.7642C6.52599 11.4026 6.76039 11.128 7.12205 11.0677C7.22921 11.0476 7.34306 11.0476 7.45692 11.0476C8.07977 11.0409 8.0128 11.1213 8.01949 10.465C8.01949 10.0497 8.0128 9.64118 8.01949 9.22594C8.01949 9.04512 7.95252 8.95805 7.76499 8.97144C7.58417 8.97814 7.41003 8.97814 7.22921 8.97144C6.85416 8.96475 6.58626 8.73704 6.53268 8.37538C6.4791 7.99363 6.65993 7.61858 7.03498 7.59179C7.67123 7.53821 8.32087 7.54491 8.96382 7.59179C9.29199 7.61188 9.45942 7.91996 9.46612 8.28162C9.46612 8.69016 9.45942 9.10539 9.45942 9.51393Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.52734 5.47544C7.53404 4.89277 7.90239 4.52441 8.49176 4.52441C9.08782 4.52441 9.46957 4.90616 9.46288 5.50892C9.45618 6.0648 9.04764 6.48004 8.49176 6.48004C7.92918 6.48004 7.52734 6.0648 7.52734 5.47544Z'
        fill={color}
      />
    </svg>
  )
}

export default Info
