import { Col, Row, Select } from 'antd'
import React from 'react'
import { useRecoilState } from 'recoil'
import svgAssets from '../../assets/svg'
import { AreaChart } from '../../components/Chart'
import { VerticalBarChart } from '../../components/Chart/VerticalBarChart'
import DatePickerBase from '../../components/Picker/DatePickerBase'
import userInfoAtom from '../../recoil/userInfoAtom'
import { mobileAndTabletCheck } from '../../utils'
import { showFullName } from '../../utils/infoRender'
import Slide from '../HomePage/Slide'
import ConcentrationLevel from './components/ConcentrationLevel'
import HistoryDeepView from './components/HistoryDeepView'
import ItemData from './components/ItemData'
import ViewTitle from './components/ViewTitle'

const Overview = () => {
  const [userInfo] = useRecoilState(userInfoAtom)

  const renderOverview = () => {
    return (
      <Row
        className='mt-5'
        gutter={[
          { xs: 16, lg: 30 },
          { xs: 16, lg: 0 },
        ]}
      >
        <Col xs={24} lg={8} className='gutter-row text-left'>
          <ItemData name='Số lượt xem' data='867' img={svgAssets.Eye} />
        </Col>
        <Col xs={24} lg={8} className='gutter-row text-left'>
          <ItemData name='Tổng điểm đạt được' data='194700.23' color='#00AC69' img={svgAssets.Score} />
        </Col>
        <Col xs={24} lg={8} className='gutter-row text-left'>
          <ItemData name='Tổng số giờ xem' data='1,997.5' color='#00CFD5' img={svgAssets.Timing} />
        </Col>
      </Row>
    )
  }

  const renderStatisticalView = () => {
    return (
      <Row
        className='mt-5'
        gutter={[
          { xs: 16, lg: 30 },
          { xs: 16, lg: 0 },
        ]}
      >
        <Col xs={24} lg={16} className='gutter-row text-left rounded-[10px] overflow-hidden'>
          <ViewTitle title='Thống kê lượt xem' renderRight={() => <DatePickerBase />} />
          <div className='bg-[white] rounded-b-[10px] p-7'>
            <AreaChart />
          </div>
        </Col>
        <Col xs={24} lg={8} className='gutter-row'>
          <ConcentrationLevel />
        </Col>
        <Col span={24} className='gutter-row text-left mt-7'>
          <ViewTitle title='Thống kê điểm theo ngày' renderRight={() => <DatePickerBase />} />
          <div className='bg-[white] rounded-b-[10px] p-7'>
            <VerticalBarChart />
          </div>
          <div />
        </Col>
      </Row>
    )
  }

  const renderHistory = () => {
    return <HistoryDeepView />
  }

  const renderMain = () => {
    return (
      <Col className='h-full p-4' span={mobileAndTabletCheck ? 24 : 20}>
        <Row gutter={[20, 20]}>
          <Col className='gutter-row text-left'>
            <div className='font-semibold text-2xl text-[#030303]'>{showFullName(userInfo)}</div>
            <div className='text-base text-[#606060]'>Thống kê cá nhân</div>
          </Col>
        </Row>
        {renderOverview()}
        {renderStatisticalView()}
        {renderHistory()}
      </Col>
    )
  }

  return (
    <div className='homePageContainer'>
      <Row className=''>
        <Slide />
        {renderMain()}
      </Row>
    </div>
  )
}

export default Overview
