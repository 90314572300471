import VI from './vi'

const modalEN: typeof VI = {
  'login.sign_in': 'Login',
  'login.customer_code': 'Customer code',
  'login.emailLogin': 'Email',
  'login.emailLoginPlaceholder': 'Enter Email',
  'login.passwordLogin': 'Password',
  'login.passwordLoginPlaceholder': 'Enter Password',
  'login.rePasswordLogin': 'Confirm Password',
  'login.rePasswordLoginPlaceholder': 'Enter Confirm Password',
  'login.notMatch': 'The new password that you entered do not match!',
  'login.passMin8': 'Include a password that includes a minimum of 8 characters.',
  'login.forgetPassword': 'Forget Password?',
  'login.no_user_code': "I don't have a user code",
  'login.user_not_found': 'Email address does not exist!',
  'login.invalid_authentication': 'Login information is incorrect',
  'auth.passwordNotStrongEnough': 'Password is not strong enough',
  'login.reset_password': 'Reset password',
  'login.sign_up': 'Sign Up',
  'login.last_name': 'Last Name ',
  'login.first_name': 'First Name',
  'login.email_address': 'Email Address',
  'login.phone': 'Phone Number ',
  'login.referral_code': 'Referral code',
  'login.referral_code_sentences': 'Enter referral code to get bonus points or skip',
  'login.have_user_code': 'I already have a customer code',
  'login.sign_up_button': 'Sign Up',
  'login.signUpSuccess': 'Sign Up Success',
  'login.ok': 'Ok',
  'login.checkEmail': 'Please check your email to get the login code',
  'login.inSpam': 'In the inbox or in Spam',
  'login.referralCodeOrSkip': 'Enter referral code to get bonus points or skip',
  'login.email': 'Email is not correct',
  'thank.noti': 'We will send notification of reward points and redemption value to your email.',
  'thank.close': 'Close',
  'thank.more': 'Watch more videos',
  'history.history_purchase': 'History Purchase',
  'history.empty': 'Empty',
}

export default modalEN
