/* eslint-disable max-len */

export default {
  'overview_class.overview_position': 'Chức danh',
  'overview_class.overview_department': 'Phòng ban',
  'overview_class.overview_total_score_tab': 'Tổng điểm',
  'overview_class.overview_detail': 'Chi tiết',
  'overview_class.overview_average_tab': 'Mức độ tập trung trung bình',
  'overview_class.overview_total_view_tab': 'Tổng lượt xem',
  'overview_class.overview_participating_class': 'Lớp học tham gia',
  'overview_class.overview_community': 'Cộng đồng',
  'overview_class.overview_participating_class_tab': 'Đang tham gia',
  'overview_class.overview_joined': 'Đã tham gia',
  'overview_class.overview_pending': 'Chờ duyệt',
  'overview_class.overview_reject': 'Từ chối',
  'overview_class.overview_have_left': 'Đã rời',
  'overview_class.overview_student': '{{total}} học viên',
  'overview_class.overview_lesson': '{{total}} bài học',
  'overview_class.overview_no_class_message': 'Không có lớp học nào',
  'overview_class.overview_popup_participant': 'Tham gia lớp học',
  'overview_class.class': 'lớp học',
  'overview_class.overview_popup_question_participant': 'Bạn muốn yêu cầu tham gia lớp học này?',
  'overview_class.overview_popup_hide_detail': 'Ẩn chi tiết',
  'overview_class.overview_popup_close': 'Đóng',
  'overview_class.overview_popup_ok': 'Đồng ý',
  'overview_class.overview_participating_message_title': 'Đã yêu cầu tham gia lớp học',
  'overview_class.overview_participating_message_content':
    'Yêu cầu tham gia lớp học của bạn đã được gửi. Bạn có thể vào học sau khi được duyệt yêu cầu',
  'overview_class.overview_pending_message_title': 'Yêu cầu tham gia lớp học đang được xét duyệt',
  'overview_class.overview_pending_message_content':
    'Yêu cầu của bạn đang được xem xét. Chúng tôi sẽ thông báo kết quả sớm nhất qua email',
  'overview_class.overview_reject_message_title': 'Yêu cầu tham gia bị từ chối',
  'overview_class.overview_reject_message_content':
    'Xin lỗi, yêu cầu tham gia lớp học của bạn đã từ chối. Vui lòng liên hệ với quản trị viên nếu bạn cần thêm thông tin',
  'overview_class.overview_reject_message':
    'Yêu cầu tham gia bị từ chối\nXin lỗi, yêu cầu tham gia lớp học của bạn đã từ chối. Vui lòng liên hệ với quản trị viên nếu bạn cần thêm thông tin',
  'overview_class.overview_not_yet_started_title': 'Lớp học chưa bắt đầu',
  'overview_class.overview_not_yet_started_content':
    'Xin lỗi, lớp học chưa đến ngày bắt đầu. Vui lòng đợi <b>{{time}}</b> để tham gia lớp học hoặc liên hệ Quản trị viên để biết thêm thông tin',

  'overview_class.already_enrolled_title': 'Bạn đã tham gia lớp học này',
  'overview_class.already_enrolled_content':
    'Xin lỗi, bạn đã đăng ký tham gia lớp học này trước đó nên không thể đăng ký lại. Vui lòng chọn một lớp học khác hoặc liên hệ với quản trị viên để được hỗ trợ.',

  'overview_class.not_found_title': 'Lớp Học Không Tồn Tại',
  'overview_class.not_found_content': 'Xin lỗi, không tìm thấy lớp học',

  'overview_class.dropped_title': 'Yêu cầu của bạn đã bị từ chối',
  'overview_class.dropped_content':
    'Xin lỗi, yêu cầu của bạn đã bị từ chối vì bạn đã rời lớp học. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với quản trị viên để được hỗ trợ',

  // detail_class

  'detail_class.detail_class_organizer': 'Người tổ chức',
  'detail_class.detail_class_organizer_day': 'Ngày tổ chức',
  'detail_class.detail_class_end_day': 'Ngày kết thúc',
  'detail_class.detail_class_view_detail': 'Xem chi tiết',
  'detail_class.detail_class_hide_detail': 'Ẩn chi tiết',
  'detail_class.detail_class_total_score': 'Tổng điểm',
  'detail_class.detail_class_avg_concern_level': 'Mức độ tập trung trung bình',
  'detail_class.detail_class_total_view': 'Tổng lượt xem',
  'detail_class.detail_class_number_lesson_learned': 'Số bài đã học',
  'detail_class.detail_class_unlearned_lesson': 'Bài chưa học',
  'detail_class.detail_class_learned_lessons': 'Bài đã học',
  'detail_class.empty_class': 'Bạn chưa xem bài học nào',
  'detail_class.detail_class_lesson': 'Bài học',
  'detail_class.detail_class_sort': 'Sắp xếp',
  'detail_class.detail_class_newest': 'Mới nhất',
  'detail_class.detail_class_oldest': 'Cũ nhất',
  'detail_class.detail_class_A_Z': 'Từ A - Z',
  'detail_class.detail_class_Z_A': 'Từ Z - A',
  'detail_class.detail_class_sponsored': 'Được tài trợ',
  'detail_class.detail_class_free	': 'Miễn phí',
  'detail_class.detail_class_leave_class': 'Rời khỏi lớp học',
  'detail_class.detail_class_leave_class_mesage': 'Bạn muốn rời khỏi lớp học này?',
  'detail_class.detail_class_close': 'Đóng',
  'detail_class.detail_class_agree': 'Đồng ý',
  'detail_class.detail_class_left_class': 'Bạn đã rời khỏi lớp học',

  'detail_class.detail_class_left_class_message_title': 'Bạn đã rời khỏi lớp học thành công',
  'detail_class.detail_class_left_class_message_content':
    'Bạn có thể tham gia lại lớp học được người tổ chức thêm vào trong trường hợp lớp học chưa kết thúc',
}
