import { Table } from 'antd'
import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import BaseModal from '../../../../../components/Modal/BaseModal'

export interface IVideoHistory {
  name: string
  view: number
  question: string
  point: string
  time: string
}

const columns: ColumnsType<IVideoHistory> = [
  {
    title: 'Video',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Lượt xem',
    dataIndex: 'view',
    key: 'view',
    align: 'center',
  },
  {
    title: 'Câu hỏi',
    dataIndex: 'question',
    key: 'question',
    align: 'center',
  },
  {
    title: 'Điểm số',
    dataIndex: 'point',
    key: 'point',
    align: 'center',
  },
  {
    title: 'Thời gian',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
  },
]

type Props = {
  dataSource: IVideoHistory[]
  showHistoryModal?: boolean
  onClose?: () => void
}

const HistoryModal: React.FC<Props> = ({ dataSource, onClose, showHistoryModal }) => {
  return (
    <BaseModal className='classroom-modal' width='80%' open={showHistoryModal} onCancel={onClose}>
      <div>
        <div className='flex justify-between items-center mb-7'>
          <div className='text-[#212121] text-2xl font-[700]'>Chi tiết điểm</div>
          <div className='text-[#212121] font-bold cursor-pointer' onClick={onClose}>
            x
          </div>
        </div>
        <Table
          rowClassName='bg-[#F9F9F9]'
          scroll={{ y: 456 }}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </BaseModal>
  )
}

export default HistoryModal
