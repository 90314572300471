import React from 'react'
import { SvgIconProps } from './type'

const Sao: React.FC<SvgIconProps> = ({ height = 9, width = 8, color = '#F9F9F9' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.887695 7.36084L2.6543 4.93506L0 4.14404L0.413086 2.82568L3.06738 3.80127L2.98828 0.777832H4.32422L4.23633 3.854L6.85547 2.87842L7.25977 4.22314L4.56152 5.02295L6.29297 7.40479L5.20312 8.22217L3.57715 5.69092L1.98633 8.16064L0.887695 7.36084Z'
        fill={color}
      />
    </svg>
  )
}

export default Sao
