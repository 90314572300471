import moment from 'moment'

export const formatNumber = (numberInput = 0) => {
  const parts = (Math.round(numberInput * 100) / 100).toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return parts.join('.')
}

export const formatCurrency = (price = 0) => {
  return `${formatNumber(price)} VND`
}

export const formatPointBase = (point = 0) => {
  return formatNumber(Math.floor(point))
}

export const formatPointDV = (point = 0) => {
  return `${formatPointBase(point)} DV`
}

export const formatShowCurrency = (price = 0, isPoint = false) => {
  return isPoint ? formatPointDV(price) : formatCurrency(price)
}

export const convertToHHMMSS = (time = 0) => {
  const duration = moment.duration(time, 'seconds')

  if (time < 3600) {
    return moment.utc(duration.asMilliseconds()).format('mm:ss')
  }

  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}
