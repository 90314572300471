import VI from './commonVI'

const EN: typeof VI = {
  'common.account': 'Account',
  'validate.require': 'Required Information',
  'validate.phone': 'Incorrect phone number',
  'validate.email': 'The input is not valid E-mail!',
  'error.emailNotFound': 'The email was not found',
  'common.referral': 'Referral code',
  'common.availableBalance': 'Available balances',
  'common.availablePurchase': 'Purchase balance',
  'common.accumulatedPoint': 'Accumulated points',
  'common.purchaseHistory': 'Purchase history',
  'common.logout': 'Log out',
}

export default EN
