import React from 'react'
import { SvgIconProps } from './type'

const Eye: React.FC<SvgIconProps> = ({ height = 18, width = 18 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1437_6750)'>
        <path
          d='M20.4786 7.64917C13.3005 7.64917 7.64016 12.1667 0.872773 19.5501C0.381735 20.0947 0.372807 20.9072 0.863844 21.4518C6.65809 27.8531 11.7649 33.3617 20.4786 33.3617C29.0851 33.3617 35.54 26.4247 40.129 21.3982C40.6022 20.8804 40.6289 20.0947 40.1736 19.5501C35.4954 13.8809 29.0226 7.64917 20.4786 7.64917ZM20.8714 28.5316C16.1396 28.7459 12.247 24.8533 12.4702 20.1304C12.6577 16.0057 15.9967 12.6667 20.1214 12.4792C24.8533 12.2649 28.7458 16.1575 28.5226 20.8804C28.3352 25.0051 24.9961 28.3442 20.8714 28.5316Z'
          fill='#E5E5E5'
        />
        <path
          d='M20.4975 16.3093C20.4975 15.7736 20.5957 15.2647 20.7743 14.8004C20.685 14.8004 20.5957 14.7915 20.4975 14.7915C17.2031 14.7915 14.5515 17.5949 14.8015 20.9429C15.0158 23.7373 17.2656 25.9872 20.0601 26.2014C23.408 26.4514 26.2114 23.7998 26.2114 20.5054C26.2114 20.3893 26.2025 20.2733 26.2025 20.1572C25.7025 20.3804 25.1579 20.5054 24.5776 20.5054C22.3278 20.5054 20.4975 18.6216 20.4975 16.3093Z'
          fill='#E5E5E5'
        />
      </g>
      <defs>
        <clipPath id='clip0_1437_6750'>
          <rect width='40' height='40' fill='white' transform='translate(0.5 0.499268)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Eye
