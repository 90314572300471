export default {
  'plan.upgradeNow': 'NÂNG CẤP NGAY',
  'plan.fit': 'Phù hợp',
  'plan.functions': 'Các tính năng',
  'plan.discount': 'Ưu đãi thành viên',
  'plan.redemption': 'Đổi điểm thưởng',
  'plan.reward': 'Mức điểm thưởng tối đa',
  'plan.point': 'Điểm nhận được',
  'plan.labelPoint': 'điểm',
  'plan.labelPackage': 'gói',
  'plan.labelPointMinutes': 'điểm / phút',
  'plan.labelPointMinutesLearn': 'điểm / phút học',
  'plan.transactionInformation': 'THÔNG TIN CHUYỂN KHOẢN',
  'plan.upgradeTo': 'Nâng cấp gói',
  'plan.planFor': 'của tài khoản',
  'plan.person': 'Gói cá nhân',
  'plan.quantity': 'Số lượng',
  'plan.total': 'Tổng',
  'plan.method': 'Phương thức thanh toán',
  'plan.transactionBank': 'Chuyển khoản ngân hàng',
  'plan.bank': 'Ngân hàng',
  'plan.beneficiary': 'Người thụ hưởng',
  'plan.beneficiaryAccount': 'Tài khoản thụ hưởng',
  'plan.money': 'Số tiền',
  'plan.transferContents': 'Nội dung chuyển khoản',
  'plan.content': 'Vui lòng sử dụng nội dung được cung cấp để đảm bảo chính xác',
  'plan.back': 'quay lại',
  'plan.ok': 'Xác nhận đã chuyển',
  'plan.submitted': 'Đã thanh toán',
  'plan.submittedContent': 'Bạn đã gửi yêu cầu thanh toán, chúng tôi sẽ đối soát và phản hồi bạn trong vòng 24h',
  'plan.close': 'ĐÓNG',
  'plan.voucher': 'Mua từ 2 gói để được giảm lên đến 20%.',
}
