import React from 'react'
import { Select } from 'antd'

import './SelectBase.scss'
import { BaseOptionType, SelectProps } from 'antd/lib/select'

type Props = SelectProps & {
  className?: string
}

const SelectBase = React.forwardRef<any, Props>(({ className, ...otherProps }, ref) => {
  return <Select ref={ref} className={`select-base ${className ?? ''}`} {...otherProps} />
})

export default SelectBase
