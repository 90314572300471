import { TFunction } from 'i18next'
import { useImperativeHandle, createRef } from 'react'
import { useTranslation } from 'react-i18next'

interface LangManagerRef {
  t: TFunction
  getTranslation: () => TFunction
}

export const langManagerRef = createRef<LangManagerRef>()

export const LangManager = () => {
  const { t } = useTranslation()

  useImperativeHandle(
    langManagerRef,
    () => ({
      getTranslation: () => {
        return t
      },
      t,
    }),
    [t],
  )

  return <></>
}

export const translateHelper = (key: string, params?: any): string => {
  const trans = langManagerRef?.current?.getTranslation()

  if (trans) {
    return trans(key, params) as unknown as string
  }

  return ''
}
