/* eslint-disable max-len */
import VI from './vi'

const classroomEN: typeof VI = {
  'overview_class.overview_position': 'Title/Position',
  'overview_class.overview_department': 'Department',
  'overview_class.overview_total_score_tab': 'Total score',
  'overview_class.overview_detail': 'Details',
  'overview_class.overview_average_tab': 'Average concentration level',
  'overview_class.overview_total_view_tab': 'Total views',
  'overview_class.overview_participating_class': 'Joined class',
  'overview_class.overview_community': 'Community',
  'overview_class.overview_participating_class_tab': 'Joining',
  'overview_class.overview_joined': 'Joined',
  'overview_class.overview_pending': 'Pending approval',
  'overview_class.overview_reject': 'Rejected',
  'overview_class.overview_have_left': 'Left',
  'overview_class.overview_student': '{{total}} students',
  'overview_class.overview_lesson': '{{total}} lessons',
  'overview_class.overview_no_class_message': 'No classes available',
  'overview_class.overview_popup_participant': 'Join the class',
  'overview_class.class': 'class',
  'overview_class.overview_popup_question_participant': 'Do you want to request to join this class?',
  'overview_class.overview_popup_hide_detail': 'Hide detail',
  'overview_class.overview_popup_close': 'Close',
  'overview_class.overview_popup_ok': 'Agree',
  'overview_class.overview_participating_message_title': 'You have been requested to join a class',
  'overview_class.overview_participating_message_content':
    'Your request to join the class has been submitted. You can access the class after your request has been approved',
  'overview_class.overview_pending_message_title': 'Joining request under review',
  'overview_class.overview_pending_message_content':
    'Your request is being reviewed. We will notify you of the outcome soon via email',
  'overview_class.overview_reject_message_title': 'Joining request declined',
  'overview_class.overview_reject_message_content':
    "We're sorry, but your request to join the class has been declined. Please contact the administrator if you need further information",

  'overview_class.overview_reject_message':
    "Joining request declined\nWe're sorry, but your request to join the class has been declined. Please contact the administrator if you need further information",

  'overview_class.overview_not_yet_started_title': 'Class not yet started',
  'overview_class.overview_not_yet_started_content':
    'We apologize, but the class has not yet started. Please wait until <b>{{time}}</b> to join the class or contact the administrator for more information.',

  'overview_class.already_enrolled_title': 'You took this class',
  'overview_class.already_enrolled_content':
    "Sorry, you signed up for this class before, so you can't re-enroll. Please select a different class or contact the administrator for assistance.",

  'overview_class.not_found_title': "Classroom Doesn't Exist",
  'overview_class.not_found_content': 'Sorry, the class could not be found',

  'overview_class.dropped_title': 'Your request has been declined',
  'overview_class.dropped_content':
    'Sorry, your request was denied because you left the class. If you have any questions, please contact the administrator for assistance',

  // sss

  'detail_class.detail_class_organizer': 'Organizer',
  'detail_class.detail_class_organizer_day': 'Organize date',
  'detail_class.detail_class_end_day': 'End date',
  'detail_class.detail_class_view_detail': 'View details',
  'detail_class.detail_class_hide_detail': 'Hide details',
  'detail_class.detail_class_total_score': 'Total score',
  'detail_class.detail_class_avg_concern_level': 'Average concentration level',
  'detail_class.detail_class_total_view': 'Total views',
  'detail_class.detail_class_number_lesson_learned': 'Number of lessons learned',
  'detail_class.detail_class_unlearned_lesson': 'Unlearned lessons',
  'detail_class.detail_class_learned_lessons': 'Learned lessons',
  'detail_class.detail_class_lesson': 'Lessons',
  'detail_class.detail_class_sort': 'Sort',
  'detail_class.detail_class_newest': 'Newest',
  'detail_class.detail_class_oldest': 'Oldest',
  'detail_class.detail_class_A_Z': 'A - Z',
  'detail_class.detail_class_Z_A': 'Z - A',
  'detail_class.detail_class_sponsored': 'Sponsored',
  'detail_class.detail_class_free	': 'Free',
  'detail_class.detail_class_leave_class': 'Leave the class',
  'detail_class.detail_class_leave_class_mesage': 'Do you want to leave this class?',
  'detail_class.detail_class_close': 'Close',
  'detail_class.detail_class_agree': 'Agree',
  'detail_class.detail_class_left_class': 'You have left the class',
  'detail_class.empty_class': "You haven't watched any lessons yet",

  'detail_class.detail_class_left_class_message_title': 'You have successfully left the class.',
  'detail_class.detail_class_left_class_message_content':
    'You can rejoin the class if the organizer adds you again, provided that the class has not ended.',
}

export default classroomEN
