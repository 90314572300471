/* eslint-disable max-len */
import Svg from '@components/Svg'
import './style.scss'
import MainButton from '@components/Button/MainButton'
import { useHistory } from 'react-router-dom'
import pathUrl from '@routes/path'
import { IPlan } from 'types'
import { formatCurrency, formatNumber, formatShowCurrency } from '@utils/format'
import { useTranslation } from 'react-i18next'

interface Props {
  plan: IPlan
}

export const PlanCard: React.FC<Props> = ({ plan }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirectBuyPackage = () => {
    history.push(pathUrl.subscriptionsPlans(plan._id))
  }

  return (
    <div className='rounded-2xl border-t-8 border-[#1F91FA] bg-[#FFFFFF] w-fit pricing-item p-6 sm:p-3 sm:mt-9 h-fit'>
      <div />
      <div>
        <div className='font-bold text-[#1F91FA] text-xl text-center'>{plan.name}</div>
        <div className='mt-3 mb-2 flex items-center justify-center text-[#030303]'>
          <span className='text-2xl font-bold'>{formatNumber(plan.price)}đ</span>{' '}
          <span className='ml-1 font-bold text-base'>/ {t('plan.labelPackage')}</span>
        </div>
        <div className='flex flex-col gap-3 mt-6 mx-10 sm:mx-3'>
          <div className='info-item'>
            <div className=''>
              <Svg.BxBulb />
            </div>
            <div className=''>{t('plan.point')}:</div>
            <div className=''>
              {formatNumber(plan.points_received)} {t('plan.labelPoint')}
            </div>
          </div>
          <div className='info-item'>
            <div className=''>
              <Svg.Lesson />
            </div>
            <div className=''>{t('plan.reward')}:</div>
            <div className=''>
              {formatNumber(plan.max_reward_rate)} {t('plan.labelPointMinutes')}
            </div>
          </div>
          <div className='info-item'>
            <div className=''>
              <Svg.Student />
            </div>
            <div className=''>{t('plan.redemption')}:</div>
            <div className=''>
              {formatNumber(plan.redemption_rate)} {t('plan.labelPointMinutesLearn')}
            </div>
          </div>

          <div className='info-item text-[#1E88E5]'>
            <div className=''>
              <Svg.Crown />
            </div>
            <div className='text-[#1E88E5] font-bold'>{t('plan.discount')}</div>
          </div>
          <div className='text-[#1E88E5] ml-3 flex flex-col gap-2'>
            {plan.discount_info &&
              plan.discount_info.map((item) => {
                return (
                  <div className='flex items-center'>
                    <div className='w-1 h-1 rounded-full bg-[#1E88E5] mr-2' />
                    {item}
                  </div>
                )
              })}
          </div>

          <div className='info-item'>
            <div className=''>
              <Svg.Feature />
            </div>
            <div className=''>{t('plan.functions')}</div>
          </div>
          <div className='ml-3 flex flex-col gap-2'>
            {plan.features_included &&
              plan.features_included.map((item) => {
                return (
                  <div className='flex items-center'>
                    <div className='w-1 h-1 rounded-full bg-[#939393]  mr-2' /> {item}
                  </div>
                )
              })}
          </div>

          <div className='info-item'>
            <div className=''>
              <Svg.Respon />
            </div>
            <div className=''>{t('plan.fit')}:</div>
            <div className=''>{plan.suitable_for}</div>
          </div>
        </div>
        <div className='mt-16 sm:mt-3'>
          <MainButton className='w-full' onClick={redirectBuyPackage}>
            {t('plan.upgradeNow')}
          </MainButton>
        </div>
      </div>
    </div>
  )
}
