/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const Student: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#939393' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.081 7.41343C17.0758 9.91799 14.9878 11.9956 12.4832 11.9852C9.98906 11.9747 7.91668 9.89195 7.92188 7.39261C7.92709 4.89326 10.0099 2.81567 12.4988 2.81567C15.0034 2.82088 17.0862 4.90888 17.081 7.41343ZM12.4936 10.3189C14.0765 10.3241 15.4095 9.00156 15.4199 7.41864C15.4303 5.84613 14.0922 4.49753 12.5196 4.49232C10.9315 4.48711 9.58812 5.8201 9.58291 7.40302C9.58291 8.98073 10.9107 10.3137 12.4936 10.3189Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 20.1381C2.66662 19.6486 3.0207 19.4768 3.51536 19.5132C3.61429 19.5184 3.70802 19.5132 3.81736 19.5132C3.87464 19.18 3.91109 18.8572 3.98399 18.5343C4.55155 16.0662 5.82205 14.0563 7.92567 12.6036C8.21205 12.4057 8.53489 12.2495 8.85251 12.1089C9.32635 11.8902 9.63356 11.9579 9.98763 12.3432C10.6281 13.0358 11.2633 13.7335 11.9038 14.4312C12.1017 14.6447 12.2943 14.8634 12.5026 15.0977C12.7786 14.7853 13.0389 14.4937 13.2993 14.1969C13.8356 13.5929 14.3667 12.9889 14.8978 12.3797C15.1686 12.0725 15.5122 11.9683 15.9028 12.0464C16.6734 12.2026 17.2878 12.64 17.8398 13.1607C19.5789 14.8165 20.6723 16.8316 21.1254 19.1904C21.1462 19.2945 21.1566 19.3935 21.1722 19.5184C21.7294 19.4872 22.2865 19.4612 22.5 20.1381C22.5 20.2682 22.5 20.3984 22.5 20.5286C22.4115 20.8254 22.2501 21.0597 21.9324 21.143C21.7971 21.1795 21.6513 21.1847 21.5055 21.1847C15.5018 21.1847 9.49818 21.1847 3.49453 21.1847C2.90615 21.1847 2.7239 21.0597 2.5 20.5286C2.5 20.3984 2.5 20.2682 2.5 20.1381ZM19.5685 19.5861C19.5633 19.5184 19.5633 19.4612 19.5528 19.4039C19.2196 17.5242 18.3396 15.936 17.0014 14.5874C16.7098 14.2906 16.3818 14.0303 15.9965 13.848C15.9392 13.9105 15.8871 13.9678 15.8351 14.0251C14.9655 14.9936 14.1011 15.9673 13.2316 16.9358C12.9244 17.2742 12.6015 17.3471 12.2058 17.118C12.034 17.0191 11.883 16.8785 11.7528 16.7327C10.9926 15.884 10.2428 15.03 9.48776 14.1761C9.38362 14.0615 9.27428 13.9522 9.16493 13.8324C7.87881 14.5666 6.99362 15.634 6.32713 16.9045C5.88453 17.7429 5.59815 18.6281 5.49401 19.5809C10.2011 19.5861 14.877 19.5861 19.5685 19.5861Z'
        fill={color}
      />
    </svg>
  )
}

export default Student
