export enum ETabType {
  PUBLIC = 'PUBLIC',
  JOINING = 'ENROLLED',
  JOINED = 'COMPLETED',
  PENDING = 'PENDING',
  DROPPED = 'DROPPED',
  REJECTED = 'REJECTED',
}

export enum EVideoTabType {
  NOT_LEARNED = 'INCOMPLETE',
  LEARNED = 'COMPLETED',
}
