export type IClassroomType = {
  id: string
  video: string
  videoLength: string
  name: string
  organizationName: string
  organizationDate: string
  numberOfStudents: number
  numberOfLessons: number
}

export enum VideoStatus {
  DROPPED = 'DROPPED',
  ENROLLED = 'ENROLLED',
  NOT_ENROLLED = 'NOT_ENROLLED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
