import VI from './vi'

const PlanEN: typeof VI = {
  'plan.upgradeNow': 'UPGRADE NOW',
  'plan.fit': 'Fit',
  'plan.functions': 'Functions',
  'plan.discount': 'Member offers',
  'plan.redemption': 'Redeem bonus points',
  'plan.reward': 'Maximum reward points',
  'plan.point': 'Points received',
  'plan.labelPoint': 'point',
  'plan.labelPackage': 'package',
  'plan.labelPointMinutes': 'point / Minutes',
  'plan.labelPointMinutesLearn': 'point / learning minutes',
  'plan.upgradeTo': 'Upgrade to',
  'plan.planFor': 'plan for',
  'plan.person': 'Individual package',
  'plan.quantity': 'Quantity',
  'plan.total': 'Total',
  'plan.method': 'Payment methods',
  'plan.transactionBank': 'Bank transfer',
  'plan.bank': 'Bank',
  'plan.beneficiary': 'Beneficiary',
  'plan.beneficiaryAccount': 'Beneficiary account',
  'plan.money': 'Amount',
  'plan.transferContents': 'Transfer Contents',
  'plan.content': 'Please use the provided content to ensure accuracy',
  'plan.transactionInformation': 'INFORMATION OF TRANSFER',
  'plan.back': 'back',
  'plan.ok': 'Transfer Confirmation',
  'plan.submitted': 'Payment Submitted',
  'plan.submittedContent': 'You have submitted a payment request, we will verify and respond to you within 24 hours',
  'plan.close': 'CLOSE',
  'plan.voucher': 'Buy from 2 packs to get up to 20% off.',
}

export default PlanEN
