import userInfoAtom from '@recoil/userInfoAtom'
import { useRecoilState } from 'recoil'

import { DEMO_ACCOUNT } from '@constants/constants'

export const useDemoAccount = () => {
  const [userInfo] = useRecoilState(userInfoAtom)

  const isDemoAccount = userInfo?.email === DEMO_ACCOUNT.email

  return {
    isDemoAccount,
  }
}
