import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import appConfigAtom from '@recoil/appConfigAtom'

import Logger from '@managers/Logger'
import { apis } from '@apis'

import { IPlan } from 'types'
import { PlanCard } from '../components'

const UpgradeAccount: React.FC = () => {
  const setAppConfig = useSetRecoilState(appConfigAtom)

  const [plans, setPlans] = useState<IPlan[]>([])

  useEffect(() => {
    const getPlanInfo = async () => {
      try {
        setAppConfig((value) => ({ ...value, loading: true }))
        const response = await apis.getPersonalPlans()
        setPlans(response.plans)
      } catch (error) {
        Logger.error('getPlanInfo', error)
      }
      setAppConfig((value) => ({ ...value, loading: false }))
    }

    getPlanInfo()
  }, [])

  return (
    <div className='flex justify-center w-full min-h-screen p-9 bg-[#E3F2FD]'>
      {plans && plans.map((item) => <PlanCard plan={item} />)}
    </div>
  )
}

export default UpgradeAccount
