import useInfoProfile from '@components/useHook/useInfoProfile'
import Logger from '@managers/Logger'
import appModalAtom from '@recoil/appModalAtom'
import React, { ReactElement, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { UserType } from 'types'
import { ModalType } from 'types/enum'

type Props = {
  component: JSX.Element
}

const PrivateRouter: React.FC<Props> = ({ component }) => {
  const { userInfo } = useInfoProfile()
  const [, setAppModal] = useRecoilState(appModalAtom)
  const [hasAuth, setHasAuth] = useState(false)

  useEffect(() => {
    try {
      if (!userInfo?.email) {
        setAppModal({
          mode: ModalType.signIn,
          data: {
            onCancel: async (userData?: UserType) => {
              if (userData?.email) {
                setAppModal({ mode: ModalType.close })
                setHasAuth(true)
              }
            },
          },
        })
      } else {
        setHasAuth(true)
      }
    } catch (error) {
      Logger.error('PrivateRouter', error)
    }
  }, [])

  if (hasAuth) {
    return component
  }

  return <></>
}

export default PrivateRouter
