import * as React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import './styles.scss'

function TerminalButton({ className, ...other }: ButtonProps) {
  return (
    <Button type='default' className={`terminalBtn ${className ?? ''}`} {...other}>
      {other.children || 'Submit'}
    </Button>
  )
}

export default TerminalButton
