import Eye from './Eye.png'
import Score from './Score.png'
import Timing from './Timing.png'
import Video from './Video.png'
import User from './User.png'

export const svgAssets = {
  Eye,
  Score,
  Timing,
  Video,
  User,
}

export default svgAssets
