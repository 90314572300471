import * as React from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryBtn from '../Button/PrimaryBtn'
import SecondaryBtn from '../Button/SecondaryBtn'
import BaseModal, { BaseModalProps } from './BaseModal'
import './styles.scss'

interface Props extends BaseModalProps {
  onClose: () => void
}

function ThanksUserCodeModal(props: Props) {
  const { t } = useTranslation()

  return (
    <BaseModal
      footer={[
        <SecondaryBtn onClick={props.onClose}>{t('thank.close')}</SecondaryBtn>,
        <PrimaryBtn onClick={props.onClose}>{t('thank.more')}</PrimaryBtn>,
      ]}
      className='thanks-modal'
      {...props}
    >
      <div className='pt-7'>{t('thank.noti')}</div>
    </BaseModal>
  )
}

export default ThanksUserCodeModal
