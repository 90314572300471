import { ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import MessageModal from '@components/Modal/MessageModal'
import Svg from '@components/Svg'
import { translateHelper } from '@lang/langManager'
import { hideModal } from '@managers/GlobalModal'

export const PendingJoinClass = () => (
  <MessageModal
    title={translateHelper('overview_class.overview_pending_message_title')}
    content={translateHelper('overview_class.overview_pending_message_content')}
    icon={
      <span className='anticon anticon-info-circle'>
        <ClockCircleOutlined style={{ color: 'orange', fontSize: '24px' }} />
      </span>
    }
    onClose={hideModal}
  />
)

export const RejectedJoinClass = () => (
  <MessageModal
    title={translateHelper('overview_class.overview_reject_message_title')}
    content={translateHelper('overview_class.overview_reject_message_content')}
    icon={<CloseCircleOutlined style={{ color: 'red', fontSize: '24px' }} />}
    onClose={hideModal}
  />
)

export const leaveClass = (onClose: () => void) => (
  <MessageModal
    title={translateHelper('detail_class.detail_class_left_class')}
    content={
      <div className='text-center leading-7'>
        <p>{translateHelper('detail_class.detail_class_left_class_message_title')}</p>
        <p>{translateHelper('detail_class.detail_class_left_class_message_content')}</p>
      </div>
    }
    icon={
      <span className='anticon anticon-info-circle'>
        <Svg.CheckCircleTick />
      </span>
    }
    onClose={onClose}
  />
)
