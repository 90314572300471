import { atom } from 'recoil'
import { IDetailClassResponse } from '../api'

export const CLASSES_LIMIT_PAGE = 20

const classesDetailAtom = atom<IDetailClassResponse>({
  key: 'classesDetailAtom',
  default: {} as IDetailClassResponse,
})

export default classesDetailAtom
