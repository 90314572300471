import imagesAssets from '@assets/imgs'

export enum EEnv {
  BASE_API = 'REACT_APP_BASE_API',
  ROOT_WEB_URL = 'REACT_APP_ROOT_WEB_URL',
  ACCEPTED_TOLERANCE = 'REACT_APP_ACCEPTED_TOLERANCE',
  CONCENTRATION_LEVEL_DEFAULT = 'REACT_APP_CONCENTRATION_LEVEL_DEFAULT',
  DEMO_ACCOUNT_EMAIL = 'REACT_APP_DEMO_ACCOUNT_EMAIL',
  DEMO_ACCOUNT_PASSWORD = 'REACT_APP_DEMO_ACCOUNT_PASSWORD',
  AUTO_LOGIN_TIME = 'REACT_APP_AUTO_LOGIN_TIME',
  CONFIG_DASHBOARD = 'REACT_APP_CONFIG_DASHBOARD',
  CONFIG_LOGO = 'REACT_APP_CONFIG_LOGO',
  CONFIG_NAME_APP = 'REACT_APP_CONFIG_NAME_APP',
  CONFIG_BANNER_VI = 'REACT_APP_CONFIG_BANNER_VI',
  CONFIG_BANNER_EN = 'REACT_APP_CONFIG_BANNER_EN',
}

class Env {
  private variables: Record<EEnv, string | number>

  constructor() {
    this.variables = {
      [EEnv.BASE_API]: this.getEnvOrDefault(EEnv.BASE_API, 'https://dv.uperty.net/api'),
      [EEnv.ROOT_WEB_URL]: this.getEnvOrDefault(EEnv.ROOT_WEB_URL, 'https://www.deepview.win'),
      [EEnv.ACCEPTED_TOLERANCE]: +this.getEnvOrDefault(EEnv.ACCEPTED_TOLERANCE, 3),
      [EEnv.CONCENTRATION_LEVEL_DEFAULT]: +this.getEnvOrDefault(EEnv.CONCENTRATION_LEVEL_DEFAULT, 85),
      [EEnv.DEMO_ACCOUNT_EMAIL]: this.getEnvOrDefault(EEnv.DEMO_ACCOUNT_EMAIL, ''),
      [EEnv.DEMO_ACCOUNT_PASSWORD]: this.getEnvOrDefault(EEnv.DEMO_ACCOUNT_PASSWORD, ''),
      [EEnv.AUTO_LOGIN_TIME]: +this.getEnvOrDefault(EEnv.AUTO_LOGIN_TIME, 5),
      [EEnv.CONFIG_DASHBOARD]: this.getEnvOrDefault(EEnv.CONFIG_DASHBOARD, 'https://dashboard.deepview.social'),
      [EEnv.CONFIG_LOGO]: this.getEnvOrDefault(EEnv.CONFIG_LOGO, imagesAssets.deepViewLogoImg),
      [EEnv.CONFIG_NAME_APP]: this.getEnvOrDefault(EEnv.CONFIG_NAME_APP, 'Deepview'),
      [EEnv.CONFIG_BANNER_EN]: this.getEnvOrDefault(EEnv.CONFIG_BANNER_EN, imagesAssets.bannerEN),
      [EEnv.CONFIG_BANNER_VI]: this.getEnvOrDefault(EEnv.CONFIG_BANNER_VI, imagesAssets.bannerVI),
    }
  }

  private getEnvOrDefault(key: string, defaultValue: string | number): any {
    try {
      const envValue = process.env[key]

      const checkValue = typeof envValue === 'undefined' || (envValue && envValue === '')

      return checkValue ? defaultValue : envValue
    } catch (error) {
      return defaultValue
    }
  }

  public get(key: EEnv): any {
    return this.variables[key]
  }
}

export const envApp = new Env()
