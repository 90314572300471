import { CONFIG_APP } from '@constants/constants'

export default {
  'home.home': 'Trang chủ',
  'home.about_us': 'Giới thiệu',
  'home.help': 'Trợ giúp',
  'home.classroom': 'Lớp học',
  'home.dashboard': 'Quản lý',
  'home.response': 'Phản hồi',
  'home.team': 'Đội ngũ',
  'home.recruit': 'Tuyển dụng',
  'home.overview': 'Tổng quan',
  'home.store': 'Cửa hàng',
  'home.phone': 'Điện thoại: 0779611553',
  'home.email': 'Email: admin@deepview.win',
  'home.chat': 'Chat: m.me/ilovedeepview',
  'home.deepview': `${CONFIG_APP.nameApp} trên báo`,
  'home.yourVideos': 'Video của bạn',
  'home.search': 'Tìm kiếm',
  'home.free': 'Miễn phí',
  'home.sponsor': 'Tài trợ',
  'home.language': 'NGÔN NGỮ',
  'home.en': 'English',
  'home.vi': 'Tiếng Việt',
  'home.all': 'Tất cả',
}
