import { Spin } from 'antd'
import React from 'react'

const LoadingFullScreen: React.FC = () => {
  return (
    <div className='w-full h-full fixed flex justify-center items-center top-0 left-0 bg-white opacity-75 z-50'>
      <Spin />
    </div>
  )
}

export default LoadingFullScreen
