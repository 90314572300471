import * as React from 'react'
import { Alert, Checkbox, Col, Radio, Row } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'

import PrimaryBtn from '../Button/PrimaryBtn'
import BaseModal, { BaseModalProps } from './BaseModal'
import './styles.scss'
import { QuizzesType } from '../../types'
import pointRuleSelector from '../../recoil/pointRuleSelector'
import resultRecordAtom from '../../recoil/resultRecordAtom'

interface Props extends BaseModalProps {
  onClose: () => void
  quiz: QuizzesType
  quizIndex: number
}

function QuizzesModal(props: Props) {
  const { quiz, onClose, quizIndex, ...rest } = props
  const { t } = useTranslation()

  const { word, options, answers } = quiz
  const [userAnswers, setUserAnswers] = React.useState<string[]>([])
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const pointRule = useRecoilValue(pointRuleSelector)
  const [resultRecord, setResultRecord] = useRecoilState(resultRecordAtom)

  const isOnlyAnswers = answers && answers.length === 1

  const handleAnswer = (checked: boolean, option: string) => {
    if (isOnlyAnswers) {
      setUserAnswers([option])
      return
    }

    if (checked) {
      setUserAnswers([...userAnswers, option])
    } else {
      setUserAnswers(userAnswers.filter((answer) => answer !== option))
    }
  }

  const isCorrect = React.useMemo(() => {
    if (answers.length !== userAnswers.length) return false
    const answerMap = answers.reduce((map: Record<string, boolean>, answer) => {
      map[answer] = true
      return map
    }, {})
    return userAnswers.every((userAnswer) => answerMap[userAnswer])
  }, [answers, userAnswers])

  const handleSubmit = () => {
    const { show_at, options, ...restQuiz } = quiz
    setResultRecord({
      ...resultRecord,
      quizzes: [
        ...resultRecord.quizzes,
        {
          ...restQuiz,
          result: isCorrect,
          point: isCorrect ? pointRule.addQuizNumber : pointRule.subtractQuizNumber,
          answers: userAnswers,
        },
      ],
    })
    setIsSubmitted(true)
  }

  const renderOptionAnswers = () => {
    if (isOnlyAnswers) {
      return (
        <>
          {options.map((option) => (
            <Col key={option} span={12}>
              <Radio
                checked={userAnswers.includes(option)}
                onChange={(e) => handleAnswer(e.target.checked, option)}
                disabled={isSubmitted}
              >
                {option}
              </Radio>
            </Col>
          ))}
        </>
      )
    }

    return (
      <>
        {options.map((option) => (
          <Col key={option} span={12}>
            <Checkbox
              checked={userAnswers.includes(option)}
              onChange={(e) => handleAnswer(e.target.checked, option)}
              disabled={isSubmitted}
            >
              {option}
            </Checkbox>
          </Col>
        ))}
      </>
    )
  }

  return (
    <BaseModal
      title={`${t('video.quiz_label')} ${quizIndex + 1}`}
      footer={
        !isSubmitted ? (
          <PrimaryBtn disabled={userAnswers.length === 0} onClick={handleSubmit}>
            {t('video.quiz_submit')}
          </PrimaryBtn>
        ) : (
          <PrimaryBtn onClick={onClose}>{t('video.continue')}</PrimaryBtn>
        )
      }
      centered
      className='quiz-modal'
      {...rest}
    >
      <p className='mb-3'>{word}</p>
      <Row gutter={[10, 10]}>{renderOptionAnswers()}</Row>

      {isSubmitted && (
        <div style={{ marginTop: 15 }}>
          {isCorrect ? (
            <Alert message={t('video.correct')} type='info' showIcon icon={<CheckCircleFilled />} />
          ) : (
            <Alert message={t('video.incorrect')} type='error' showIcon />
          )}
        </div>
      )}
    </BaseModal>
  )
}

export default QuizzesModal
