import UserSVG from './UserSVG'
import Search from './Search'
import Gift from './Gift'
import Exit from './Exit'
import Close from './Close'
import Minus from './Minus'
import Plus from './Plus'
import Done from './Done'
import Menu from './Menu'
import Back from './Back'
import Home from './Home'
import Coupon from './Coupon'
import Next from './Next'
import Cart from './Cart'
import VideoPlay from './VideoPlay'
import History from './History'
import Logout from './Logout'
import Withdraw from './Withdraw'
import Copy from './Copy'
import Play from './Play'
import Eye from './Eye'
import CheckCircle from './CheckCircle'
import CheckCircleTick from './CheckCircleTick'
import Registration from './Registration'
import Sao from './Sao'
import NextDiv from './NextDiv'
import NextNext from './NextNext'
import Info from './Info'
import BxBulb from './BxBulb'
import Crown from './Crown'
import Feature from './Feature'
import Lesson from './Lesson'
import Respon from './Respon'
import Student from './Student'
import CopyInformation from './CopyInformation'

const Svg = {
  BxBulb,
  Student,
  Crown,
  Feature,
  Lesson,
  Respon,
  Info,
  CopyInformation,
  NextDiv,
  CheckCircle,
  CheckCircleTick,
  UserSVG,
  Search,
  Gift,
  Exit,
  Close,
  Minus,
  Done,
  Plus,
  Menu,
  Back,
  Home,
  Coupon,
  Next,
  Cart,
  VideoPlay,
  History,
  Logout,
  Withdraw,
  Copy,
  Play,
  Eye,
  Sao,
  Registration,
  NextNext,
}

export default Svg
