import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export interface INotiConfigAtom {
  isShow: boolean
}

const notiConfigAtom = atom<INotiConfigAtom>({
  key: 'notiConfigAtom',
  default: {
    isShow: true,
  },
  effects_UNSTABLE: [persistAtom],
})

export default notiConfigAtom
