/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const Crown: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#1E88E5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.719 3.37498L8.5 7.39898L5.281 3.37498C5.15099 3.21426 4.97444 3.09769 4.77558 3.04125C4.57671 2.9848 4.36525 2.99125 4.1702 3.0597C3.97514 3.12815 3.80602 3.25526 3.68604 3.4236C3.56605 3.59193 3.50107 3.79326 3.5 3.99998V19C3.5 20.103 4.397 21 5.5 21H19.5C20.603 21 21.5 20.103 21.5 19V3.99998C21.4989 3.79326 21.4339 3.59193 21.314 3.4236C21.194 3.25526 21.0249 3.12815 20.8298 3.0597C20.6347 2.99125 20.4233 2.9848 20.2244 3.04125C20.0256 3.09769 19.849 3.21426 19.719 3.37498L16.5 7.39898L13.281 3.37498C12.9 2.90098 12.1 2.90098 11.719 3.37498ZM5.5 19V17H19.501V19H5.5ZM15.719 9.62498C16.1 10.1 16.901 10.1 17.282 9.62498L19.5 6.85098L19.501 15H5.5V6.85098L7.719 9.62498C8.1 10.1 8.901 10.1 9.282 9.62498L12.5 5.60098L15.719 9.62498Z'
        fill={color}
      />
    </svg>
  )
}

export default Crown
