import { recoilPersist } from 'recoil-persist'
import { atom } from 'recoil'

const { persistAtom } = recoilPersist()

export interface DemoAccountAtom {
  date: string
  numberOfMinutesUsed: number
}

const demoAccountAtom = atom<Partial<DemoAccountAtom>>({
  key: 'demoAccountAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export default demoAccountAtom
