import React, { useEffect, useImperativeHandle } from 'react'
import { useRecoilState } from 'recoil'

import { hideModal, showModal } from '@managers/GlobalModal'
import userInfoAtom from '@recoil/userInfoAtom'

import { ShowLimitNumberModal, TimeOutModal } from './components'
import { isSameDate } from './helpers'
import remainingMinutesAtom from './recoil/remainingMinutesAtom'
import { RemainingMinutesParams, remainingMinutesRef } from './RemainingMinutesManager'
import { ShowMinutesRemainingModal } from './components/ShowMinutesRemainingModal'
import { EnoughType } from './components/TimeOutModal/TimeOutModal'

const RemainingMinutes = () => {
  const [userInfo] = useRecoilState(userInfoAtom)
  const [remainingMinutesInfo, setRemainingMinutesInfo] = useRecoilState(remainingMinutesAtom)

  const handleClose = () => {
    if (!userInfo?.email) return

    setRemainingMinutesInfo({
      log: {
        date: new Date(),
        email: userInfo?.email,
        isShowNoted: true,
      },
    })
    hideModal()
  }

  const handleRemainingClose = () => {
    hideModal()
  }

  useImperativeHandle(remainingMinutesRef, () => ({
    showModal(params?: RemainingMinutesParams) {
      showModal({
        content: <TimeOutModal onCloseModal={handleRemainingClose} />,
        onCancel: params?.callBackOnClose ?? handleRemainingClose,
      })
    },
    hideModal() {
      //
    },
    showEndTimeOutModal(params?: RemainingMinutesParams) {
      showModal({
        content: <TimeOutModal enoughType={EnoughType.AVAILABLE} onCloseModal={handleRemainingClose} />,
        onCancel: params?.callBackOnClose ?? handleRemainingClose,
      })
    },
    show5MinutesModal(params?: RemainingMinutesParams) {
      showModal({
        content: <ShowMinutesRemainingModal onCloseModal={handleRemainingClose} />,
        onCancel: params?.callBackOnClose ?? handleRemainingClose,
      })
    },
    showPendingUpgradeModal(params?: RemainingMinutesParams) {
      showModal({
        content: <TimeOutModal enoughType={EnoughType.PENDING_UPGRADE} onCloseModal={handleRemainingClose} />,
        onCancel: params?.callBackOnClose ?? handleRemainingClose,
      })
    },
  }))

  useEffect(() => {
    const { date } = remainingMinutesInfo?.log ?? {}
    // check email check date check showsh
    const hasMinutesLimit =
      userInfo?.customer?.minutes_limit !== 0 && userInfo?.minutes_limit && userInfo?.minutes_limit > 0
    const notShownYet = !isSameDate(date)
    const isNeedShowModal = !!userInfo?.email && hasMinutesLimit && notShownYet

    if (isNeedShowModal) {
      showModal({
        content: <ShowLimitNumberModal onCloseModal={handleClose} />,
        onCancel: handleClose,
      })
    }
  }, [userInfo?.email])

  return <></>
}

export default RemainingMinutes
