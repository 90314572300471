import React from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import moment from 'moment'

import './DatePickerBase.scss'
import Date from '../../Svg/Date'

const RangePickerBase = DatePicker.RangePicker as unknown as React.FC<any>

const customWeekStartEndFormat: DatePickerProps['format'] = (value) => moment(value).format('DD/MM/YYYY')

const DatePickerBase: React.FC = () => {
  return (
    <RangePickerBase
      className='date-picker-base'
      format={customWeekStartEndFormat}
      suffixIcon={<Date width={16} height={18} />}
      separator='-'
    />
  )
}

export default DatePickerBase
