import { message } from 'antd'
import Logger from '@managers/Logger'

import { VideoType } from '../../../types'
import request, { IError } from '../../../utils/request'
import { messageLeave } from './helpers/message'
import { VideoStatus } from './models'

export interface IClassesPayload {
  status?: string
  page?: number
  page_limit?: number
  q?: string
}

export interface IClass {
  _id: string
  created_at: string
  description: string
  finished_at: string
  name: string
  organizer: IOrganizer
  status: string
  updated_at: string
  started_at: string
  total_users: number
  total_videos: number
  user_status: VideoStatus
}

export interface IOrganizer {
  _id: string
  name: string
}

export interface IClassesResponse {
  classes: IClass[]
  total_rows: number
}

export interface IJoinClassesResponse {
  message: EJoin
}

export enum EJoin {
  SUCCESS = 'SUCCESS',
  ERROR_ALREADY_ENROLLED = 'ERROR_ALREADY_ENROLLED',
  ERROR_CLASS_NOT_FOUND = 'ERROR_CLASS_NOT_FOUND',
  ERROR_DROPPED_REQUEST = 'ERROR_DROPPED_REQUEST',
  ERROR_PENDING_REQUEST = 'ERROR_PENDING_REQUEST',
  ERROR_CANCELLED_REQUEST = 'ERROR_CANCELLED_REQUEST',
}

export enum EVideoStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum ESortVideos {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
}

export enum EClassDetailError {
  NOT_STARTED = 'ERROR_CLASS_NOT_STARTED',
  PENDING = 'ERROR_PENDING_REQUEST',
  DROPPED = 'ERROR_DROPPED_REQUEST',
  CANCELLED = 'ERROR_CANCELLED_REQUEST',
}

export interface IVideoClassesPayload {
  page?: number
  page_limit?: number
  q?: string
  status?: EVideoStatus
  sort_by?: ESortVideos
}

export interface IVideoTypeResponse {
  page: number
  page_limit: number
  total_rows: number
  videos: VideoType[]
}

export interface IUserClass {
  completion_date: string
  concentration_level: number
  enrollment_date: string
  leaving_date: string
  name: string
  num_lessons_completed: number
  status: string
  total_score: number
  total_views: number
}

export interface IDetailClassResponse {
  class: IClass
  user: IUserClass
  message?: EClassDetailError
}

const handleError = (error: IError) => {
  Logger.info('error handleJoinError', error)
}

const handleLeaveError = (error: IError) => {
  message.error(messageLeave[error.message] ?? error.message)
}

export const classesAPIs = {
  getClasses: async (payload: IClassesPayload): Promise<IClassesResponse> => {
    return request.getWithAuthV2(`/classes`, payload)
  },
  detailClasses: async (id: string): Promise<IDetailClassResponse> => {
    return request.getWithAuthV2(`/classes/${id}`, undefined, { handleError })
  },
  joinClasses: async (id: string): Promise<IJoinClassesResponse> => {
    return request.postWithAuth(`/classes/${id}/join`, {}, { handleError })
  },
  leaveClasses: async (id: string): Promise<IJoinClassesResponse> => {
    return request.postWithAuth(`/classes/${id}/leave`, {}, { handleError: handleLeaveError })
  },
  getVideoClasses: async (classesId: string, payload: IVideoClassesPayload = {}): Promise<IVideoTypeResponse> => {
    return request.getWithAuthV2(`/classes/${classesId}/videos`, payload)
  },
}
