import { EEnv, envApp } from './config'

// export const BASE_API = "http://localhost:5001";
// export const BASE_API = 'https://dv-stg.uperty.net/api'
// export const BASE_API = "https://api.deepcare.net/api";
export const BASE_API = envApp.get(EEnv.BASE_API)
export const ROOT_WEB_URL = envApp.get(EEnv.ROOT_WEB_URL)
export const ACCEPTED_TOLERANCE = +envApp.get(EEnv.ACCEPTED_TOLERANCE)
export const CONCENTRATION_LEVEL_DEFAULT = envApp.get(EEnv.CONCENTRATION_LEVEL_DEFAULT)
export const DEMO_ACCOUNT = {
  email: envApp.get(EEnv.DEMO_ACCOUNT_EMAIL),
  password: envApp.get(EEnv.DEMO_ACCOUNT_PASSWORD),
}
export const AUTO_LOGIN_TIME = +envApp.get(EEnv.AUTO_LOGIN_TIME)

export const CONFIG_APP = {
  logo: envApp.get(EEnv.CONFIG_LOGO),
  dashboardUrl: envApp.get(EEnv.CONFIG_DASHBOARD),
  nameApp: envApp.get(EEnv.CONFIG_NAME_APP),
  bannerVI: envApp.get(EEnv.CONFIG_BANNER_VI),
  bannerEN: envApp.get(EEnv.CONFIG_BANNER_EN),
}
