import imagesAssets from '../../assets/imgs'

const ShareBtn = () => {
  return (
    <a
      className='shareWrapper'
      href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`}
      target='_blank'
      rel='noreferrer'
    >
      <img src={imagesAssets.facebookShare} className='facebookShare' alt='facebook' />
    </a>
  )
}

export default ShareBtn
