/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const Lesson: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#939393' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.88132 19.1429V3.90476H6.78609V10.5714L9.16704 9.14286L11.548 10.5714V3.90476H16.3099V10.6476C16.6242 10.6 16.9385 10.5714 17.2623 10.5714C17.5861 10.5714 17.9004 10.6 18.2147 10.6476V3.90476C18.2147 2.85714 17.3575 2 16.3099 2H4.88132C3.83371 2 2.97656 2.85714 2.97656 3.90476V19.1429C2.97656 20.1905 3.83371 21.0476 4.88132 21.0476H11.7956C11.3956 20.4762 11.0813 19.8286 10.8718 19.1429H4.88132ZM17.2623 12.4762C14.6337 12.4762 12.5004 14.6095 12.5004 17.2381C12.5004 19.8667 14.6337 22 17.2623 22C19.8908 22 22.0242 19.8667 22.0242 17.2381C22.0242 14.6095 19.8908 12.4762 17.2623 12.4762ZM16.0718 19.619V14.8571L19.8813 17.2381L16.0718 19.619Z'
        fill={color}
      />
    </svg>
  )
}

export default Lesson
