import React from 'react'
import imagesAssets from '@assets/imgs'

import MainButton from '@components/Button/MainButton'
import Svg from '@components/Svg'
import DangerButton from '@components/Button/DangerButton'
import { useHistory } from 'react-router-dom'
import pathUrl from '@routes/path'

interface Props {
  onCloseModal: () => void
}

const ShowMinutesRemainingModal: React.FC<Props> = ({ onCloseModal }) => {
  const history = useHistory()

  const handleClose = () => {
    onCloseModal()
  }

  const redirectUpgradeAccount = () => {
    handleClose()
    history.push(pathUrl.upgradeAccount())
  }

  return (
    <div className='limit-number-wrapper'>
      <div className='title text-[#3083ff] bg-cover flex flex-col justify-center items-center relative'>
        <div className='font-bold text-4xl text-[#D32F2F]'>Thông báo</div>
        <div className='absolute right-0 top-0 cursor-pointer' onClick={handleClose}>
          <Svg.Close />
        </div>
      </div>

      <div className='text-center mt-4'>
        <div className='text-lg'>Thời gian xem miễn phí trong ngày còn </div>
        <div className='flex items-center justify-center text-[#D32F2F] text-5xl gap-3 my-5'>
          <span className=''>5</span> <span className='text-lg'>phút</span> <span>00</span>
          <span className='text-lg'>giây</span>
        </div>
        <div className='text-lg '>Hãy nâng cấp gói để xem thêm nhiều video nhé!</div>
      </div>
      <div className='flex justify-center gap-6 mb-7 mt-4'>
        <DangerButton className='uppercase min-w-[200px]' onClick={handleClose}>
          bỏ qua
        </DangerButton>
        <MainButton className='uppercase min-w-[200px]' onClick={redirectUpgradeAccount}>
          nâng cấp ngay
        </MainButton>
      </div>
    </div>
  )
}

export default ShowMinutesRemainingModal
