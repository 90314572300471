import React from 'react'
import { Col, Row, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

import imagesAssets from '@assets/imgs'
import { VideoType } from 'types'
import VideoCollectionItem from '@containers/VideoQuiz/components/VideoCollection/VideoCollectionItem'

type Props = {
  loading: boolean
  videos: VideoType[]
  fetchMoreData: () => void
  hasMore: boolean
}

const VideoListZone: React.FC<Props> = ({ loading, videos, hasMore, fetchMoreData }) => {
  const renderEmpty = () => {
    return (
      <div className='flex pt-8 justify-center items-center w-full h-full'>
        <div>
          <img alt='logo' className='w-full max-w-[400px]' src={imagesAssets.emptyVideo} />
          <p className='text-center mt-8 mb-6 font-semibold'>Danh mục này chưa cập nhật video</p>
          <p className='text-center'>Hãy thử đến với các danh mục khác</p>
        </div>
      </div>
    )
  }

  const loader = (
    <div key='loader' className='loader'>
      Loading ...
    </div>
  )

  if (loading) {
    return (
      <div className='globalLoadingContainer'>
        <Spin />
      </div>
    )
  }

  if (videos.length === 0) {
    return renderEmpty()
  }

  return (
    <InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} loader={loader}>
      <Row className='p-4' gutter={[20, 20]}>
        {videos.map((video) => (
          <Col key={video.id} className='gutter-row' span={24} md={8} lg={6}>
            <VideoCollectionItem videoDetail={video} />
          </Col>
        ))}
      </Row>
    </InfiniteScroll>
  )
}

export default VideoListZone
