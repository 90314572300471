import React, { useState } from 'react'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { CheckCircleFilled } from '@ant-design/icons'

import Logger from '@managers/Logger'

import appModalAtom from '@recoil/appModalAtom'

import PrimaryBtn from '@components/Button/PrimaryBtn'
import Slide from '@containers/HomePage/Slide'
import LineOrBase from '@components/Line/LineOrBase'

import { ModalType } from 'types/enum'

import { authApis } from '../auth.apis'

const ForgetPassword = () => {
  const [, setAppModal] = useRecoilState(appModalAtom)
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const [sendEmail, setSendEmail] = useState(false)

  const _handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields()

      const { email } = fieldsValue
      const res = await authApis.requestPasswordReset(email)

      if (res.message === 'PASSWORD_RESET_LINK_SENT') {
        setSendEmail(true)
      } else {
        message.error(`${t('error.emailNotFound')}`)
      }
    } catch (error) {
      Logger.error('_handleSubmit ForgetPassword ', error)
      message.error(`${t('error.emailNotFound')}`)
    }
  }

  const renderContent = () => {
    return (
      <div className='flex flex-col justify-center items-center m-auto sm:mt-2 min-h-[95vh] py-3 max-w-[400px] sm:px-6'>
        <Form layout='vertical' form={form}>
          <Row gutter={{ lg: 15 }}>
            <Col xs={24}>
              <div className='font-bold text-2xl text-center'>Quên mật khẩu</div>
            </Col>
            {sendEmail && (
              <>
                <Col xs={24}>
                  <div className='flex justify-center items-center bg-[#E0F4C6] py-4 px-5 gap-3 text-[#2B8700] my-4'>
                    <CheckCircleFilled style={{ color: '#2B8700', fontSize: 24 }} /> {t('auth.checkResetPassword')}
                  </div>
                </Col>
              </>
            )}
            <Col xs={24}>
              <Form.Item
                label={t('login.emailLogin')}
                name='email'
                rules={[
                  { required: true, message: t('validate.require') ?? '' },
                  {
                    type: 'email',
                    message: `${t('validate.email')}`,
                  },
                ]}
              >
                <Input placeholder={t('login.emailLoginPlaceholder') ?? ''} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <PrimaryBtn
                className='w-full md:!rounded-[100px] md:mt-4'
                disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
                onClick={_handleSubmit}
              >
                {t('login.reset_password')}
              </PrimaryBtn>
            </Col>
          </Row>
        </Form>
        <div className='w-full flex justify-center flex-col items-center'>
          <LineOrBase />

          <Button type='link' onClick={() => setAppModal({ mode: ModalType.signIn })}>
            {t('login.sign_in')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='homePageContainer bg-[#ffffff] min-h-[95vh]'>
        <Row className='h-full'>
          <Col span={4} xs={0} md={0} lg={4} className=''>
            <Slide />
          </Col>
          <Col className='h-full' span={20} xs={24} md={24} lg={20}>
            {renderContent()}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ForgetPassword
