import React from 'react'

type Props = {
  name?: React.ReactNode
  data?: React.ReactNode
  img?: string
  color?: string
  nameClassName?: string
  dataClassName?: string
}

const ItemData: React.FC<Props> = ({
  color = '#6900C7',
  name,
  img,
  data,
  nameClassName = '',
  dataClassName = 'text-[#1C62B9]',
}) => {
  return (
    <div className='bg-[white] min-w-[100px] flex flex-row rounded-[5px] w-full p-7 relative h-full'>
      <div className='absolute h-full top-0 rounded-[10px] w-[20px] -z-10 left-[-5px]' style={{ background: color }} />
      <div className='flex-1 flex flex-col justify-between overflow-hidden'>
        <div className={`text-[#939393] text-lg font-medium ${nameClassName ?? ''}`}>{name}</div>
        <div className={`font-bold text-2xl mt-3 ${dataClassName ?? ''}`}>{data ?? '...'}</div>
      </div>
      <div className='flex self-center'>
        <img className='' src={img} alt='icon' />
      </div>
    </div>
  )
}

export default ItemData
