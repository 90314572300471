import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import PrimaryBtn from '@components/Button/PrimaryBtn'
import SecondaryBtn from '@components/Button/SecondaryBtn'

import Svg from '@components/Svg'
import { hideModal } from '@managers/GlobalModal'
import appModalAtom from '@recoil/appModalAtom'
import { ModalType } from 'types/enum'
import imagesAssets from '@assets/imgs'
import { AUTO_LOGIN_TIME, DEMO_ACCOUNT } from '@constants/constants'
import { useTranslation } from 'react-i18next'
import { apis } from '@apis'
import userInfoAtom from '@recoil/userInfoAtom'
import authAtom from '@containers/Auth/atom/authAtom'
import Logger from '@managers/Logger'

const InfoAccountModal: React.FC = () => {
  const { t } = useTranslation()

  const [, setAppModal] = useRecoilState(appModalAtom)
  const [, setUserInfo] = useRecoilState(userInfoAtom)
  const [, setAuth] = useRecoilState(authAtom)

  const [countDownLogin, setCountDownLogin] = useState(AUTO_LOGIN_TIME)

  const handleClose = () => {
    hideModal()
  }

  const handleSubmit = () => {
    hideModal()

    setAppModal({
      mode: ModalType.signIn,
    })
  }

  const handleLoginDemoAccount = async () => {
    try {
      handleClose()

      const response = await apis.postSignIn({ password: DEMO_ACCOUNT.password, email: DEMO_ACCOUNT.email })

      setAuth({ accessToken: response.access_token })
      setUserInfo({ ...response.user })
    } catch (error) {
      Logger.error('handleLoginDemoAccount', error)
    }
  }

  useEffect(() => {
    const autoLoginTimeInterval = setInterval(() => {
      setCountDownLogin((value) => (value > 0 ? value - 1 : 0))
    }, 1000)

    return () => {
      clearInterval(autoLoginTimeInterval)
    }
  }, [])

  useEffect(() => {
    if (countDownLogin <= 0) {
      handleLoginDemoAccount()
    }
  }, [countDownLogin])

  return (
    <div className='relate'>
      <div className='cursor-pointer absolute right-3 top-3' onClick={handleClose}>
        <Svg.Close />
      </div>
      <div className='w-full flex justify-center mb-4'>
        <img src={imagesAssets.info} alt='logo' className='max-h-[72px]' />
      </div>
      <div className='flex w-full'>
        <div className='flex-1 font-medium uppercase text-3xl ml-3 mb-4 text-center text-[#1F91FA]'>
          {t('demoAccount.title')}
        </div>
      </div>
      <div className='text-[#606060] text-center leading-7'>
        <div className='text-lg'>{t('demoAccount.content', { time: AUTO_LOGIN_TIME })}</div>
        <div className='flex gap-5 w-full justify-center mt-3 mb-3'>
          <SecondaryBtn className='!text-[#5C5C5C] w-[120px]' onClick={handleLoginDemoAccount}>
            DEMO ({countDownLogin}s)
          </SecondaryBtn>
          <PrimaryBtn className='w-[120px]' onClick={handleSubmit}>
            {t('demoAccount.login')}
          </PrimaryBtn>
        </div>
      </div>
    </div>
  )
}

export default InfoAccountModal
