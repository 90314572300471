import React, { useCallback } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import imagesAssets from '../../assets/imgs'
import VideoCollectionItem from '../../containers/VideoQuiz/components/VideoCollection/VideoCollectionItem'
import { VideoType } from '../../types'

import './styles.scss'
import './hideScrollbar.css'

interface Props<P = any> {
  data: P[]
  renderItem: (item: P, index: number) => JSX.Element
  className?: string
  nextImage?: string
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  const handlePrev = () => {
    scrollPrev()
  }

  return (
    <div
      style={{ visibility: isFirstItemVisible ? 'hidden' : 'unset' }}
      onClick={handlePrev}
      className='scrollIcon iconLeft'
    >
      <img src={imagesAssets.previousIcon} alt='' />
    </div>
  )
}

function RightArrow({ nextImage }: { nextImage: string }) {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  const handleNext = () => {
    scrollNext()
  }

  return (
    <div
      style={{ visibility: isLastItemVisible ? 'hidden' : 'unset' }}
      onClick={handleNext}
      className='scrollIcon iconRight'
    >
      <img src={nextImage} alt='' />
    </div>
  )
}

const VideoItem = ({ video }: { video: VideoType }) => (
  <div className='videoCollectionItemWrapper'>
    <VideoCollectionItem videoDetail={video} />
  </div>
)

export function Card({ data, itemId }: { data: VideoType; itemId: string }) {
  const visibility = React.useContext(VisibilityContext)

  // dont remove itemId
  const visible = visibility.isItemVisible(itemId)

  return <VideoItem video={data} />
}

export const CardWrapper: React.FC<{
  data: any
  itemId: string
  index: number
  render: (data: any, index: number) => void
}> = ({ data, itemId, render, index }) => {
  const visibility = React.useContext(VisibilityContext)

  const visible = visibility.isItemVisible(itemId)

  return <div>{render(data, index)}</div>
}

const HorizontalScrolling: React.FC<Props> = (props) => {
  const { data, renderItem, className, nextImage } = props

  const renderList = () => {
    if (renderItem) {
      return (
        data &&
        data.map((item, index) => (
          <CardWrapper key={item.id} index={index} data={item} render={renderItem} itemId={item.id} />
        ))
      )
    }

    return data && data.map((item) => <Card key={item.id} data={item} itemId={item.id} />)
  }

  if (!data) {
    return <></>
  }

  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={<RightArrow nextImage={nextImage || imagesAssets.nextIcon} />}
      wrapperClassName={`hozScroll ${className ?? ''}`}
    >
      {renderList()}
    </ScrollMenu>
  )
}

export default HorizontalScrolling
