import * as React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { ModalManager } from '@managers/GlobalModal'
import { LangManager } from '@lang/langManager'

import { SignUp } from '@containers/SignUp'
import { CategoryPage } from '@containers/CategoryPage'

import { EmailVerification } from '@containers/Auth'
import ForgetPassword from '@containers/Auth/screens/ForgetPassword'
import ResetPassword from '@containers/Auth/screens/ResetPassword'

import { SubscriptionsPlans, UpgradeAccount } from '@containers/SubscriptionsPlans'
import useInfoProfile from '@components/useHook/useInfoProfile'
import Logger from '@managers/Logger'

import './App.scss'
import '../lang/AppI18n'
import AppTrigger from './AppTrigger'
import pathUrl from '../routes/path'

import VideoQuiz from '../containers/VideoQuiz'
import Header from '../components/Header'
import HomePage from '../containers/HomePage'
import Overview from '../containers/Statistical/Overview'
import { RegistrationForm } from '../containers/SLO/Registration'
import AppInitControl from './AppInitControl'

const WrapperApp: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <div className='App'>{children}</div>
    </>
  )
}

const AppBase = () => {
  const [isInitApp, setIsInitApp] = React.useState(false)

  const { handleRefreshProfile } = useInfoProfile()

  React.useEffect(() => {
    const handleInitApp = async () => {
      try {
        await handleRefreshProfile()
        setIsInitApp(true)
      } catch (error) {
        setIsInitApp(true)
        Logger.error('handleInitApp', error)
      }
    }
    handleInitApp()
  }, [])

  if (!isInitApp) {
    return <>Loading...</>
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={pathUrl.registrationSLO()}
          render={() => (
            <>
              <RegistrationForm />
            </>
          )}
        />
        <Route
          exact
          path={pathUrl.signup()}
          render={() => (
            <>
              <SignUp />
            </>
          )}
        />
        <Route
          exact
          path={pathUrl.videoDetail()}
          render={() => (
            <WrapperApp>
              <VideoQuiz />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.upgradeAccount()}
          render={() => (
            <WrapperApp>
              <UpgradeAccount />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.subscriptionsPlans()}
          render={() => (
            <WrapperApp>
              <SubscriptionsPlans />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.overview()}
          render={() => (
            <WrapperApp>
              <Overview />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.forgetPassword()}
          render={() => (
            <WrapperApp>
              <ForgetPassword />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.passwordReset()}
          render={() => (
            <WrapperApp>
              <ResetPassword />
            </WrapperApp>
          )}
        />
        <Route
          path={pathUrl.emailVerification()}
          render={() => (
            <WrapperApp>
              <EmailVerification />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.videoByCategory()}
          render={() => (
            <WrapperApp>
              <VideoQuiz />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.learnVideo()}
          render={() => (
            <WrapperApp>
              <VideoQuiz />
            </WrapperApp>
          )}
        />
        <Route
          exact
          path={pathUrl.videoCategory()}
          render={() => (
            <WrapperApp>
              <CategoryPage />
            </WrapperApp>
          )}
        />
        <Route
          path={pathUrl.home()}
          render={() => (
            <WrapperApp>
              <HomePage />
            </WrapperApp>
          )}
        />
      </Switch>
      <AppTrigger />
      <ModalManager />
    </>
  )
}

function App() {
  return (
    <Router>
      <AppInitControl app={<AppBase />} />
      <LangManager />
    </Router>
  )
}

export default App
