// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context: any) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || []
    const bodyLines = tooltip.body.map((b) => b.lines)

    const tableHead = document.createElement('thead')

    titleLines.forEach((title) => {
      const tr = document.createElement('tr')
      tr.style.borderWidth = 0

      const th = document.createElement('th')
      th.style.borderWidth = 0
      th.style.color = 'gray'
      th.style.minWidth = '120px'
      const text = document.createTextNode(title)

      th.appendChild(text)
      tr.appendChild(th)
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement('tbody')
    bodyLines.forEach((body) => {
      const tr = document.createElement('tr')
      tr.style.backgroundColor = 'inherit'
      tr.style.borderWidth = 0

      const td = document.createElement('td')
      td.style.borderWidth = 0

      const bodyContent = document.createElement('div')
      bodyContent.innerHTML = `<div class="flex">Số lượt xem: <div class="font-semibold ml-2">${body}</div><div>`

      td.appendChild(bodyContent)
      tr.appendChild(td)
      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector('table')

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.backgroundColor = 'white'
  tooltipEl.style.color = 'black'
  tooltipEl.style.border = '1px solid rgba(0, 0, 0, 0.16)'
  tooltipEl.style.filter = 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16))'
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
}
