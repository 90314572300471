/* eslint-disable no-unused-expressions */
import { Button, Input, InputRef } from 'antd'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { CONFIG_APP } from '@constants/constants'

import videosAtom from '../../../recoil/videosAtom'
import pathUrl from '../../../routes/path'
import Svg from '../../Svg'
import BaseModal, { BaseModalProps } from '../BaseModal'
import './styles.scss'

interface Props extends BaseModalProps {
  onCancel: () => void
}

const SearchModal: React.FC<Props> = (props) => {
  const history = useHistory()

  const searchRef = React.useRef<InputRef>(null)

  const [_, setVideosStore] = useRecoilState(videosAtom)

  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  const handleSubmit = () => {
    setVideosStore((value) => ({
      ...value,
      filter: {
        ...value?.filter,
        search: searchRef?.current?.input?.value ?? '',
      },
    }))

    history.push(pathUrl.home())
    props.onCancel && props.onCancel()
  }

  React.useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        searchRef?.current?.focus()
      }, 100)
    }
  }, [props.open])

  return (
    <BaseModal className='searchModal' {...props}>
      <div className='searchWrapper'>
        <div>
          <Button onClick={handleClose} className='border-none'>
            <Svg.Back />
          </Button>
        </div>
        <Input
          ref={searchRef}
          className='searchInput'
          autoFocus
          placeholder={`Tìm kiếm trên ${CONFIG_APP.nameApp}`}
          suffix={
            <span onClick={handleSubmit}>
              <Svg.Search color='#000000' height={18} width={18} />
            </span>
          }
          onSubmit={handleSubmit}
        />
      </div>
    </BaseModal>
  )
}

export default SearchModal
