import React from 'react'

const LineOrBase = () => {
  return (
    <div className='flex justify-center relative my-7 w-full'>
      <div className='absolute  border-t border-t-[#D0D5DD] top-[10px] left-0 w-full' />
      <div className='z-10'>
        <span className='bg-[#ffffff] px-2 font-medium text-[#697077] text-sm'>Hoặc</span>
      </div>
    </div>
  )
}

export default LineOrBase
