import { Col, Row } from 'antd'
import React from 'react'
import Svg from '../../../../components/Svg'

type Props = {
  name: string
  isRequired?: boolean
}

const ItemForm: React.FC<Props> = ({ name, isRequired, children }) => {
  return (
    <Row>
      <Col span={24} className='text-white font-[600] flex items-center mb-2'>
        {isRequired ? (
          <div className='mr-1'>
            <Svg.Sao />
          </div>
        ) : (
          <></>
        )}

        {name}
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  )
}

export default ItemForm
