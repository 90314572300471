import { recoilPersist } from 'recoil-persist'
import { atom, selector } from 'recoil'
import { UserType } from '../types'
import { CONCENTRATION_LEVEL_DEFAULT } from '../constants/constants'

const { persistAtom } = recoilPersist()

const userInfoAtom = atom<UserType>({
  key: 'userInfoAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const concentrationLevelSelector = selector({
  key: 'concentrationLevelSelector',
  get: ({ get }) => {
    return get(userInfoAtom).customer?.concentration_level ?? CONCENTRATION_LEVEL_DEFAULT
  },
})

export default userInfoAtom
