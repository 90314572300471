import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import LoggerBase from '@managers/Logger'

import BaseModal from '@components/Modal/BaseModal'
import PrimaryBtn from '@components/Button/PrimaryBtn'
import SecondaryBtn from '@components/Button/SecondaryBtn'

import appConfigAtom from '@recoil/appConfigAtom'
import pathUrl from 'routes/path'
import { hideModal, showModal } from '@managers/GlobalModal'

import { classesAPIs } from '../../api'
import { leaveClass } from '../../components'

export interface IVideoHistory {
  name: string
  view: number
  question: string
  point: string
  time: string
}

type Props = {
  showHistoryModal?: boolean
  onClose?: () => void
  idClass: string
}

const OutLessonsModal: React.FC<Props> = ({ onClose, showHistoryModal, idClass }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const setAppConfig = useSetRecoilState(appConfigAtom)

  const closeModal = () => {
    history.push(pathUrl.classroom())
    hideModal()
  }

  const handleSubmit = async () => {
    setAppConfig((value) => ({ ...value, loading: true }))

    try {
      const response = await classesAPIs.leaveClasses(idClass)

      onClose && onClose()

      showModal({
        content: leaveClass(() => {
          closeModal()
        }),
        onCancel: closeModal,
      })

      LoggerBase.info('leaveClasses', response)
    } catch (error) {
      LoggerBase.error('leaveClasses', error)
    }

    setAppConfig((value) => ({ ...value, loading: false }))
  }

  return (
    <BaseModal className='outLesson-modal' width='60%' open={showHistoryModal} onCancel={onClose}>
      <div>
        <div className='flex flex-col justify-between items-center my-5'>
          <div className='text-[#E13B19] text-xl font-[700]'>{t('detail_class.detail_class_leave_class')}</div>
          <div className='text-lg my-7 text-[#606060]'>{t('detail_class.detail_class_leave_class_mesage')}</div>
          <div className='flex gap-7'>
            <SecondaryBtn
              onClick={onClose}
              className='w-24 !border-[#E13B19] !text-[#E13B19]'
              style={{ border: '1px solid #E13B19;' }}
            >
              {t('detail_class.detail_class_close')}
            </SecondaryBtn>
            <PrimaryBtn onClick={handleSubmit} className='w-24 !bg-[#E13B19] border-none'>
              {t('detail_class.detail_class_agree')}
            </PrimaryBtn>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default OutLessonsModal
