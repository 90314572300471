import { Spin } from 'antd'
import React from 'react'

const LoadingBase: React.FC = () => {
  return (
    <div className='globalLoadingContainer'>
      <Spin />
    </div>
  )
}

export default LoadingBase
