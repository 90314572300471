import { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { useRecoilState, useRecoilValue } from 'recoil'
import { concat, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

import imagesAssets from '@assets/imgs'
import svgAssets from '@assets/svg'
import Svg from '@components/Svg'
import ItemData from '@containers/Statistical/components/ItemData'
import { EmptyComponent } from '@components/Empty/EmptyBase'
import userInfoAtom from '@recoil/userInfoAtom'
import { showFullName } from '@utils/infoRender'
import Logger from '@managers/Logger'
import { translateHelper } from '@lang/langManager'

import classesAtom, { CLASSES_LIMIT_PAGE } from '../../recoil/classesAtom'
import { classesAPIs, IClassesPayload } from '../../api'
import ClassroomItem from './ClassroomItem/ClassroomItem'
import HistoryModal, { IVideoHistory } from './HistoryModal'
import './ClassroomList.scss'
import { ETabType } from '../../constants'

const tabs = () => [
  {
    key: ETabType.PUBLIC,
    label: translateHelper('overview_class.overview_community'),
  },
  {
    key: ETabType.JOINING,
    label: translateHelper('overview_class.overview_participating_class_tab'),
  },
  {
    key: ETabType.JOINED,
    label: translateHelper('overview_class.overview_joined'),
  },
  {
    key: ETabType.PENDING,
    label: translateHelper('overview_class.overview_pending'),
  },
  {
    key: ETabType.REJECTED,
    label: translateHelper('overview_class.overview_reject'),
  },
  {
    key: ETabType.DROPPED,
    label: translateHelper('overview_class.overview_have_left'),
  },
]

const mockDataHistory: IVideoHistory[] = [
  {
    name: 'Bài 10: VIDEO ĐÀO TẠO CỦA BIOSCOPE - LENTINEX (PART 10)',
    point: '2418.2',
    question: 'Hãy bấm vào nút "DEEPVIEW" ĐỂ NHẬN THƯỞNG mỗi khi nghe từ "TUYỆT VỜI" và từ "KHÔNG TUYỆT VỜI"',
    time: '11-12-2022 12:03:11',
    view: 5,
  },
]

const ClassroomList: React.FC = () => {
  const { t } = useTranslation()

  const userInfo = useRecoilValue(userInfoAtom)
  const [classList, setClassList] = useRecoilState(classesAtom)

  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const { class_statistics } = userInfo
  const { filter } = classList

  const tabActive = filter.status

  const handleOpenHistoryModal = () => {
    setShowHistoryModal(true)
  }

  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false)
  }

  const handleClickTab = (item: ETabType) => {
    setClassList((value) => ({
      ...value,
      filter: {
        ...value.filter,
        page: 1,
        page_limit: CLASSES_LIMIT_PAGE,
        status: item,
      },
    }))
  }

  const getClassesAPI = async (page: number) => {
    try {
      const paramSearch: IClassesPayload = {
        page_limit: classList?.filter?.page_limit,
        page,
      }
      setHasMore(false)
      if (classList?.filter?.status !== ETabType.PUBLIC) {
        paramSearch.status = classList?.filter?.status
      }

      const response = await classesAPIs.getClasses(paramSearch)

      setClassList((values) => ({
        ...values,
        total: response?.total_rows,
        classes: concat(values.classes, response.classes),
      }))

      if (response?.classes?.length < CLASSES_LIMIT_PAGE) {
        setHasMore(false)
      } else {
        setCurrentPage(page + 1)
        setHasMore(true)
      }
    } catch (error) {
      Logger.error('error getClassesAPI', error)
    }
  }

  const fetchMoreData = async () => {
    if (!hasMore || classList?.classes.length === 0) {
      return
    }

    await getClassesAPI(currentPage)
  }

  const renderHeader = () => {
    return (
      <div className='classroom-header px-7 py-10 relative pb-[6rem]'>
        <div className='flex flex-row items-center'>
          <div>
            <img className='w-[100px] h-[100px] object-contain' src={imagesAssets.avatar} alt='avatart' />
          </div>
          <div className='ml-7'>
            <div className='font-[500] text-[#1C62B9] text-3xl'>{showFullName(userInfo)}</div>
            <div className='flex  mt-4 text-base'>
              <div>{userInfo?.position ?? t('overview_class.overview_position')}</div>
              <div className='w-[2px] bg-[#CCCCCC] mx-4' />
              <div>{userInfo?.department ?? t('overview_class.overview_department')}</div>
            </div>
          </div>
        </div>
        <Row
          className='pt-5 w-full -mb-44'
          gutter={[
            { xs: 16, lg: 30, md: 16 },
            { xs: 16, lg: 0, md: 16 },
          ]}
        >
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              name={t('overview_class.overview_total_score_tab')}
              data={
                <div className='flex flex-row flex-wrap items-center'>
                  <span className='text-[#F9A621] mr-3 truncate'>{class_statistics?.total_points}</span>
                </div>
              }
              color='#F9A621'
              img={svgAssets.Score}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#E13B19]'
              name={t('overview_class.overview_average_tab')}
              data={`${class_statistics?.avg_concentration_level}%`}
              color='#E13B19'
              img={svgAssets.Video}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#34A853]'
              name={t('overview_class.overview_total_view_tab')}
              data={class_statistics?.total_views}
              color='#34A853'
              img={svgAssets.Timing}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#1C62B9]'
              name={t('overview_class.overview_participating_class')}
              data={class_statistics?.num_classes_enrolled}
              color='#1C62B9'
              img={svgAssets.Timing}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const renderTabType = () => {
    return (
      <div className='mt-32 tab-wrapper '>
        <ScrollMenu>
          {tabs().map((item) => (
            <div
              key={item.key}
              onClick={() => handleClickTab(item.key)}
              className={`type-item whitespace-nowrap uppercase ${tabActive === item.key ? 'type-active' : ''}`}
            >
              {item.label}
            </div>
          ))}
        </ScrollMenu>
      </div>
    )
  }

  const loader = (
    <div key='loader' className='loader'>
      Loading ...
    </div>
  )

  const renderVideos = () => {
    if (classList?.loading) {
      return (
        <div className='min-h-[600px]'>
          <div className='globalLoadingContainer'>
            <Spin />
          </div>
        </div>
      )
    }

    if (isEmpty(classList?.classes)) {
      return (
        <div>
          <EmptyComponent description={t('overview_class.overview_no_class_message') ?? ''} />
        </div>
      )
    }

    return (
      <div className='mt-7 mx-7'>
        <div className=''>
          <span className='text-[#1F91FA] font-[700]'>{classList?.total}</span>{' '}
          <span className='text-[#606060]'>{t('overview_class.class')}</span>
        </div>
        <div>
          <InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} loader={loader}>
            <Row className='py-4' gutter={[20, 20]}>
              {classList?.classes.length > 0 ? (
                classList?.classes.map((classItem) => (
                  <Col key={classItem._id} className='gutter-row' span={12} md={8} lg={6} xs={24}>
                    <ClassroomItem
                      type={tabActive}
                      isDisable={tabActive === ETabType.PENDING}
                      classDetail={classItem}
                    />
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </InfiniteScroll>
        </div>
      </div>
    )
  }

  const renderHistoryModal = () => {
    return (
      <HistoryModal
        dataSource={mockDataHistory}
        onClose={handleCloseHistoryModal}
        showHistoryModal={showHistoryModal}
      />
    )
  }

  useEffect(() => {
    const initApi = async () => {
      setClassList((values) => ({
        ...values,
        classes: [],
        loading: true,
      }))

      await getClassesAPI(1)

      setClassList((values) => ({
        ...values,
        loading: false,
      }))
    }
    initApi()
  }, [classList?.filter?.status])

  return (
    <Row className='bg-[#ffffff] classroom'>
      <Col span={24} className='min-h-screen'>
        {renderHeader()}
        {renderTabType()}
        {renderVideos()}
        {renderHistoryModal()}
      </Col>
    </Row>
  )
}

export default ClassroomList
