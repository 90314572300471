import { ConfigProvider, Empty, Table } from 'antd'
import React from 'react'
import imagesAssets from '../../assets/imgs'

import './EmptyBase.scss'

interface Props {
  description?: string
}

export const EmptyComponent: React.FC<Props> = ({ description }) => {
  return <Empty image={imagesAssets.empty} description={description} />
}

const EmptyBase: React.FC<Props> = ({ description, children }) => {
  return (
    <ConfigProvider renderEmpty={() => <Empty image={imagesAssets.empty} description={description} />}>
      {children}
    </ConfigProvider>
  )
}

export default EmptyBase
