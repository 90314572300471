/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { InputNumber } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { IBank, IGenerateOrder, apis } from '@apis'
import Logger from '@managers/Logger'
import appConfigAtom from '@recoil/appConfigAtom'
import userInfoAtom from '@recoil/userInfoAtom'
import pathUrl from '@routes/path'

import { hideModal, showModal } from '@managers/GlobalModal'
import LoadingBase from '@components/Loading/LoadingBase'
import SelectBase from '@components/Select/SelectBase'
import Svg from '@components/Svg'
import PrimaryBtn from '@components/Button/PrimaryBtn'
import DangerButton from '@components/Button/DangerButton'

import { formatNumber } from '@utils/format'
import { IPlan } from 'types'

import './SubscriptionsPlans.scss'
import { AccountItemInfo, PaidPlanModal } from '../components'

enum VALUE_KEY {
  beneficiary_name = 'beneficiary_name',
  beneficiary_account = 'beneficiary_account',
  last_price = 'last_price',
  transfer_description = 'transfer_description',
}

const SubscriptionsPlans = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { planId } = useParams<{ planId: string }>()

  const [userInfo] = useRecoilState(userInfoAtom)
  const setAppConfig = useSetRecoilState(appConfigAtom)

  const [plan, setPlans] = useState<IPlan>()
  const [generateOrder, setGenerateOrder] = useState<IGenerateOrder>()
  const [bankList, setBankList] = useState<IBank[]>()
  const [bankSelected, setBankSelected] = useState<IBank>()

  const [quantity, setQuantity] = useState(1)
  const [error, setError] = useState<string>()

  const handleChangeQuantity = (value: number | null) => {
    if (value) {
      setQuantity(value)
    }
  }

  const updateGenerateOrder = async () => {
    setError(undefined)
    setAppConfig((value) => ({ ...value, loading: true }))
    try {
      const response = await apis.generateOrder({
        plan_id: planId,
        quantity,
      })

      if (response?.message.toLowerCase() === 'ok') {
        setGenerateOrder(response.data)
      } else {
        setError(response?.message)
      }
    } catch (error: any) {
      setError(error?.message ?? 'Error')
    } finally {
      setAppConfig((value) => ({ ...value, loading: false }))
    }
  }

  const handleSelectBank = (item: IBank) => {
    setBankSelected(item)
  }

  const handleGetBankList = async () => {
    try {
      const response = await apis.banksList()

      if (response.banks[0]) {
        setBankList(response.banks)
        setBankSelected(response.banks[0])
      }
    } catch (error: any) {
      //
    }
  }

  const showValue = (key: VALUE_KEY) => {
    let value: any = ''

    switch (key) {
      case VALUE_KEY.beneficiary_name:
        value = bankSelected?.beneficiary_name
        break

      case VALUE_KEY.beneficiary_account:
        value = bankSelected?.bank_account
        break

      case VALUE_KEY.last_price:
        value = generateOrder?.last_price
        break

      case VALUE_KEY.transfer_description:
        value = generateOrder?.transfer_description
        break

      default:
        break
    }

    return value
  }

  const goBack = () => {
    history.push(pathUrl.upgradeAccount())
  }

  const handleCloseModal = () => {
    hideModal()
    history.push(pathUrl.home())
  }

  const handleCreateOrder = async () => {
    if (!bankSelected || !generateOrder || !plan) {
      return
    }

    try {
      const response = await apis.createPlanOrder({
        amount: generateOrder?.last_price,
        beneficiary_account: bankSelected?.bank_account ?? '',
        beneficiary_bank: bankSelected?.bank_name,
        beneficiary_name: bankSelected?.beneficiary_name,
        discount_amount: generateOrder?.discount_amount,
        payment_method: 'bank',
        plan_id: plan?._id,
        quantity,
        transfer_description: generateOrder?.transfer_description,
      })

      if (response.message.toLowerCase() === 'ok') {
        showModal({
          content: <PaidPlanModal onCloseModal={handleCloseModal} />,
        })
      }
    } catch (error) {
      //
    }
  }

  const getUserId = () => {
    return userInfo.email?.split('@')[0]
  }

  useEffect(() => {
    const getPlanInfo = async () => {
      try {
        setAppConfig((value) => ({ ...value, loading: true }))

        const [planResponse, generateOrderResponse] = await Promise.all([
          apis.getPlans(),
          apis.generateOrder({
            plan_id: planId,
            quantity,
          }),
        ])

        if (planResponse?.plans && generateOrderResponse?.message?.toLowerCase() === 'ok') {
          const currentPlan = planResponse.plans.find((item) => item._id === planId)

          setPlans(currentPlan)
          setGenerateOrder(generateOrderResponse.data)
        }
      } catch (error: any) {
        setError(error?.message ?? 'Error')
        Logger.error('getPlanInfo', error)
      } finally {
        setAppConfig((value) => ({ ...value, loading: false }))
        handleGetBankList()
      }
    }

    getPlanInfo()
  }, [])

  if (!plan) {
    return <LoadingBase />
  }

  return (
    <div className='w-full min-h-screen bg-[#285FE8] text-[#000000] flex justify-center sm:py-5 sm:pt-20 sm:px-3 items-center subscriptionsPlans-wrapper'>
      <div className='rounded-[32px] bg-[#FFFFFF] p-8 max-w-[900px] w-full  '>
        <div className='text-[#2196F3] font-bold text-3xl text-center sm:text-2xl'>
          {t('plan.transactionInformation')}
        </div>
        <div className='mt-3 line-clamp-2 leading-7'>
          <span>{t('plan.upgradeTo')} </span>{' '}
          <span className='bg-[#D9ECE0] text-[#4DA96F] px-3 py-2 rounded-lg mx-1 uppercase'>{plan?.suitable_for}</span>
          <span>
            {t('plan.planFor')} {`${userInfo.firstName} ${userInfo.lastName}`} (ID: {getUserId()})
          </span>
        </div>
        <div className='flex items-center text-[#1E88E5] text-sm gap-2 my-5'>
          <Svg.Crown width={18} height={18} /> <span className='font-bold'>{t('plan.discount')}:</span>
          <span>{t('plan.voucher')}</span>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex justify-between items-center'>
            <div className='labelItem'>{t('plan.person')}</div>
            <div>
              {formatNumber(plan.price)}đ / {t('plan.labelPackage')}
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='labelItem'>{t('plan.quantity')}</div>
            <div className='flex items-center gap-1'>
              <InputNumber
                min={1}
                defaultValue={quantity}
                onChange={handleChangeQuantity}
                onBlur={updateGenerateOrder}
                style={{ width: 75 }}
                className='roundedPlans mr-1'
              />
              {t('plan.labelPackage')}
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='labelItem'>
              {t('plan.discount')}
              <span className='border border-[#1F91FA] rounded px-1 py-[2px] ml-1'>
                {generateOrder?.discount_percent ?? 0}%
              </span>
            </div>
            <div>- {formatNumber(generateOrder?.discount_amount)}đ</div>
          </div>

          <div className='border-t border-[#00000066] my-3' />

          <div className='flex justify-between items-center'>
            <div className='labelItem'>{t('plan.total')}</div>

            <div>{formatNumber(generateOrder?.last_price)}đ</div>
          </div>

          <div className='flex justify-between items-center my-2'>
            <div className='labelItem'>{t('plan.method')}</div>

            <div>
              <SelectBase
                defaultValue='bank'
                className='ml-3'
                options={[{ label: t('plan.transactionBank'), value: 'bank' }]}
              />
            </div>
          </div>

          <div>
            <div className='labelItem'>{t('plan.bank')}</div>

            <div className='flex items-center gap-5 overflow-auto'>
              {bankList &&
                bankList?.map((item) => (
                  <div
                    key={item.bank_name}
                    className={`flex flex-col justify-between items-center cursor-pointer w-[100px] h-fit ${
                      item === bankSelected ? 'item-selected' : ''
                    }`}
                    onClick={() => handleSelectBank(item)}
                  >
                    <div className='w-14 h-14'>
                      <img src={item.bank_logo} alt={item.bank_name} />
                    </div>
                    <div>{item.bank_name}</div>
                  </div>
                ))}
            </div>
          </div>

          <div className='flex flex-col gap-3'>
            <AccountItemInfo label={t('plan.beneficiary')} value={showValue(VALUE_KEY.beneficiary_name)} />
            <AccountItemInfo label={t('plan.beneficiaryAccount')} value={showValue(VALUE_KEY.beneficiary_account)} />
            <AccountItemInfo label={t('plan.money')} value={`${formatNumber(showValue(VALUE_KEY.last_price))}đ`} />
            <AccountItemInfo
              className='flex-wrap sm:flex-col sm:flex-initial'
              label={t('plan.transferContents')}
              value={showValue(VALUE_KEY.transfer_description)}
            >
              <div className='w-full text-[#F60909] text-xs'>* {t('plan.content')}</div>
            </AccountItemInfo>
          </div>

          <div className=' mt-3'>
            {error && <div>{error}</div>}
            <div className='flex gap-4 justify-center'>
              <DangerButton onClick={goBack} className='uppercase w-[181px] sm:w-auto'>
                {t('plan.back')}
              </DangerButton>
              <PrimaryBtn onClick={handleCreateOrder} className='uppercase'>
                {t('plan.ok')}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionsPlans
