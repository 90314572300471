import imagesAssets from '../../assets/imgs'
import { CategoryType } from '../../recoil/categoriesAtom'
import { CustomerBranch } from '../../types'

export const categoryTotal: CategoryType = {
  _id: '-1',
  created_at: new Date().toISOString(),
  name: 'home.all',
  slug: 'total',
  updated_at: new Date().toISOString(),
}

export const brandTotal: CustomerBranch = {
  _id: '-1',
  name: 'home.home',
  img: imagesAssets.home,
  imgActive: imagesAssets.homeIn,
}

export const brandMyVideo: CustomerBranch = {
  _id: '-2',
  name: 'home.yourVideos',
  img: imagesAssets.video,
  imgActive: imagesAssets.videoIn,
}

export const classroomItem: CustomerBranch = {
  _id: '-3',
  name: 'home.classroom',
  img: imagesAssets.classroom,
  imgActive: imagesAssets.classroomIn,
  isBeta: true,
}

export const dashboardItem: CustomerBranch = {
  _id: '-4',
  name: 'home.dashboard',
  img: imagesAssets.dashboard,
  imgActive: imagesAssets.dashboardIn,
}

export const generateRandom = () => {
  return [...Array(100).keys()].map((item, index) => {
    return {
      _id: index,
      created_at: new Date().toISOString(),
      name: index,
      slug: index,
      updated_at: new Date().toISOString(),
    }
  })
}
