import * as React from 'react'
import { ColumnType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'

import { ResultItemType } from '../../../types'
import correctIcon from '../../../assets/imgs/correctIcon.png'
import failIcon from '../../../assets/imgs/failIcon.png'
import { formatTime } from '../../../utils'

function useReviewPointColumn(): ColumnType<ResultItemType>[] {
  const { t } = useTranslation()

  return React.useMemo(
    (): ColumnType<ResultItemType>[] => [
      {
        title: t('video.stt'),
        key: 'stt',
        render: (value, row, index: number) => index + 1,
      },
      {
        title: t('video.time'),
        dataIndex: 'time',
        render: (value) => formatTime(value),
      },
      {
        title: t('video.resultReport'),
        dataIndex: 'result',
        render: (value) =>
          value ? <img src={correctIcon} height='20' alt='' /> : <img src={failIcon} height='20' alt='' />,
      },
      {
        title: t('video.Score'),
        dataIndex: 'point',
      },
    ],
    [],
  )
}

export default useReviewPointColumn
