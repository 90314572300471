/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const BxBulb: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#939393' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5 20H15.5V22H9.5V20ZM17.406 13.712C18.436 12.506 19.5 11.259 19.5 9C19.5 5.141 16.359 2 12.5 2C8.641 2 5.5 5.141 5.5 9C5.5 11.285 6.567 12.528 7.601 13.73C7.959 14.148 8.33 14.581 8.685 15.079C8.829 15.285 9.065 16.075 9.276 17H8.5V19H16.5V17H15.726C15.939 16.073 16.176 15.281 16.319 15.075C16.671 14.572 17.045 14.135 17.406 13.712ZM14.682 13.925C14.248 14.542 13.886 16 13.676 17H11.325C11.116 15.998 10.753 14.537 10.314 13.92C9.93876 13.4043 9.53966 12.9065 9.118 12.428C8.144 11.294 7.5 10.544 7.5 9C7.5 6.243 9.743 4 12.5 4C15.257 4 17.5 6.243 17.5 9C17.5 10.521 16.857 11.274 15.885 12.413C15.512 12.851 15.089 13.346 14.682 13.925Z'
        fill={color}
      />
    </svg>
  )
}

export default BxBulb
