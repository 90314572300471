import * as React from 'react'
import { Modal, ModalProps, Space } from 'antd'
import './styles.scss'
import { mobileAndTabletCheck } from '../../utils'

export interface BaseModalProps extends ModalProps {
  children?: React.ReactNode
}

function BaseModal({ className, ...otherProps }: BaseModalProps) {
  const footer = () => {
    if (!otherProps.footer) return false
    return (
      <div className='baseModalFooter'>
        <Space>{otherProps.footer}</Space>
      </div>
    )
  }
  return (
    <Modal
      className={`baseModal ${className ?? ''}`}
      closable={false}
      footer={footer()}
      style={mobileAndTabletCheck ? { top: 0 } : undefined}
      title={otherProps.title ? <div className='baseModalTitle'>{otherProps.title}</div> : undefined}
      {...otherProps}
    >
      {otherProps.children}
    </Modal>
  )
}

export default BaseModal
