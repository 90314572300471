import * as React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { ButtonProps } from 'antd/lib/button'

const DangerButton: React.FC<ButtonProps> = ({ className, ...other }) => {
  return (
    <Button className={`dangerBtn ${className ?? ''}`} {...other}>
      {other.children || 'Cancel'}
    </Button>
  )
}

export default DangerButton
