/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const Feature: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = '#939393' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.5 6H14.5V8H10.5V6ZM10 4C9.17188 4 8.5 4.67188 8.5 5.5V8.5C8.5 9.32812 9.17188 10 10 10H11.5V11H3.5C2.94687 11 2.5 11.4469 2.5 12C2.5 12.5531 2.94687 13 3.5 13H6.5V14H5C4.17188 14 3.5 14.6719 3.5 15.5V18.5C3.5 19.3281 4.17188 20 5 20H10C10.8281 20 11.5 19.3281 11.5 18.5V15.5C11.5 14.6719 10.8281 14 10 14H8.5V13H16.5V14H15C14.1719 14 13.5 14.6719 13.5 15.5V18.5C13.5 19.3281 14.1719 20 15 20H20C20.8281 20 21.5 19.3281 21.5 18.5V15.5C21.5 14.6719 20.8281 14 20 14H18.5V13H21.5C22.0531 13 22.5 12.5531 22.5 12C22.5 11.4469 22.0531 11 21.5 11H13.5V10H15C15.8281 10 16.5 9.32812 16.5 8.5V5.5C16.5 4.67188 15.8281 4 15 4H10ZM5.5 18V16H9.5V18H5.5ZM15.5 16H19.5V18H15.5V16Z'
        fill={color}
      />
    </svg>
  )
}

export default Feature
