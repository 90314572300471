/* eslint-disable max-len */
import React from 'react'
import { useRecoilState } from 'recoil'
import notiConfigAtom from './notiConfigAtom'

const NotificationBar = () => {
  const [notiConfig, setNotiConfig] = useRecoilState(notiConfigAtom)

  const handleClose = () => {
    setNotiConfig({ isShow: false })
  }

  if (!notiConfig.isShow) {
    return <></>
  }

  return (
    <div className='relative w-full bg-[#4f4fee] text-[#ffffff] p-3'>
      <div className='text-center'>
        Từ ngày 07/10, Deepview sẽ triển khai tính năng quản lý mật khẩu mới bằng email và password trên deepview.social. <a target="_blank" rel="noreferrer" href="https://blog.deepview.win/thong-bao-cap-nhat-tinh-nang-quan-ly-mat-khau-tren-deepview/"><strong>Xem chi tiết!</strong></a>
      </div>
      <div
        onClick={handleClose}
        className='cursor-pointer bg-[#ffffff] text-[#000000] flex justify-center items-center rounded-full w-5 h-5 absolute top-2 right-3'
      >
        x
      </div>
    </div>
  )
}

export default NotificationBar
