import React from 'react'
import { SvgIconProps } from './type'

const CheckCircleTick: React.FC<SvgIconProps> = ({ height = 20, width = 20, color = '#16A34A' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99844 19.6C15.3004 19.6 19.5984 15.302 19.5984 10C19.5984 4.69809 15.3004 0.400024 9.99844 0.400024C4.6965 0.400024 0.398438 4.69809 0.398438 10C0.398438 15.302 4.6965 19.6 9.99844 19.6ZM14.6263 7.82938C14.9187 7.42739 14.8298 6.86452 14.4278 6.57216C14.0258 6.27981 13.4629 6.36868 13.1706 6.77067L8.99007 12.5189L6.73483 10.2636C6.38336 9.91216 5.81351 9.91216 5.46204 10.2636C5.11057 10.6151 5.11057 11.1849 5.46204 11.5364L8.46204 14.5364C8.64816 14.7225 8.90643 14.8179 9.16884 14.7973C9.43124 14.7767 9.67149 14.6423 9.8263 14.4294L14.6263 7.82938Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckCircleTick
