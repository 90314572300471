import React from 'react'
import { SvgIconProps } from './type'

const NextDiv: React.FC<SvgIconProps> = ({ height = 24, width = 24, color = '#ffffff' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.6834 12L11.3453 6.76261C10.9424 6.35973 10.9424 5.75539 11.3453 5.35251C11.7482 4.94963 12.3525 4.94963 12.7554 5.35251L18.6978 11.295C18.8993 11.4964 19 11.6978 19 12C19 12.3021 18.8993 12.5036 18.6978 12.705L12.7554 18.6475C12.3525 19.0504 11.7482 19.0504 11.3453 18.6475C10.9424 18.2446 10.9424 17.6403 11.3453 17.2374L16.6834 12ZM5.30216 17.2374C4.89928 17.6403 4.89928 18.2446 5.30216 18.6475C5.70504 19.0504 6.30935 19.0504 6.71222 18.6475L12.6547 12.705C12.8561 12.5036 12.9568 12.3021 12.9568 12C12.9568 11.6978 12.8561 11.4964 12.6547 11.295L6.71222 5.35251C6.30935 4.94963 5.70504 4.94963 5.30216 5.35251C4.89928 5.75539 4.89928 6.35973 5.30216 6.76261L10.6403 12L5.30216 17.2374Z'
        fill={color}
      />
    </svg>
  )
}

export default NextDiv
