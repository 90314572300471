/* eslint-disable max-len */
import React from 'react'
import { SvgIconProps } from './type'

const CopyInformation: React.FC<SvgIconProps> = ({ height = 28, width = 28, color = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.125 7.4375H4.375C4.25897 7.4375 4.14769 7.48359 4.06564 7.56564C3.98359 7.64769 3.9375 7.75897 3.9375 7.875V23.625C3.9375 23.741 3.98359 23.8523 4.06564 23.9344C4.14769 24.0164 4.25897 24.0625 4.375 24.0625H20.125C20.241 24.0625 20.3523 24.0164 20.4344 23.9344C20.5164 23.8523 20.5625 23.741 20.5625 23.625V7.875C20.5625 7.75897 20.5164 7.64769 20.4344 7.56564C20.3523 7.48359 20.241 7.4375 20.125 7.4375ZM19.6875 23.1875H4.8125V8.3125H19.6875V23.1875ZM24.0625 4.375V20.125C24.0625 20.241 24.0164 20.3523 23.9344 20.4344C23.8523 20.5164 23.741 20.5625 23.625 20.5625C23.509 20.5625 23.3977 20.5164 23.3156 20.4344C23.2336 20.3523 23.1875 20.241 23.1875 20.125V4.8125H7.875C7.75897 4.8125 7.64769 4.76641 7.56564 4.68436C7.48359 4.60231 7.4375 4.49103 7.4375 4.375C7.4375 4.25897 7.48359 4.14769 7.56564 4.06564C7.64769 3.98359 7.75897 3.9375 7.875 3.9375H23.625C23.741 3.9375 23.8523 3.98359 23.9344 4.06564C24.0164 4.14769 24.0625 4.25897 24.0625 4.375Z'
        fill={color}
      />
    </svg>
  )
}

export default CopyInformation
