import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import _ReactPlayer, { ReactPlayerProps } from 'react-player'
import Truncate from 'react-truncate'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ICustomer, VideoType } from '../../../../types'
import './styles.scss'
import imagesAssets from '../../../../assets/imgs'
import { convertToHHMMSS, formatShowCurrency } from '../../../../utils/format'
import appModalAtom from '../../../../recoil/appModalAtom'
import { ModalType } from '../../../../types/enum'
import userInfoAtom from '../../../../recoil/userInfoAtom'
import { apis } from '../../../../apis'
import appConfigAtom from '../../../../recoil/appConfigAtom'
import Svg from '../../../../components/Svg'
import pathUrl from '../../../../routes/path'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

interface Props {
  videoDetail: VideoType
}

function VideoCollectionItem(props: Props) {
  const history = useHistory()
  const { t } = useTranslation()

  const [appModal, setAppModal] = useRecoilState(appModalAtom)
  const setAppConfig = useSetRecoilState(appConfigAtom)
  const [userInfo] = useRecoilState(userInfoAtom)

  const { category } = useParams<any>()
  const { videoDetail } = props
  const { video, name, id, is_sponsor, customer = {} as ICustomer, price } = videoDetail

  const isFree = !price || price === 0

  const showNameBrand = () => {
    try {
      return <Truncate lines={1}>{customer?.display_name ?? 'No Name'}</Truncate>
    } catch (e) {
      return 'No Name'
    }
  }

  const renderSponsor = () => {
    return (
      <div className='bg-[#109D59] text-[13px] h-fit text-[#FFFFFF] rounded-[10px] px-2 py-[1px]  text-center'>
        {t('home.sponsor')}
      </div>
    )
  }

  const renderFreeBadge = () => {
    const style = isFree ? 'bg-[#EF9E00] text-[#FFFFFF]' : 'bg-[#D32F2F] text-[#FFFFFF]'

    return (
      <span className={`h-fit flex text-[13px] rounded-[10px] px-2 py-[1px] w-fit text-center ${style}`}>
        {isFree ? t('home.free') : formatShowCurrency(price, true)}
      </span>
    )
  }

  const renderBadge = () => {
    return is_sponsor ? renderSponsor() : renderFreeBadge()
  }

  const handleClick = async () => {
    if (!is_sponsor && !isFree) {
      if (userInfo?.email) {
        try {
          setAppConfig((value) => ({ ...value, loading: true }))
          const response = await apis.purchaseStatus(videoDetail?.id)
          if (response?.message !== 'PURCHASED') {
            setAppModal({ mode: ModalType.purchaseVideo, data: videoDetail })
            setAppConfig((value) => ({ ...value, loading: false }))
            return
          }
        } catch (error) {
          setAppConfig((value) => ({ ...value, loading: false }))
          return
        }
      } else {
        setAppModal({ mode: ModalType.purchaseVideo, data: videoDetail })
        return
      }
    }

    setAppConfig((value) => ({ ...value, loading: false }))
    history.push(!category ? pathUrl.videoDetail(id) : pathUrl.videoByCategory(category, id))
  }

  const renderRedeemPoint = () => {
    if (!videoDetail?.is_sponsor && videoDetail?.price > 0) {
      return (
        <div className='blurPlayer noteVideo'>
          <div className='flex h-fit'>
            <div>
              <Svg.Play />
            </div>
            <div className='flex items-center ml-2'>Đổi điểm</div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return (
    <div className='videoCollectionItem' onClick={handleClick}>
      <div className='videoPlayerWrapper'>
        <ReactPlayer
          url={video}
          width='100%'
          height='100%'
          className='reactPlayer'
          playIcon={<></>}
          controls={false}
          config={{
            youtube: {
              playerVars: { showinfo: 0, controls: 0 },
            },
          }}
          light // preview only, not load the full player
        />
        <div className='video-length'>{convertToHHMMSS(videoDetail.length_in_seconds)}</div>
        <div className='blurPlayer' />
        {renderRedeemPoint()}
      </div>
      <div className='videoNameWrapper'>
        <div className='channelAvatar'>
          <img className='avatar circle' src={customer?.avatar ?? imagesAssets.avatar} alt='avatar' />
        </div>
        <div className='infoWrapper overflow-hidden'>
          <div className='videoName mb-2'>
            <Truncate lines={2}>{name}</Truncate>
          </div>
          <div className='flex'>
            <div className='flex-none pr-3'>{renderBadge()}</div>
            <div className='flex-auto'>{showNameBrand()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCollectionItem
