/* eslint-disable max-len */

export const messageJoin: Record<string, string> = {
  SUCCESS: 'Yêu cầu tham gia lớp học của bạn đã được gửi. Bạn có thể vào học sau khi được duyệt yêu cầu',
  ERROR_PENDING_REQUEST:
    'Yêu cầu tham gia lớp học của bạn thành công và đang trong quá trình xử lý. Chúng tôi sẽ gửi thông báo qua email sau khi yêu cầu của bạn được duyệt hoặc bị từ chối',
  ERROR_ALREADY_ENROLLED:
    'Xin lỗi, bạn đã đăng ký tham gia lớp học này trước đó nên không thể đăng ký lại. Vui lòng chọn một lớp học khác hoặc liên hệ với quản trị viên để được hỗ trợ.',
  ERROR_CLASS_NOT_FOUND: 'Xin lỗi, không tìm thấy lớp học',
  ERROR_DROPPED_REQUEST:
    'Xin lỗi, yêu cầu của bạn đã bị từ chối vì bạn đã rời lớp học. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với quản trị viên để được hỗ trợ',
}

export const messageLeave: Record<string, string> = {
  SUCCESS: 'Bạn đã rời lớp học thành công',
  ERROR_NOT_ENROLLED:
    'Xin lỗi, bạn không được phép thực hiện hành động này vì bạn chưa tham gia lớp học. Vui lòng liên hệ với giáo viên hoặc quản trị viên để được hỗ trợ',
  ERROR_CLASS_NOT_FOUND: 'Xin lỗi, không tìm thấy lớp học vì bạn đã rời lớp học',
}
