import facebookShare from './facebook-share.png'
import avatar from './avatar.png'
import promotion from './promotion.png'
import nextIcon from './nextIcon.png'
import previousIcon from './previousIcon.png'
import product from './product.png'
import thanks from './thanks.png'
import empty from './empty.png'
import item from './item.png'
import home from './home.png'
import homeIn from './homeIn.png'
import purchase from './purchase.png'
import video from './video.png'
import emptyVideo from './emptyvideo.png'
import feedback from './feedback.png'
import help from './help.png'
import videoIn from './videoIn.png'
import overview from './overview.png'
import overviewIn from './overviewIn.png'
import eng from './eng.png'
import vi from './vi.png'
import solidNext from './solidNext.png'
import tick from './tick.png'
import deepviewButton from './deepviewButton.png'
import classroom from './classroom.png'
import classroomIn from './classroomIn.png'
import bannerEN from './banner_en.png'
import bannerVI from './banner_vi.png'
import bgLimitNumber from './bg_limit_number.png'
import timeOut from './timeOut.png'
import humaaans3characters from './humaaans3characters.png'
import dashboard from './dashboard.png'
import dashboardIn from './dashboardIn.png'
import info from './info.png'
import deepViewLogoImg from './deepViewLogo.png'

export const imagesAssets = {
  deepViewLogoImg,
  dashboard,
  dashboardIn,
  bannerEN,
  bannerVI,
  humaaans3characters,
  classroom,
  classroomIn,
  avatar,
  facebookShare,
  promotion,
  nextIcon,
  previousIcon,
  product,
  thanks,
  empty,
  item,
  home,
  homeIn,
  purchase,
  video,
  emptyVideo,
  feedback,
  help,
  videoIn,
  overview,
  overviewIn,
  vi,
  eng,
  solidNext,
  tick,
  deepviewButton,
  bgLimitNumber,
  timeOut,
  info,
}

export default imagesAssets
