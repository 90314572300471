import React from 'react'
import { SvgIconProps } from './type'

const Menu: React.FC<SvgIconProps> = ({ height = 18, width = 18 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='2' width='18' height='2' rx='1' fill='#606060' />
      <rect y='8' width='18' height='2' rx='1' fill='#606060' />
      <rect y='14' width='18' height='2' rx='1' fill='#606060' />
    </svg>
  )
}

export default Menu
