import React from 'react'

type Props = {
  image: string
  imageActive: string
  name: string
  isActive?: boolean
  onClick?: () => void
  sizeImage?: number
  isBeta?: boolean
}

const CategoryItem: React.FC<Props> = ({
  image,
  imageActive,
  name,
  onClick,
  isActive = false,
  sizeImage = 20,
  isBeta = false,
}) => {
  return (
    <div
      className={`pl-5 py-2 flex items-center cursor-pointer lg:justify-center ${isActive ? 'bg-[#E5E5E5]' : ''}`}
      onClick={onClick}
    >
      <span className='w-8'>
        <img src={isActive ? imageActive : image} width={sizeImage} height={sizeImage} alt='item' />
      </span>
      <div className='flex flex-row items-center justify-between flex-1'>
        <span className='ml-3 flex-1 pr-2 text-[#030303] font-normal text-lg truncate'>{name}</span>
        {isBeta && <div className='bg-[#F9A621] text-[#ffffff] uppercase font-bold px-2 rounded-md mr-3'>BETA</div>}
      </div>
    </div>
  )
}

export default CategoryItem
