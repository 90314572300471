/* eslint-disable max-len */
import VI from './vi'

const videoEN: typeof VI = {
  'video.related_video': 'Related videos',
  'video.only_score_message': 'You get <highlight>{{scoreCorrect}}</highlight> points/for each correct click',
  'video.only_score_message_sponsor': 'You get <highlight>{{scoreCorrect}}</highlight> VND/for each correct click',
  'video.score_message_sponsor':
    'You get <highlight>{{scoreCorrect}}</highlight> VND/for each correct click and <highlight>{{scoreIncorrect}}</highlight> VND for each answer correct quiz',
  'video.score_message':
    'You get <highlight>{{scoreCorrect}}</highlight> points/for each correct click and <highlight>{{scoreIncorrect}}</highlight> points for each answer correct quiz',
  'video.minus_score': 'Wrong! You have just deducted {{score}}',
  'video.play_video': 'Click to watch the video to get the reward',
  'video.bonus_score': 'So great! You just got {{score}}',
  'video.quiz_label': 'Question',
  'video.quiz_submit': 'Answer',
  'video.continue': 'Continue',
  'video.product': 'You have {{currency}} VND (Use it now))',
  'video.other_video': 'More videos',
  'video.change_question': 'Change Questions',
  'video.previous_results': '(Previous result: Concentration level {{concentrationLevel}}%, {{point}} DV)',
  'video.watched_message': 'You have finished viewing the content',
  'video.total_score': 'The total score is:',
  'video.score': 'score',
  'video.Score': 'Score',
  'video.vnd': 'đ',
  'video.more_detail': 'See details',
  'video.hidden_detail': 'Hide details',
  'video.result': 'Result: {{score1}} points + {{score2}} points (Answer the Quiz question)',
  'video.warning_message': 'You can review or report scores.',
  'video.watch_again': 'Watch the video again',
  'video.re_watch': 'Rewatch',
  'video.skip': 'Skip',
  'video.register': 'Register',
  'video.watch_other_video': 'watch other video',
  'video.login': 'Login',
  'video.suggestLogin': 'To write point, please please register account or ',
  'video.point_sended': 'Your score has been recorded',
  'video.error_demo_account': 'Please login with another demo account',
  'video.stt': 'Number',
  'video.time': 'Time',
  'video.warning_score': 'This question has already been scored. If you Agree the new points will be overwritten!',
  'video.cancel': 'Cancel',
  'video.agree': 'Agree',
  'video.completed_message': 'We will send a notification of reward points and redemption value to your email',
  'video.close': 'Close',
  'video.watch_more': 'Watch more videos',
  'video.concentrationWarning':
    "This video requires {{concentrationLevel}}% concentration, if it's lower than this, you need to watch the video again",
  'video.scored': 'This question has been scored. You can',
  'video.resulted': 'The results have been calculated',
  'video.previousResult': '(Previous result: Concentration level {{concentrationLevel}}%, {{score}} DV)',
  'video.correct': 'Correct',
  'video.incorrect': 'Incorrect.',
  'video.reportScore': 'Report score',
  'video.resultReport': 'Result',
  'video.quiz': 'Answer the Quiz question',
  'video.youReceive': 'You receive',
  'video.concentration_level': 'Concentration level',
  'video.showRequire': 'Your concentration level is less than required ({{concentrationLevel}}%)',
  'video.canReview': 'you can review it to improve your concentration',
  'video.continueVideoQuestion': 'Do you want to continue watching the video?',
  'video.continueVideoNote':
    'The total score that you have achieved previously will still be recorded if you continue watching.',
  'video.okContinue': 'Continue watching',
  'video.cancelContinue': 'Watch from Beginning',
}

export default videoEN
