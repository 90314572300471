import React, { useState } from 'react'
import Truncate from 'react-truncate'
import './styles.css'
import { useTranslation } from 'react-i18next'

interface Props {
  text?: string
  lines?: number
}

const TruncatedText: React.FC<Props> = ({ text = '', lines = 0 }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Truncate lines={!expanded && lines} ellipsis={<span>...</span>}>
        <div className='fade'>{text}</div>
      </Truncate>
      <div className='text-[#00CFD5]'>
        {!expanded && (
          <button className='underline' onClick={handleExpandClick} type='button'>
            {t('detail_class.detail_class_view_detail')}
          </button>
        )}
        {expanded && (
          <button className='underline' onClick={handleExpandClick} type='button'>
            {t('detail_class.detail_class_hide_detail')}
          </button>
        )}
      </div>
    </div>
  )
}

export default TruncatedText
