import { recoilPersist } from 'recoil-persist'
import { atom } from 'recoil'

const { persistAtom } = recoilPersist()

export interface RemainingMinutesAtom {
  log?: {
    email: string
    date: string | Date
    isShowNoted: boolean
  }
}

const remainingMinutesAtom = atom<RemainingMinutesAtom>({
  key: 'remainingMinutesAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export default remainingMinutesAtom
