import React from 'react'

import Svg from '@components/Svg'
import { copyHelper } from '@components/useHook/useCopyToClipboard'

type Props = {
  label: string
  value?: string | number
  className?: string
}

const AccountItemInfo: React.FC<Props> = ({ label, value, className = '', children }) => {
  const handleCopy = () => {
    copyHelper(value)
  }

  return (
    <div className={`accountItemWrapper flex justify-between ${className}`}>
      <div className='labelItem'>{label}</div>
      <div className='valueItem' onClick={handleCopy}>
        <span>{value}</span> <Svg.CopyInformation />
      </div>
      {children}
    </div>
  )
}

export default AccountItemInfo
