import VI from './vi'

const AuthEN: typeof VI = {
  'auth.checkResetPassword': 'Please check your email to recover your password!',
  'auth.invalid_token': 'Token is invalid',
  'auth.tokenExpired': 'Token Expired',
  'auth.passwordNotStrongEnough': 'Password not strong enough',
  'auth.emailNotVerified': 'Please go to your email to activate your account.',
  'signUp.userExists': 'Account has been used.',
  'auth.mathPassword': 'Password confirmation not match',
  'auth.updatePassword': 'Update password',
  'auth.updateSuccessPassword': 'Update password successfully',
}

export default AuthEN
