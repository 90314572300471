import _ from 'lodash'
import { getRecoil } from 'recoil-nexus'
import request from '../utils/request'
import { ApiResultRecordRequestType, CustomerBranch, IPlan, UserType, VideoDetailType, VideoType } from '../types'
import { CategoryType } from '../recoil/categoriesAtom'
import userInfoAtom from '../recoil/userInfoAtom'
import { loginCallbackResponse } from './interceptors/loginInterceptor'
import { authErrorCodeMessage } from './error/handleShowErrorLogin'

export const postSignUp = async (data: {
  firstName: string
  lastName: string
  email: string
  phone: string
  userType: string
  company?: string
  companyAddress?: string
  refCode?: string
  password?: string
  password_confirmation?: string
}) =>
  request.post('/users/sign_up', data, false, {
    handleError: authErrorCodeMessage,
  })

export const getVideoList = async ({ category }: { category?: string }) =>
  request.get(category ? `/videos/${category}` : '/videos')

export const postResultRecord = (data: ApiResultRecordRequestType) => request.postWithAuth('/record', data)

interface ICategory {
  customer_id?: string
}

export const getCategories = (search?: ICategory): Promise<{ categories: CategoryType[] }> => {
  const searchParams = _.omitBy(search, _.isNil)
  return request.get('/categories?', searchParams)
}

export interface IVideoParams {
  page?: number
  page_limit?: number
  customer_id?: string
  purchased?: boolean
  category?: string
  q?: string
}

export interface IVideoResponse {
  videos: VideoType[]
  page: number
  limit: number
}

export enum IMessageVideo {
  ENOUGH_BALANCE = 'ENOUGH_BALANCE',
  ERROR_NOT_ENOUGH_BALANCE = 'ERROR_NOT_ENOUGH_BALANCE',
}

export interface IVideoDetailResponse {
  video: VideoType
  message?: IMessageVideo
}

export interface IProductPayload {
  product_id: string
  amount: number
}
export interface IOrderPayload {
  customer_name: string
  customer_phone: string
  customer_address: string
  products: IProductPayload[]
  promotion_code: string
  cash_discount: string
}

export interface ICreateCustomerPayload {
  firstName: string
  lastName: string
  email: string
  phone: string
  companyName: string
  companyWebsite: string
  numberOfUsers: string
  customerMessage: string
  refSource: string
  refCode: string
}

export interface ICustomer {
  active: boolean
  amount: number
  code: string
  company: string
  company_website: string
  customer_id: string
  email: string
  firstName: string
  influence_level: number
  lastName: string
  max_point: number
  my_refcode: string
  number_of_users: number
  password: string
  phone: string
  points: number
  role: string
  source: string
}

export type ICustomerResponse = {
  user: ICustomer
}
export type IOrderResponse = IOrderPayload

export interface CouponType {
  _id: string
  code: string
  description: string
  discount_amount: number
  exchange_price: number
  quantity: number
  status: string
  valid_for: number
}

export interface MyCouponType {
  code: string
  coupon_id: string
  description: string
  discount_amount: number
  exchange_price: number
  exchanged_at: string
  expired_at: string
  user_id: string
  user_name: string
}

export interface ICouponsResponse {
  coupons: CouponType[]
}

export interface IMyCouponsResponse {
  coupons: MyCouponType[]
}

export interface ICheckCouponResponse {
  message: 'ok' | 'nok'
}

export interface ProductResponse {
  amount: number
  price: number
  product_id: string
  product_name: string
  name: string
  promotion_discount: number
  sale_price: number
  sub_total: number
}

export interface OrderItem {
  _id: string
  cash_discount: number
  created_at: string
  created_by: string
  customer_address: string
  customer_name: string
  customer_phone: number
  products: ProductResponse[]
  promotion_code: string
  status: string
  total: number
  total_original_price: number
  total_original_sale_price: number
  total_promotion_discount: number
  updated_at: string
}

export interface IOrdersResponse {
  orders: OrderItem[]
}

export interface MyVideo {
  _id: string
  action: string
  created_at: string
  data: {
    video: {
      _id: string
      name: string
    }
  }
  owner_id: string
  rewarded_points: number
  updated_at: string
}

export enum EPurchase {
  PURCHASED = 'PURCHASED',
  SUCCESS = 'SUCCESS',
  ERROR_NOT_ENOUGH_BALANCE = 'ERROR_NOT_ENOUGH_BALANCE',
  ERROR_VIDEO_NOT_FOUND = 'ERROR_VIDEO_NOT_FOUND',
}

export enum EPurchaseStatus {
  PURCHASED = 'PURCHASED',
  NOTYET_PURCHASED = 'NOTYET_PURCHASED',
}

export interface IPurchaseResponse {
  message: EPurchase
}

export interface IPurchaseStatusResponse {
  message: EPurchaseStatus
}

export interface IMyVideoResponse {
  videos: MyVideo[]
}

export interface IGetMeResponse {
  user: UserType
}

export interface IBodyUpdateRemainingMinutes {
  minutes_limit: number
}

export interface ICreateOrderBody {
  plan_id: string
  quantity: number
  payment_method: string
  beneficiary_bank: string
  beneficiary_account: string
  beneficiary_name: string
  amount: number
  discount_amount: number
  transfer_description: string
}

export interface IUpdateAvailableMinutesBody {
  minutes: number
}

export interface ILimitTimeResponse {
  message: 'ok'
  minutes_limit: number
}

export interface IBaseResponse {
  message: 'ok'
}

export interface IPlansResponse {
  plans: IPlan[]
}

export interface IAvailableTimeResponse {
  available_minutes: number
  message: string
  points: number
}

interface IPlanSearchParams {
  plan_type: string
}

interface IGenerateOrderParams {
  plan_id: string
  quantity: number
}

export interface IGenerateOrder {
  discount_amount: number
  discount_percent: number
  last_price: number
  total_price: number
  transfer_description: string
}

export interface IGenerateOrderResponse {
  data: IGenerateOrder
  message: 'ok'
}

export interface IBank {
  bank_account: string
  bank_logo: string
  bank_name: string
  beneficiary_name: string
}

export interface IBanksResponse {
  banks: IBank[]
}

export interface ISignInResponse {
  user: UserType
  access_token: string
}

export type IResultRecord = ApiResultRecordRequestType & {
  concentration_level: number
  created_at: string
  is_sponsor_video: boolean
  point: number
}

export enum EResultStatus {
  answered = 'Question Answered',
}

export type IResultRecordResponse = {
  message: EResultStatus
  record: IResultRecord
}

export const apis = {
  postSignIn: async (data: { password: string; email: string }): Promise<ISignInResponse> =>
    request.post('/users/sign_in', data, false, {
      handleError: authErrorCodeMessage,
    }),

  getCustomerBrands: (): Promise<{ customers: CustomerBranch[] }> => request.get('/customers'),

  getVideoList: async (params: IVideoParams = {}, isAuth = false): Promise<IVideoResponse> => {
    const { category, ...otherParams } = params ?? {}

    const url = `/videos${category ? `/${category}` : ''}?`

    return isAuth ? request.getWithAuth(url, otherParams) : request.get(url, otherParams)
  },
  getVideoDetail: async (id: string): Promise<IVideoDetailResponse> => {
    const { email } = getRecoil(userInfoAtom)
    if (email) {
      return request.getWithAuth(`/videos/details/${id}`)
    }

    return request.get(`/videos/details/${id}`)
  },

  getQuestionStatus: async (videoId: string, questionId: number): Promise<IResultRecordResponse> => {
    return request.getWithAuth(`/videos/${videoId}/questions/${questionId}`)
  },

  createOrder: async (payload: IOrderPayload): Promise<IOrderResponse> => {
    return request.post(`/orders`, payload, true)
  },
  myCoupons: async (): Promise<IMyCouponsResponse> => {
    return request.getWithAuth(`/coupons/me`)
  },
  coupons: async (): Promise<ICouponsResponse> => {
    return request.getWithAuth(`/coupons`)
  },
  exchangeCoupon: async (id: string): Promise<IOrderResponse> => {
    return request.getWithAuth(`/coupons/${id}/exchange`)
  },
  getCoupons: async (code: string): Promise<ICheckCouponResponse> => {
    return request.getWithAuth(`/coupons/${code}`)
  },
  getOrders: async (): Promise<IOrdersResponse> => {
    return request.getWithAuth(`/orders`)
  },
  purchaseStatus: async (videoId: string): Promise<IPurchaseStatusResponse> => {
    return request.getWithAuth(`/videos/${videoId}/purchase_status`)
  },
  purchaseVideo: async (videoId: string): Promise<IPurchaseResponse> => {
    return request.postWithAuth(`/videos/${videoId}/purchase`, {})
  },

  myVideos: async (): Promise<IMyVideoResponse> => {
    return request.getWithAuth(`/my_videos`)
  },
  getMe: async (): Promise<IGetMeResponse> => {
    return request.getWithAuthV2(`/users/me`)
  },

  createNewCustomer: async (payload: ICreateCustomerPayload): Promise<ICustomerResponse> => {
    return request.post(`/customers/sign_up`, payload)
  },
  updateLimitTime: (body: IBodyUpdateRemainingMinutes): Promise<ILimitTimeResponse> => {
    return request.postWithAuth(`/users/me/remaining_minutes`, body)
  },
  updateAvailableTime: (body: IUpdateAvailableMinutesBody): Promise<IAvailableTimeResponse> => {
    return request.putWithAuth(`/users/me/update_minutes`, body)
  },
  getPersonalPlans: (planType = 'personal'): Promise<IPlansResponse> => {
    return apis.getPlans({
      plan_type: planType,
    })
  },
  getPlans: (params?: IPlanSearchParams): Promise<IPlansResponse> => {
    return request.getWithAuthV2(`/subscriptions/plans`, params)
  },
  generateOrder: (params?: IGenerateOrderParams): Promise<IGenerateOrderResponse> => {
    return request.getWithAuthV2(`/subscriptions/generate_order`, params)
  },
  banksList: (): Promise<IBanksResponse> => {
    return request.getWithAuthV2(`/subscriptions/banks`)
  },
  createPlanOrder: (body: ICreateOrderBody): Promise<IBaseResponse> => {
    return request.postWithAuth(`/subscriptions/orders`, body)
  },
}

export const { postSignIn } = apis
