import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import userInfoAtom from '@recoil/userInfoAtom'

import pathUrl from '@routes/path'
import Logger from '@managers/Logger'

import useInfoProfile from '@components/useHook/useInfoProfile'
import { showModal } from '@managers/GlobalModal'
import InfoAccountModal from '@managers/DemoAccount/InfoAccountModal'

type Props = {
  app: JSX.Element
}

const AppInitControl: React.FC<Props> = ({ app }) => {
  const { location } = useHistory()

  const { handleRefreshProfile } = useInfoProfile()

  const [hasDoneInitApp, setHasDoneInitApp] = useState(false)
  const [userInfo] = useRecoilState(userInfoAtom)

  const _handleShowModal = () => {
    showModal({
      content: <InfoAccountModal />,
    })
  }

  const _loginDemoAccount = async () => {
    setHasDoneInitApp(true)
    setTimeout(() => {
      _handleShowModal()
    }, 1)
  }

  const _handleGetProfile = async () => {
    try {
      await handleRefreshProfile()
      setHasDoneInitApp(true)
    } catch (error) {
      Logger.error('_handleLogin')
    }
  }

  const hasPageNotAccept = () => {
    const basePath = location.pathname.split('?')[0]
    return [
      pathUrl.registrationSLO(),
      pathUrl.emailVerification(),
      pathUrl.passwordReset(),
      pathUrl.forgetPassword(),
    ].includes(basePath)
  }

  useEffect(() => {
    if (userInfo?.email) {
      _handleGetProfile()
    } else if (hasPageNotAccept()) {
      // handle
      setHasDoneInitApp(true)
    } else {
      _loginDemoAccount()
    }
  }, [])

  if (hasDoneInitApp) {
    return app
  }

  return <>Loading...</>
}

export default AppInitControl
