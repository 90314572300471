import React, { useEffect } from 'react'
import { Col } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

import { CONFIG_APP } from '@constants/constants'
import appConfigAtom from '@recoil/appConfigAtom'

import imagesAssets from '../../assets/imgs'
import { CategoryItem } from '../../components/Item'
import { mobileAndTabletCheck } from '../../utils'
import { apis } from '../../apis'
import customerBrandsAtom from '../../recoil/customerBrandAtom'
import { CustomerBranch } from '../../types'
import videosAtom from '../../recoil/videosAtom'
import { brandTotal, brandMyVideo, classroomItem, dashboardItem } from './constant'
import userInfoAtom from '../../recoil/userInfoAtom'
import pathUrl from '../../routes/path'
import { checkPath } from '../../routes/helpers'

export const overview: CustomerBranch = {
  _id: 'overview',
  img: imagesAssets.overview,
  imgActive: imagesAssets?.overviewIn,
  name: 'Tổng quan',
}

const Slide = () => {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const [appConfig] = useRecoilState(appConfigAtom)

  const setCustomerBrands = useSetRecoilState(customerBrandsAtom)
  const userInfo = useRecoilValue(userInfoAtom)
  const customerBrandsStore = useRecoilValue(customerBrandsAtom)
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const { customerId } = videosStore?.filter ?? {}

  const handleSelectBrand = (item: CustomerBranch) => {
    setVideosStore((value) => ({
      ...value,
      filter: {
        ...value?.filter,
        customerId: item?._id,
      },
    }))
  }

  const handleHome = (brand: CustomerBranch) => {
    history.push(pathUrl.home())
    handleSelectBrand(brand)
  }

  const handleClickHome = () => {
    history.push(pathUrl.home())
    handleSelectBrand(brandTotal)
  }

  const handleClickClassroom = () => {
    history.push(pathUrl.classroom())
    handleSelectBrand(classroomItem)
  }

  const renderOtherLineITem = () => {
    const newBrandTotal = { ...brandTotal }
    newBrandTotal.onClick = handleClickHome

    const brands = [newBrandTotal]

    if (userInfo?.email) {
      const newBrandMyVideo = {
        ...brandMyVideo,
        onClick: () => {
          handleHome(newBrandMyVideo)
        },
      }
      brands.push(newBrandMyVideo)

      brands.push({
        ...dashboardItem,
        onClick: () => {
          window.location.href = CONFIG_APP.dashboardUrl
        },
      })

      const classroomItemMenu = {
        ...classroomItem,
        onClick: handleClickClassroom,
      }

      brands.push(classroomItemMenu)
    }

    return (
      <>
        {brands.map((item) => (
          <React.Fragment key={item._id}>
            <CategoryItem
              imageActive={item.imgActive}
              isActive={customerId === item._id}
              onClick={() => (item.onClick ? item.onClick() : handleSelectBrand(item))}
              image={item.img}
              isBeta={item.isBeta}
              name={t(item.name)}
            />
            <hr />
          </React.Fragment>
        ))}

        <CategoryItem
          imageActive={imagesAssets?.help}
          isActive={false}
          onClick={() => null}
          image={imagesAssets.help}
          name={t('home.help')}
        />
        <hr />
        <CategoryItem
          imageActive={imagesAssets?.help}
          isActive={false}
          onClick={() => null}
          image={imagesAssets.feedback}
          name={t('home.response')}
        />
      </>
    )
  }

  const renderLine = (item: CustomerBranch) => {
    return (
      <CategoryItem
        imageActive={item?.imgActive}
        key={item._id}
        isActive={customerId === item?._id}
        onClick={() => handleSelectBrand(item)}
        image={imagesAssets.item}
        name={item.name}
      />
    )
  }

  const renderCustomerBrands = () => {
    const categoriesMenu = customerBrandsStore?.customerBrands

    return categoriesMenu ? categoriesMenu?.map((item) => renderLine(item)) : <></>
  }

  const renderStatistical = () => {
    if (!userInfo?.email) {
      return <></>
    }

    const menu: CustomerBranch[] = [
      {
        ...overview,
        onClick: () => {
          history.push(pathUrl.overview())
          handleSelectBrand(overview)
        },
      },
    ]

    return (
      <div className='min-h-[300px]'>
        <div className='pl-5 py-2 '>Thống kê</div>
        {menu.map((item) => (
          <CategoryItem
            key={item?._id}
            imageActive={item.imgActive}
            isActive={customerId === item._id}
            onClick={() => (item.onClick ? item.onClick() : handleSelectBrand(item))}
            image={item.img}
            name={item.name}
          />
        ))}
      </div>
    )
  }

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  React.useEffect(() => {
    const getApi = () => {
      setCustomerBrands({ loading: true, customerBrands: [] })

      apis.getCustomerBrands().then((result) => {
        if (result) {
          setCustomerBrands({
            loading: false,
            customerBrands: result.customers,
          })
        }
      })
    }

    getApi()
  }, [])

  useEffect(() => {
    if (!userInfo?.email) {
      handleSelectBrand(brandTotal)
    }
  }, [userInfo])

  useEffect(() => {
    const { pathname } = location

    if (pathname.includes(pathUrl.overview())) {
      handleSelectBrand(overview)
    } else if (pathname.includes(pathUrl.classroom()) || checkPath(pathUrl.lessons(), pathname)) {
      handleSelectBrand(classroomItem)
    }
  }, [location?.pathname])

  return (
    <>
      <div className=' fixed z-10 h-screen top-0 w-[16.7%] overflow-auto'>
        <div style={{ marginTop: `${appConfig.heightHeader}px` }} />
        <div className='bg-[#FFFFFF] h-full overflow-auto'>
          <div className=''>
            {renderOtherLineITem()}
            {/* {renderStatistical()} */}
            {/* <div className="pl-5 py-4">
          NHÃN HÀNG
        </div>
        <div className='min-h-[15rem]'>
          {renderCustomerBrands()}
        </div> */}
          </div>
          <div className='pb-5 pt-5 border-t border-t-[#E5E5E5] px-5'>
            <a href='#'>
              <p className='py-1 text-base font-semibold'>{t('home.about_us')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.deepview')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.team')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.recruit')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.store')}</p>
            </a>

            <p className='py-1 text-sm mt-5 font-medium'>{t('home.phone')}</p>
            <p className='py-1 text-sm font-medium'>{t('home.email')}</p>
            <p className='py-1 text-sm font-medium'>{t('home.chat')}</p>
            <p className='py-1 mt-5 text-sm font-medium'>{t('home.language')}</p>
            <p className='flex items-center cursor-pointer' onClick={() => handleChangeLanguage('vi')}>
              <img className='mr-3' width={24} height={24} src={imagesAssets.vi} alt='vi' />
              {t('home.vi')}
            </p>
            <p className='flex items-center mt-3 cursor-pointer' onClick={() => handleChangeLanguage('en')}>
              <img className='mr-3' width={24} height={24} src={imagesAssets.eng} alt='EN' />
              {t('home.en')}
            </p>
            <p className='py-1 mt-5 text-sm font-medium'>© 2022 {CONFIG_APP.nameApp}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Slide
