/* eslint-disable no-unused-expressions */
import { ConfigProvider, Empty } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { apis, OrderItem } from '../../../apis'
import imagesAssets from '../../../assets/imgs'
import PrimaryBtn from '../../Button/PrimaryBtn'
import BaseModal, { BaseModalProps } from '../BaseModal'
import HistoryPurchase from './HistoryPurchase'
import './styles.scss'

interface Props extends BaseModalProps {
  onCancel: () => void
}

const PurchasedVideo: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [orders, setOrders] = React.useState<OrderItem[]>([])

  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  React.useEffect(() => {
    const getData = async () => {
      try {
        const response = await apis.getOrders()

        if (response?.orders) {
          setOrders(response?.orders)
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    if (props?.open) {
      getData()
    }
  }, [props?.open])

  return (
    <BaseModal className='historyModal' width='80%' {...props}>
      <div className='historyWrapper'>
        <div className='modalTitle'>{t('history.history_purchase')}</div>
        <div className='modalContext'>
          {props?.open ? (
            <ConfigProvider
              renderEmpty={() => <Empty image={imagesAssets.empty} description={t('history.history_purchase')} />}
            >
              <HistoryPurchase orders={orders} />
            </ConfigProvider>
          ) : (
            <></>
          )}
        </div>
        <div className='submit-footer'>
          <PrimaryBtn className='closeBtn' onClick={handleClose}>
            {t('thank.close')}
          </PrimaryBtn>
        </div>
      </div>
    </BaseModal>
  )
}

export default PurchasedVideo
