/* eslint-disable consistent-return */
import { useRecoilState } from 'recoil'

import { UserType } from 'types'
import { apis } from '@apis'
import userInfoAtom from '@recoil/userInfoAtom'

type ICallback = (user: UserType) => void

const useInfoProfile = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)

  const handleRefreshProfile = async (callback?: ICallback) => {
    if (!userInfo?.email) return

    try {
      const res = await apis.getMe()
      setUserInfo((values) => ({ ...values, ...res?.user }))
      callback && callback(res?.user)
      return res
    } catch (error) {
      setUserInfo({})
      window.location.href = '/'
      return undefined
    }
  }

  return { userInfo, handleRefreshProfile }
}

export default useInfoProfile
