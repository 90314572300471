import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import datalabels from 'chartjs-plugin-datalabels'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, datalabels)

export const options: ChartOptions<'bar'> = {
  responsive: true,
  scales: {
    y: {
      border: {
        display: false,
      },
      grid: {
        display: true,
        color: '#E5E5E5',
        tickBorderDash: [8, 5],
        tickBorderDashOffset: 90,
      },
    },
    x: {
      border: {
        dash: [9, 7],
        dashOffset: 6,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      align: 'top',
      anchor: 'end',
      color: '#1C62B9',
    },
    tooltip: {
      enabled: false,
    },
  },
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

export const data: ChartData<'bar', number[], string> = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [23, 34, 56, 67, 12, 45, 23],
      backgroundColor: '#64B5F6',
      barThickness: 50,
    },
  ],
}

export const VerticalBarChart = () => {
  return <Bar options={options as any} data={data} />
}
