import { atom } from 'recoil'
import { VideoType } from '../../../../types'
import { ESortVideos, EVideoStatus } from '../api'

interface IVideoClassesType {
  videos: VideoType[]
  total: number
  loading: boolean
  filter: {
    page: number
    page_limit: number
    status?: EVideoStatus
    search?: string
    sort: ESortVideos
  }
}

export const VIDEOS_LIMIT_PAGE = 20

const videoClassesAtom = atom<IVideoClassesType>({
  key: 'videoClassesAtom',
  default: {
    videos: [],
    total: 0,
    loading: false,
    filter: {
      sort: ESortVideos.NEWEST,
      status: EVideoStatus.INCOMPLETE,
      page: 1,
      page_limit: VIDEOS_LIMIT_PAGE,
    },
  },
})

export default videoClassesAtom
