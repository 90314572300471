import React from 'react'
import _ReactPlayer, { ReactPlayerProps } from 'react-player'
import Truncate from 'react-truncate'
import pathUrl from '@routes/path'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { convertToHHMMSS } from '@utils/format'

import './styles.scss'
import Svg from '../../../../../components/Svg'
import imagesAssets from '../../../../../assets/imgs'
import { VideoType } from '../../../../../types'
import { IDetailClassResponse } from '../../api'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

interface Props {
  videoDetail: VideoType
  classes: IDetailClassResponse
  isDisable?: boolean
}

const VideoLessonsItem: React.FC<Props> = ({ isDisable = false, videoDetail, classes }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { video, name } = videoDetail

  const handleClick = async () => {
    // handleClick
    history.push(pathUrl.learnVideo(classes?.class?._id, videoDetail?.id))
  }

  const renderPlayer = () => {
    return (
      <div className='blurPlayer noteVideo'>
        <div className='flex h-fit'>
          <div>
            <Svg.Play />
          </div>
        </div>
      </div>
    )
  }

  const renderVideoType = () => {
    return t('detail_class.detail_class_sponsored')
  }

  return (
    <>
      <div className='videoLessonsItem' onClick={handleClick}>
        <div className={`videoPlayerWrapper ${isDisable ? 'video-disable' : ''}`}>
          <ReactPlayer
            url={video}
            width='100%'
            height='100%'
            className='reactPlayer'
            playIcon={<></>}
            controls={false}
            config={{
              youtube: {
                playerVars: { showinfo: 0, controls: 0 },
              },
            }}
            light // preview only, not load the full player
          />
          <div className='video-length'>{convertToHHMMSS(videoDetail.length_in_seconds)}</div>
          <div className='blurPlayer' />
          {renderPlayer()}
        </div>
        <div className='videoNameWrapper'>
          <div className='infoWrapper overflow-hidden'>
            <div className='flex flex-row w-full'>
              <img className='w-11 h-11 mr-3' src={imagesAssets.avatar} alt='name' />
              <div>
                <div className='videoName'>
                  <Truncate lines={2}>{name}</Truncate>
                </div>
                {/* <div className='my-2 w-full flex'>
                  <Truncate className='text-sm text-[#606060] mr-1' lines={1}>
                   xxx
                  </Truncate>
                </div> */}
                <div className={`flex items-center lesson-info mt-1 ${isDisable ? 'info-disable' : ''}`}>
                  <div className=' rounded-xl px-2 mr-3 info-name'>{videoDetail?.customer?.name}</div>
                  <div className='info-repo'>{renderVideoType()}</div>
                  <div className='bg-[#CCCCCC] w-1 h-1 rounded-lg mx-1' />
                  <div className='info-point'>{videoDetail?.point} DV</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoLessonsItem
