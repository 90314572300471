/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import Truncate from 'react-truncate'
import { useHistory } from 'react-router-dom'
import { VideoStatus } from '@containers/SLO/Classroom/models'
import { showModal } from '@managers/GlobalModal'
import { PendingJoinClass, RejectedJoinClass } from '@containers/SLO/Classroom/components'
import { useTranslation } from 'react-i18next'

import './styles.scss'
import { IClass } from '../../../api'
import { formatDate } from '../../../../../../utils'
import pathUrl from '../../../../../../routes/path'
import RequestJoinClassModal from './RequestJoinClassModal'
import { getRandomStringFromArray } from '../../../helpers/classes'
import { ETabType } from '../../../constants'

interface Props {
  classDetail: IClass
  isDisable?: boolean
  type?: ETabType
}

const ClassroomItemBase: React.FC<Props> = ({ isDisable = false, classDetail, type }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { name, organizer, total_users, total_videos, started_at } = classDetail
  const organizationName = organizer.name

  const handleClickVideo = () => {
    history.push(pathUrl.lessons(classDetail?._id))
  }

  const renderStartAt = () => {
    try {
      return started_at && <Truncate lines={1}>{formatDate(started_at) ?? 'No Name'}</Truncate>
    } catch (e) {
      return 'No Name'
    }
  }

  const renderOrganizationName = () => {
    const style = isDisable ? 'bg-[#939393] text-[#ffffff]' : 'video-organization-name'

    return (
      <span className={`h-fit flex text-[13px] rounded-[10px] px-2 py-[1px] w-fit text-center ${style}`}>
        {organizationName}
      </span>
    )
  }

  const handleClick = async () => {
    if ([VideoStatus.DROPPED, VideoStatus.NOT_ENROLLED].includes(classDetail.user_status)) {
      setIsModalOpen(true)
      return
    }

    if (classDetail.user_status === VideoStatus.REJECTED) {
      showModal({
        content: RejectedJoinClass(),
      })

      return
    }

    if (classDetail.user_status === VideoStatus.PENDING) {
      showModal({
        content: PendingJoinClass(),
      })

      return
    }

    // handleClick
    handleClickVideo()
  }

  const handleCloseModal = () => {
    setIsModalOpen(() => false)
  }

  const renderPlayer = () => {
    return (
      <div className='blurPlayer noteVideo'>
        <div className='flex h-fit'>
          <div className='text-[4em] video-text-color'>{name[0]}</div>
        </div>
      </div>
    )
  }

  const renderInfoNumberStudent = () => {
    return (
      <div className='flex my-2 items-center'>
        <div>{t('overview_class.overview_student', { total: total_users })}</div>
        <div className='w-[6px] h-[6px] bg-[#CCCCCC] mx-[6px] rounded' />
        <div>{t('overview_class.overview_lesson', { total: total_videos })}</div>
      </div>
    )
  }

  const getColor = useMemo(() => {
    if (isDisable) {
      return 'video-disable'
    }

    return getRandomStringFromArray(['video-blue', 'video-green', 'video-yellow', 'video-dashboard', 'video-violet'])
  }, [])

  const renderModal = () => {
    return <RequestJoinClassModal classDetail={classDetail} onCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
  }

  return (
    <>
      <div className={`videoClassroomItem ${getColor}`} onClick={handleClick}>
        <div className={`videoPlayerWrapper ${isDisable ? 'video-disable' : ''}`}>
          <div className='video-length'>--:--:--</div>
          {renderPlayer()}
        </div>
        <div className='videoNameWrapper'>
          <div className='infoWrapper w-full overflow-hidden'>
            <div className='videoName mb-2'>
              <Truncate lines={2}>{name}</Truncate>
            </div>
            <div className='flex'>
              <div className='flex-none pr-3'>{renderOrganizationName()}</div>
              <div className='flex-auto'>{renderStartAt()}</div>
            </div>
            <div>{renderInfoNumberStudent()}</div>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  )
}

export default React.memo(ClassroomItemBase)
