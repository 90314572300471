import * as React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { ButtonProps } from 'antd/lib/button'

const MainButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button type='primary' className={`mainBtn ${props.className ?? ''}`} {...props}>
      {props.children || 'Submit'}
    </Button>
  )
}

export default MainButton
