import { message } from 'antd'
import { getRecoil } from 'recoil-nexus'
import _ from 'lodash'

import authAtom from '@containers/Auth/atom/authAtom'

import { BASE_API } from '../constants/constants'
import userInfoAtom from '../recoil/userInfoAtom'

export interface IError {
  code: string
  message: string
}

export interface IOptionFunction {
  handleError?: (error: IError) => void
  callbackResponseSuccess?: (response: Response, data: any) => void
}

const handleHeader = (withAuth: boolean) => {
  const { email, code } = getRecoil(userInfoAtom)
  const { accessToken } = getRecoil(authAtom)

  return {
    'Content-Type': 'application/json',
    ...(withAuth && email && code
      ? { 'X-User-Email': email, 'X-User-Code': code, Authorization: `Bearer ${accessToken}` }
      : {}),
  }
}

const post = <T>(url: string, data: T, withAuth = false, options: IOptionFunction = {}) => {
  const { handleError, callbackResponseSuccess } = options

  const requestOptions: any = {
    method: 'POST',
    headers: handleHeader(withAuth),
    body: JSON.stringify(data),
  }
  return fetch(`${BASE_API}${url}`, requestOptions)
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      const data = isJson && (await response.json())
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = { code: response.status, message: (data && data.message) || response.status, data }
        return Promise.reject(error)
      }

      callbackResponseSuccess && callbackResponseSuccess(response, data)
      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      if (handleError) {
        handleError(error)
      } else {
        message.error(error?.message.toString())
      }
      throw error
    })
}

const put = <T>(url: string, data: T, withAuth = false, options: IOptionFunction = {}) => {
  const { handleError } = options

  const requestOptions: any = {
    method: 'PUT',
    headers: handleHeader(withAuth),
    body: JSON.stringify(data),
  }
  return fetch(`${BASE_API}${url}`, requestOptions)
    .then(async (response) => {
      const isJson = response.headers.get('content-type')?.includes('application/json')
      const data = isJson && (await response.json())

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = { code: response.status, message: (data && data.message) || response.status, data }
        return Promise.reject(error)
      }

      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      if (handleError) {
        handleError(error)
      } else {
        message.error(error?.message.toString())
      }
      throw error
    })
}

const get = (url: string, data?: any, withAuth = false, options: IOptionFunction = {}) => {
  const { handleError, callbackResponseSuccess } = options

  const requestOptions: any = {
    method: 'GET',
    headers: handleHeader(withAuth),
  }
  const urlString = data ? new URLSearchParams(data).toString() : ''
  return fetch(`${BASE_API}${url}${urlString}`, requestOptions)
    .then(async (response) => {
      const data = await response.json()

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = { code: response.status, message: (data && data.message) || response.status, data }
        return Promise.reject(error)
      }

      callbackResponseSuccess && callbackResponseSuccess(response, data)
      return data
    })
    .catch((error) => {
      console.error('There was an error!', error)
      if (handleError) {
        handleError(error)
      } else {
        message.error(error?.message.toString())
      }
      throw error
    })
}

const request = {
  post,
  get,
  postWithAuth: <T>(url: string, data: T, options?: IOptionFunction) => post(url, data, true, options),
  putWithAuth: <T>(url: string, data: T, options?: IOptionFunction) => put(url, data, true, options),
  getWithAuth: <T>(url: string, data?: T, options?: IOptionFunction) => get(url, data, true, options),
  getWithAuthV2: <T>(url: string, data?: T, options?: IOptionFunction) =>
    get(`${url}${_.isEmpty(data) ? '' : '?'}`, data, true, options),
}

export default request
