import React from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  DoughnutControllerChartOptions,
  ChartData,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'

ChartJS.register(ArcElement)

export interface IDoughnutData {
  label: string
  value: number
  bgColor: string
}

const options: _DeepPartialObject<
  CoreChartOptions<'doughnut'> &
    ElementChartOptions<'doughnut'> &
    PluginChartOptions<'doughnut'> &
    DatasetChartOptions<'doughnut'> &
    DoughnutControllerChartOptions & {
      borderWidth: number
    }
> = {
  cutout: '80%',
  borderColor: 'red',
  borderWidth: 0,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      borderWidth: 0,
    },
    datalabels: {
      display: false,
    },
  },
}

const formatData = (data: IDoughnutData[] = []): ChartData<'doughnut', number[], string> => {
  const labels: string[] = []
  const dataChart: number[] = []
  const bgColor: string[] = []

  data.forEach((item) => {
    labels.push(item.label)
    dataChart.push(item.value)
    bgColor.push(item.bgColor)
  })

  return {
    labels,
    datasets: [
      {
        data: dataChart,
        backgroundColor: bgColor,
      },
    ],
  }
}

interface Props {
  data: IDoughnutData[]
}

export const DoughnutChart: React.FC<Props> = ({ data }) => {
  return <Doughnut data={formatData(data)} options={options} />
}
