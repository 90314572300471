import { message } from 'antd'
import { IError } from '@utils/request'

import { translateHelper } from '@lang/langManager'

const ErrorMessageMapping: any = {
  USER_NOT_FOUND: 'login.user_not_found',
  INVALID_AUTHENTICATION: 'login.invalid_authentication',
  PASSWORD_NOT_STRONG_ENOUGH: 'auth.passwordNotStrongEnough',
  EMAIL_NOT_VERIFIED: 'auth.emailNotVerified',
  USER_EXISTS: 'signUp.userExists',
}

export const authErrorCodeMessage = (error: IError) => {
  const messageError = translateHelper(ErrorMessageMapping[error?.message] ?? error?.message)
  message.error(messageError ?? error?.message)
}
