export default {
  'common.account': 'Tài Khoản',
  'validate.require': 'Thông tin bắt buộc',
  'validate.email': 'Thông tin không hợp lệ E-mail!',
  'error.emailNotFound': 'Không tìm thấy email',
  'validate.phone': 'Số điện thoại không chính xác',
  'common.referral': 'Mã giới thiệu',
  'common.availableBalance': 'Số dư khả dụng (Rút tiền)',
  'common.availablePurchase': 'Số dư mua hàng',
  'common.accumulatedPoint': 'Điểm tích lũy',
  'common.purchaseHistory': 'Lịch sử mua hàng',
  'common.logout': 'Đăng xuất',
}
