import { createRef } from 'react'

export interface RemainingMinutesParams {
  callBackOnClose: () => void
}

export interface RemainingMinutesMethods {
  showModal: (params?: RemainingMinutesParams) => void
  hideModal: () => void
  show5MinutesModal: (params?: RemainingMinutesParams) => void
  showEndTimeOutModal: (params?: RemainingMinutesParams) => void
  showPendingUpgradeModal: (params?: RemainingMinutesParams) => void
}

export const remainingMinutesRef = createRef<RemainingMinutesMethods>()

export function showTimeOutModal(params?: RemainingMinutesParams) {
  remainingMinutesRef.current?.showModal(params)
}

export function hideTimeOutModal() {
  remainingMinutesRef.current?.hideModal()
}

export function show5MinutesModal(params?: RemainingMinutesParams) {
  remainingMinutesRef.current?.show5MinutesModal(params)
}

export function showEndTimeOutModal(params?: RemainingMinutesParams) {
  remainingMinutesRef.current?.showEndTimeOutModal(params)
}

export function showPendingUpgradeModal(params?: RemainingMinutesParams) {
  remainingMinutesRef.current?.showPendingUpgradeModal(params)
}
