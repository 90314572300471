import { Button, Col, Form, Input, Radio, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CONFIG_APP } from '@constants/constants'

import { apis, ICreateCustomerPayload } from '../../../apis'
import imagesAssets from '../../../assets/imgs'
import Svg from '../../../components/Svg'
import { ItemForm } from './components'

import './RegistrationForm.scss'

const ContentText: React.FC = ({ children }) => {
  return (
    <div className='flex mt-4'>
      <div className='mr-4'>
        <Svg.NextDiv />
      </div>
      <div>{children}</div>
    </div>
  )
}

type IRegistrationForm = ICreateCustomerPayload & {
  name: string
}

const RegistrationForm = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [email, setEmail] = useState('')

  const onFinish = async (form: IRegistrationForm) => {
    try {
      setIsLoading(true)
      const nameSplits = form.name.split(' ') ?? []

      const firstName = nameSplits[0]
      const lastName = nameSplits.slice(1).join(' ')
      const payload: IRegistrationForm = { ...form, firstName, lastName }

      await apis.createNewCustomer(payload)

      setEmail(payload?.email)
      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
    setIsLoading(false)
  }

  const renderLeftSlide = () => {
    return (
      <div className='max-w-[510px]'>
        <div>
          <div className='text-center'>
            <div className='flex justify-center'>
              <Svg.Registration height={52} />
            </div>
            <div className='mt-4 font-[500] text-[#F9A621] text-3xl'>{t('registration.registration')}</div>
          </div>
          <div className='w-full border-b-[#E5E5E5] border-b-[1px] mt-3' />
          <div className='mt-9'>
            <div className=' font-[600] text-2xl uppercase'>Nền tảng tự học cho doanh nghiệp</div>
            <div className='font-[700] text-base mt-5'>Phù hợp với mọi nhu cầu của doanh nghiệp</div>
            <div>
              <ContentText>
                <span className='font-[700]'>Tạo thói quen:</span> Hệ thống đào tạo giúp nhân viên hình thành thói quen
                tập trung tự học mỗi ngày
              </ContentText>
              <ContentText>
                <span className='font-[700]'>Tối ưu chi phí:</span> Đào tạo mang lại hiệu quả hơn với chi phí giảm tới
                90% so với đào tạo tập trung
              </ContentText>
              <ContentText>
                <span className='font-[700]'> Thực hành chánh niệm:</span> Người học tập trung vào nội dung, học nhiều
                giờ, không căng thẳng, có thể áp dụng như một hình thức thực hành chánh niệm.
              </ContentText>
              <ContentText>
                <span className='font-[700]'>Theo dõi kết quả học tập:</span> Doanh nghiệp hiểu rõ hơn về kết quả học
                tập và mức độ tập trung của từng nhân viên.
              </ContentText>
              <ContentText>
                <span className='font-[700]'>Hỗ trợ tích cực:</span> ${CONFIG_APP.nameApp} đưa ra tư vấn riêng cho từng
                doanh nghiệp để áp dụng hiệu quả.
              </ContentText>
            </div>
            <div className='mt-4'>
              “Tăng hiệu quả - Giảm chi phí đào tạo với ${CONFIG_APP.nameApp}: Đăng ký thông tin để trải nghiệm miễn phí
              30 ngày”
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className='ml-6 sm:ml-0'>
        <Form onFinish={onFinish} style={{ maxWidth: 510 }}>
          <ItemForm name='Tên công ty' isRequired>
            <Form.Item name='companyName' rules={[{ required: true, message: t('validate.require') ?? '' }]}>
              <Input className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]' placeholder='Tên công ty' />
            </Form.Item>
          </ItemForm>
          <ItemForm name='Email công ty' isRequired>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: t('validate.require') ?? '' },
                { type: 'email', message: t('login.email') ?? '' },
              ]}
            >
              <Input className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]' placeholder='Email công ty' />
            </Form.Item>
          </ItemForm>
          <Row gutter={12}>
            <Col span={12}>
              <ItemForm name='Website công ty' isRequired>
                <Form.Item name='companyWebsite' rules={[{ required: true, message: t('validate.require') ?? '' }]}>
                  <Input className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]' placeholder='Website công ty' />
                </Form.Item>
              </ItemForm>
            </Col>
            <Col span={12}>
              <ItemForm name='Số người sử dụng' isRequired>
                <Form.Item name='numberOfUsers' rules={[{ required: true, message: t('validate.require') ?? '' }]}>
                  <Input
                    type='number'
                    className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]'
                    placeholder='Số người sử dụng'
                  />
                </Form.Item>
              </ItemForm>
            </Col>
          </Row>
          <ItemForm name='Tên của bạn' isRequired>
            <Form.Item name='name' rules={[{ required: true, message: t('validate.require') ?? '' }]}>
              <Input className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]' placeholder='Tên của bạn' />
            </Form.Item>
          </ItemForm>
          <ItemForm name='Số điện thoại của bạn' isRequired>
            <Form.Item
              name='phone'
              rules={[
                { required: true, message: t('validate.require') ?? '' },
                { min: 8, message: t('validate.phone') ?? '' },
                { max: 11, message: t('validate.phone') ?? '' },
              ]}
            >
              <Input
                type='number'
                maxLength={11}
                minLength={8}
                allowClear
                className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]'
                placeholder='Số điện thoại của bạn'
              />
            </Form.Item>
          </ItemForm>
          <ItemForm name='Để lại lời nhắn'>
            <Form.Item name='customerMessage'>
              <Input.TextArea className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]' placeholder='Lời nhắn' />
            </Form.Item>
          </ItemForm>
          <ItemForm name={`Bạn biết ${CONFIG_APP.nameApp} qua đâu?`}>
            <Form.Item name='refSource'>
              <Input
                className='rounded-[10px] border-[#CCCCCC] bg-[#FFFFFF]'
                placeholder={`Bạn biết ${CONFIG_APP.nameApp} qua đâu?`}
              />
            </Form.Item>
          </ItemForm>
          <Button
            htmlType='submit'
            className='w-full bg-[#F9A621] font-[600] rounded-[10px] border-0 text-[#FFFFFF]'
            loading={isLoading}
          >
            SUBMIT
          </Button>
        </Form>
        <div className='border-b border-b-[#ffffff] my-5' />
        <div>
          <div className='font-[600]'>Bạn đã có tài khoản?</div>
          <div className='my-4'>Đăng nhập và xây dựng học liệu cho doanh nghiệp của bạn.</div>
          <Button className='w-full bg-[#FFFFFF] font-[400] rounded-[10px] border-0 text-[#F9A621]' onClick={() => ''}>
            ĐĂNG NHẬP
          </Button>
        </div>
      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <div className='bg-[#FFFFFF] text-[#606060] max-w-[1000px] registration-success flex justify-center flex-col items-center p-8'>
        <div>
          <img className='w-[40px] h-[40px]' src={imagesAssets.tick} alt='logo' />
        </div>
        <div className='text-[#1F91FA] py-6 font-[500] text-2xl uppercase'>ĐĂNG KÝ THÀNH CÔNG</div>
        <div className='text-center'>Cảm ơn bạn đã đăng ký, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.</div>
        <div className='mt-2 text-center'>
          Chúng tôi đã gửi cho bạn một email kèm tài khoản dùng thử qua email {email}, vui lòng kiểm tra để nhận tài
          khoản.
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (isSuccess) {
      return renderSuccess()
    }

    return (
      <div className='registration-wrapper'>
        {renderLeftSlide()}
        {renderForm()}
      </div>
    )
  }

  return <div className='registration-form flex justify-center items-center min-h-screen'>{renderContent()}</div>
}

export default RegistrationForm
