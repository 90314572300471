import { Button, Collapse, message } from 'antd'
import React, { useState } from 'react'
import Truncate from 'react-truncate'
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons'
import MessageModal from '@components/Modal/MessageModal'
import { translateHelper } from '@lang/langManager'

import PrimaryBtn from '../../../../../../components/Button/PrimaryBtn'
import SecondaryBtn from '../../../../../../components/Button/SecondaryBtn'
import BaseModal from '../../../../../../components/Modal/BaseModal'
import { EJoin, IClass, classesAPIs } from '../../../api'
import Svg from '../../../../../../components/Svg'

const NAME_PANEL_RESULT = '1'
enum ScreenType {
  BASE = 'base',
  SUCCESS = 'SUCCESS',
  ALREADY_ENROLLED = 'ALREADY_ENROLLED',
  CLASS_NOT_FOUND = 'CLASS_NOT_FOUND',
  ERROR_DROPPED_REQUEST = 'ERROR_DROPPED_REQUEST',
  ERROR_PENDING_REQUEST = 'ERROR_PENDING_REQUEST',
}

type Props = {
  isModalOpen: boolean
  onCloseModal: () => void
  classDetail: IClass
}

const RequestJoinClassModal: React.FC<Props> = ({ isModalOpen, onCloseModal, classDetail }) => {
  const { name, description } = classDetail

  const [activeKey, setActiveKey] = React.useState<string[] | string>([])
  const [screenType, setScreenType] = useState<ScreenType>(ScreenType.BASE)

  const handleShowResultDetail = () => {
    setActiveKey(activeKey === NAME_PANEL_RESULT ? [] : NAME_PANEL_RESULT)
  }

  const handleJoinClass = async () => {
    try {
      await classesAPIs.joinClasses(classDetail._id)

      setScreenType(ScreenType.SUCCESS)
    } catch (error: any) {
      if (error?.message === EJoin.ERROR_ALREADY_ENROLLED) {
        setScreenType(ScreenType.ALREADY_ENROLLED)
        return
      }

      if ([EJoin.ERROR_CLASS_NOT_FOUND, EJoin.ERROR_CANCELLED_REQUEST].includes(error?.message)) {
        setScreenType(ScreenType.CLASS_NOT_FOUND)
        return
      }

      if (error?.message === EJoin.ERROR_DROPPED_REQUEST) {
        setScreenType(ScreenType.ERROR_DROPPED_REQUEST)
        return
      }

      if (error?.message === EJoin.ERROR_PENDING_REQUEST) {
        setScreenType(ScreenType.ERROR_PENDING_REQUEST)
        return
      }

      message.error(error?.message ?? 'Thao Tác Không Thành Công')
    }
  }

  const renderSuccess = () => {
    return (
      <MessageModal
        title={translateHelper('overview_class.overview_participating_message_title')}
        content={translateHelper('overview_class.overview_participating_message_content')}
        icon={<Svg.CheckCircleTick />}
        onClose={onCloseModal}
      />
    )
  }

  const renderAlreadyEnrolled = () => {
    return (
      <MessageModal
        title={translateHelper('overview_class.already_enrolled_title')}
        content={translateHelper('overview_class.already_enrolled_content')}
        icon={<WarningOutlined />}
        onClose={onCloseModal}
      />
    )
  }

  const renderNotFound = () => {
    return (
      <MessageModal
        title={translateHelper('overview_class.not_found_title')}
        content={translateHelper('overview_class.not_found_content')}
        icon={<CloseCircleOutlined />}
        onClose={onCloseModal}
      />
    )
  }

  const renderDropped = () => {
    return (
      <MessageModal
        title={translateHelper('overview_class.dropped_title')}
        content={translateHelper('overview_class.dropped_content')}
        icon={<CloseCircleOutlined style={{ fontSize: '24px', color: 'red' }} />}
        onClose={onCloseModal}
      />
    )
  }

  const renderPending = () => {
    return (
      <MessageModal
        title={translateHelper('overview_class.overview_pending_message_title')}
        content={translateHelper('overview_class.overview_pending_message_content')}
        icon={<Svg.CheckCircleTick />}
        onClose={onCloseModal}
      />
    )
  }

  const renderContent = () => {
    if (screenType === ScreenType.SUCCESS) {
      return renderSuccess()
    }

    if (screenType === ScreenType.ALREADY_ENROLLED) {
      return renderAlreadyEnrolled()
    }

    if (screenType === ScreenType.CLASS_NOT_FOUND) {
      return renderNotFound()
    }

    if (screenType === ScreenType.ERROR_DROPPED_REQUEST) {
      return renderDropped()
    }

    if (screenType === ScreenType.ERROR_PENDING_REQUEST) {
      return renderPending()
    }

    return (
      <>
        <div className='text-center'>
          <div className='font-[500] text-[#1F91FA] text-lg mb-7'>
            {translateHelper('overview_class.overview_popup_participant')}
          </div>
          <div className='text-[#606060] mb-7'>
            {translateHelper('overview_class.overview_popup_question_participant')}
          </div>
          <div className='mb-2'>
            <Truncate lines={1}>{name}</Truncate>
          </div>
          <div>
            <Button type='link' onClick={handleShowResultDetail}>
              {activeKey.length ? translateHelper('video.hidden_detail') : translateHelper('video.more_detail')}
            </Button>
          </div>
          <div>
            <Collapse ghost expandIcon={() => null} activeKey={activeKey}>
              <Collapse.Panel className='py-0' header={<></>} key={NAME_PANEL_RESULT}>
                <div
                  className='font-normal text-base leading-7 text-[#939393]'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className='flex flex-row gap-3 justify-center my-7'>
            <div>
              <SecondaryBtn className='min-w-[150px]' onClick={onCloseModal}>
                {translateHelper('overview_class.overview_popup_close')}
              </SecondaryBtn>
            </div>
            <div>
              <PrimaryBtn className='min-w-[150px]' onClick={handleJoinClass}>
                {translateHelper('overview_class.overview_popup_ok')}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <BaseModal className='classroom-modal' open={isModalOpen} onCancel={onCloseModal}>
      {renderContent()}
    </BaseModal>
  )
}

export default RequestJoinClassModal
