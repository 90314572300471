import React from 'react'
import { SvgIconProps } from './type'

const Back: React.FC<SvgIconProps> = ({ height = 18, width = 18 }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.51638 7.99707C3.62843 7.99707 3.7404 7.99707 3.85246 7.99707C8.20183 7.99715 12.5514 7.99648 16.9008 7.99773C17.6784 7.99798 18.1825 8.63948 17.9378 9.31906C17.789 9.73239 17.4798 9.95055 17.047 9.99272C16.9336 10.0037 16.8183 9.99722 16.7039 9.99722C12.4168 9.99738 8.12982 9.99738 3.8428 9.99738C3.73158 9.99738 3.62036 9.99738 3.44603 9.99738C3.55992 10.1219 3.63052 10.205 3.70727 10.282C5.68595 12.2637 7.66687 14.2432 9.64346 16.2272C10.2489 16.8349 10.0461 17.7485 9.26351 17.9617C8.86316 18.0707 8.53083 17.9407 8.24421 17.6525C7.28967 16.6931 6.33246 15.7363 5.37551 14.7793C3.6972 13.1009 2.01781 11.4234 0.339661 9.74489C-0.1123 9.29281 -0.112217 8.69681 0.333917 8.25115C2.95603 5.63192 5.57897 3.01369 8.19742 0.390961C8.50852 0.07938 8.85126 -0.0862852 9.29115 0.0457138C9.96705 0.248545 10.2245 1.05162 9.79281 1.60945C9.71689 1.70778 9.6239 1.79328 9.53565 1.88162C7.60102 3.81902 5.6663 5.75642 3.72933 7.69157C3.64925 7.77148 3.54743 7.82965 3.45586 7.89798C3.476 7.93098 3.49615 7.96398 3.51638 7.99707Z'
        fill='black'
      />
    </svg>
  )
}

export default Back
