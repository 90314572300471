import React from 'react'
import { SvgIconProps } from './type'

const Close: React.FC<SvgIconProps> = ({ height = 12, width = 12, color = '#030303' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.8332 1.35351L10.6456 0.166626L5.99912 4.81163L1.35411 0.166626L0.166504 1.35495L4.81151 5.99996L0.166504 10.645L1.35411 11.8326L5.99912 7.18756L10.6448 11.8333L11.8324 10.6457L7.18744 5.99996L11.8332 1.35351Z'
        fill={color}
      />
    </svg>
  )
}

export default Close
