import React from 'react'
import { useSetRecoilState } from 'recoil'

import DangerButton from '@components/Button/DangerButton'
import MainButton from '@components/Button/MainButton'
import Svg from '@components/Svg'
import { hideModal } from '@managers/GlobalModal'
import appModalAtom from '@recoil/appModalAtom'
import { ModalType } from 'types/enum'

const TrialPeriodDemoAccountExpired = () => {
  const setAppModal = useSetRecoilState(appModalAtom)

  const handleClose = () => {
    hideModal()
  }

  const handleSignUp = () => {
    handleClose()
    setAppModal({
      mode: ModalType.signUp,
    })
  }

  const handleSignIn = () => {
    handleClose()
    setAppModal({
      mode: ModalType.signIn,
    })
  }

  return (
    <div className='limit-number-wrapper'>
      <div className='title text-[#3083ff] bg-cover flex flex-col justify-center items-center relative'>
        <div className='font-bold text-4xl text-[#D32F2F]'>Thông báo</div>
        <div className='absolute right-0 top-0 cursor-pointer' onClick={handleClose}>
          <Svg.Close />
        </div>
      </div>

      <div className='text-center mt-4'>
        <div className='text-lg'>
          Hết thời gian học thử trên tài khoản Demo, vui lòng Đăng ký / Đăng nhập để tiếp tục.
        </div>
      </div>
      <div className='flex justify-center gap-6 mb-7 mt-4'>
        <DangerButton className='uppercase min-w-[200px]' onClick={handleSignUp}>
          Đăng ký
        </DangerButton>
        <MainButton className='uppercase min-w-[200px]' onClick={handleSignIn}>
          Đăng nhập
        </MainButton>
      </div>
    </div>
  )
}

export default TrialPeriodDemoAccountExpired
