import VI from './vi'

const demoAccountEN: typeof VI = {
  'demoAccount.title': 'Notification',
  'demoAccount.content': 'Click LOG IN to enter your account or wait for {{time}}s.',
  'demoAccount.demo': 'DEMO',
  'demoAccount.login': 'LOG IN',
}

export default demoAccountEN
