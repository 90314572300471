import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import TagManager from 'react-gtm-module'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// eslint-disable-next-line import/extensions
import reportWebVitals from './reportWebVitals'

import App from './app/App'

import 'antd/dist/antd.min.css'
import './index.css'

const tagManagerArgs = {
  gtmId: 'GTM-MPZSJKX',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
