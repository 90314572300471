import React, { useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  ChartOptions,
  ChartData,
  ChartArea,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { externalTooltipHandler } from './helpers/externalTooltipHandler'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  // Title,
  Tooltip,
  Filler,
  // Legend
)

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

  gradient.addColorStop(1, '#AFDCFF')
  // gradient.addColorStop(0.5, );
  gradient.addColorStop(0, 'rgba(100, 181, 246, 0)')

  return gradient
}

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const options: ChartOptions<'line'> = {
  responsive: true,
  scales: {
    y: {
      border: {
        display: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      position: 'nearest',
      enabled: false,
      external: externalTooltipHandler,
    },
    datalabels: {
      display: false,
    },
  },
}

const labels = ['11-12-2022', '12-12-2022', '13-12-2022', '14-12-2022', '15-12-2022', '16-12-2022', '16-12-2022']

export const data: ChartData<'line', number[], string> = {
  labels,
  datasets: [
    {
      tension: 0.4,
      data: [12, 123, 123, 100, 40, 60, 70],
      fill: true,
      borderColor: '#64B5F6',
      pointRadius: 5,
      pointBackgroundColor: '#64B5F6',
      pointStyle: 'circle',
    },
  ],
}

export interface IAreaChartData {
  label: string
}

export const AreaCharBase = () => {
  const chartRef = useRef<any>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  })

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    }

    setChartData(chartData)
  }, [])

  return <Line ref={chartRef} options={options} data={chartData} />
}

export const AreaChart = React.memo(AreaCharBase)
