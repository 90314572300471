import { Collapse, Empty, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import * as React from 'react'
import { apis, OrderItem } from '../../../apis'
import imagesAssets from '../../../assets/imgs'
import { formatDate, mobileAndTabletCheck } from '../../../utils'
import { formatCurrency } from '../../../utils/format'
import './styles.scss'

const { Panel } = Collapse

enum EProductOrder {
  waiting = 'new',
  delivering = 'in_delivery',
  done = 'completed',
  cancel = 'cancelled',
  approved = 'approved',
  in_progress = 'in_progress',
}

const orderStatusLabel = {
  [EProductOrder.waiting]: 'Chờ duyệt',
  [EProductOrder.delivering]: 'Đang giao',
  [EProductOrder.done]: 'Hoàn tất',
  [EProductOrder.cancel]: 'Đã hủy',
  [EProductOrder.approved]: 'Đã duyệt',
  [EProductOrder.in_progress]: 'Đang chuẩn bị',
}

const columns: ColumnsType<OrderItem> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 60,
    render: (_text, _record, index) => {
      return index + 1
    },
  },
  {
    title: 'Đơn hàng',
    dataIndex: 'name',
    key: 'name',
    render: (_text, _record) => {
      return _record.products.map((item) => item.name).join(', ')
    },
  },
  {
    title: 'Thời gian',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    align: 'center',
    render: (_text) => {
      return formatDate(_text)
    },
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'total',
    key: 'price',
    align: 'center',
    render: (_text, _record) => {
      return formatCurrency(_text)
    },
  },
  {
    title: 'Giảm giá',
    dataIndex: 'pointUsed',
    key: 'pointUsed',
    align: 'center',
    render: (_text, _record) => {
      return (
        <>
          <div>- {formatCurrency(_record.total_promotion_discount)}</div>
        </>
      )
    },
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (_text: EProductOrder, _record) => {
      return (
        <>
          <div className={`orderStatus ${_text}`}>{orderStatusLabel[_text] ?? ''}</div>
        </>
      )
    },
  },
]

interface Props {
  orders: OrderItem[]
}

const HistoryPurchase: React.FC<Props> = ({ orders }) => {
  const renderHistoryItem = (item: OrderItem) => {
    return (
      <Panel header={<span className='font-bold bg-white'>{item.customer_name}</span>} key={item._id}>
        <p className='text-[#939393]'>
          Số lượng: <span className='text-[#030303] ml-2'>{item.total}</span>
        </p>
        <p className='text-[#939393]'>
          Đơn giá:
          <span className='text-[#030303] ml-2'>{formatCurrency(item.total)}</span>
        </p>
        <p className='text-[#939393]'>
          Điểm sử dụng:
          <span className='text-[#030303] ml-2'>{item.total}</span>
          <span className='text-[#939393] ml-2'>(- {formatCurrency(item.total)})</span>
        </p>
        <p className='text-[#939393]'>
          Trạng thái:{' '}
          <span className='ml-2 text-[#030303]'>{orderStatusLabel[item.status as EProductOrder] ?? ''}</span>
        </p>
      </Panel>
    )
  }

  const renderHistory = () => {
    return (
      <Collapse className='bg-white border-0' expandIconPosition='end'>
        {orders && orders.map((item) => renderHistoryItem(item))}
      </Collapse>
    )
  }

  if (mobileAndTabletCheck) {
    return (
      <div>
        <div>
          <div className='modalContext'>
            {!orders || orders.length === 0 ? (
              <Empty image={imagesAssets.empty} description='Bạn chưa đặt sản phẩm nào' />
            ) : (
              renderHistory()
            )}
          </div>
        </div>
      </div>
    )
  }

  return <Table scroll={{ y: 456 }} bordered dataSource={orders} columns={columns} pagination={false} />
}

export default HistoryPurchase
