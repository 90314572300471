import * as React from 'react'
import { Button } from 'antd'
import './styles.scss'
import { ButtonProps } from 'antd/lib/button'

const CancelButton: React.FC<ButtonProps> = ({ className, ...other }) => {
  return (
    <Button className={`cancelBtn ${className ?? ''}`} {...other}>
      {other.children || 'Submit'}
    </Button>
  )
}

export default CancelButton
