export enum ModalType {
  signIn,
  signUp,
  close,
  thanks,
  redeemHistory,
  search,
  purchasedVideos,
  purchaseVideo,
}
