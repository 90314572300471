import { AimOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { InputRef, SearchProps } from 'antd/lib/input'
import React from 'react'
import Svg from '../Svg'

import './styles.scss'

type Props = SearchProps

const SearchField = React.forwardRef<InputRef, Props>((props, ref) => {
  return (
    <Input.Search
      ref={ref}
      size='large'
      allowClear={{ clearIcon: <Svg.Close /> }}
      enterButton={
        <Button>
          <Svg.Search color='#030303' />
        </Button>
      }
      className='searchField'
      placeholder='Tìm kiếm'
      {...props}
    />
  )
})

export default SearchField
