/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { Col, Row, Select } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { concat, isEmpty } from 'lodash'
import pathUrl from '@routes/path'
import InfiniteScroll from 'react-infinite-scroller'
import { ClockCircleOutlined, CloseCircleOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons'

import './Lessons.scss'
// components
import { hideModal, showModal } from '@managers/GlobalModal'
import MessageModal from '@components/Modal/MessageModal'
import { EmptyComponent } from '@components/Empty/EmptyBase'
import { TruncatedText } from '@components/Truncate'
import Svg from '@components/Svg'
import LoadingBase from '@components/Loading/LoadingBase'

// utils
import Logger from '@managers/Logger'
import ItemData from '@containers/Statistical/components/ItemData'
import svgAssets from '@assets/svg'
import { formatDate } from '@utils/index'
import { useTranslation } from 'react-i18next'
import { translateHelper } from '@lang/langManager'

// local
import VideoLessonsItem from '../../components/VideoLessonsItem/VideoLessonsItem'
import OutLessonsModal from './OutLessonsModal'
import { classesAPIs, ESortVideos, EVideoStatus, IClass, IVideoClassesPayload } from '../../api'
import videoClassesAtom, { VIDEOS_LIMIT_PAGE } from '../../recoil/videoClassesAtom'
import classesDetailAtom from '../../recoil/classesDetailAtom'

export const messageDetailLessons = (errorKey: string, classDetail: IClass) => {
  switch (errorKey) {
    case 'ERROR_PENDING_REQUEST':
      return {
        icon: <ClockCircleOutlined style={{ color: 'orange', fontSize: '24px' }} />,
        title: translateHelper('overview_class.overview_pending_message_title'),
        content: translateHelper('overview_class.overview_pending_message_content'),
      }
      break

    case 'ERROR_REJECTED_REQUEST':
      return {
        icon: <CloseCircleOutlined style={{ fontSize: '24px', color: 'red' }} />,
        title: translateHelper('overview_class.overview_reject_message_title'),
        content: translateHelper('overview_class.overview_reject_message_content'),
      }

    case 'ERROR_DROPPED_REQUEST':
      return {
        icon: <ClockCircleOutlined style={{ color: 'orange', fontSize: '24px' }} />,
        title: translateHelper('overview_class.dropped_title'),
        content: translateHelper('overview_class.dropped_content'),
      }
      break

    case 'ERROR_CANCELLED_REQUEST':
      return {
        icon: <WarningOutlined style={{ fontSize: '24px', color: 'red' }} />,
        title: translateHelper('overview_class.dropped_title'),
        content: translateHelper('overview_class.dropped_content'),
      }
      break

    case 'ERROR_CLASS_NOT_FOUND':
      return {
        icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '24px' }} />,
        title: translateHelper('overview_class.not_found_title'),
        content: translateHelper('overview_class.not_found_content'),
      }
      break

    case 'ERROR_CLASS_NOT_STARTED':
      return {
        icon: <WarningFilled style={{ color: 'orange', fontSize: '24px' }} />,
        title: translateHelper('overview_class.overview_not_yet_started_title'),
        content: translateHelper('overview_class.overview_not_yet_started_content', {
          time: formatDate(classDetail?.started_at),
        }),
      }
      break

    default:
      return {
        icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '24px' }} />,
        title: translateHelper('overview_class.not_found_title'),
        content: translateHelper('overview_class.not_found_content'),
      }
      break
  }
}

const tabs = () => [
  {
    key: EVideoStatus.INCOMPLETE,
    label: translateHelper('detail_class.detail_class_unlearned_lesson'),
  },
  {
    key: EVideoStatus.COMPLETED,
    label: translateHelper('detail_class.detail_class_learned_lessons'),
  },
]

const items = () => [
  {
    label: translateHelper('detail_class.detail_class_newest'),
    value: ESortVideos.NEWEST,
  },
  {
    label: translateHelper('detail_class.detail_class_oldest'),
    value: ESortVideos.OLDEST,
  },
  {
    label: translateHelper('detail_class.detail_class_A_Z'),
    value: ESortVideos.NAME_ASC,
  },
  {
    label: translateHelper('detail_class.detail_class_Z_A'),
    value: ESortVideos.NAME_DESC,
  },
]

const Lessons = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const history = useHistory()

  const [videoClasses, setVideoClasses] = useRecoilState(videoClassesAtom)
  const [classDetail, setClassDetail] = useRecoilState(classesDetailAtom)

  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const [loadingDetail, setLoadingDetail] = useState(false)

  const tabActive = videoClasses?.filter?.status

  const getVideos = async (page: number) => {
    setHasMore(false)
    try {
      const payload: IVideoClassesPayload = {
        status: tabActive,
        sort_by: videoClasses?.filter?.sort,
        page_limit: videoClasses.filter?.page_limit,
        page,
      }

      const response = await classesAPIs.getVideoClasses(id, payload)

      setVideoClasses((values) => ({
        ...values,
        videos: concat(values.videos, response.videos ?? []),
        total: response?.total_rows,
      }))

      if (response?.videos?.length < VIDEOS_LIMIT_PAGE) {
        setHasMore(false)
      } else {
        setCurrentPage(page + 1)
        setHasMore(true)
      }

      Logger.info('getVideos', response)
    } catch (error) {
      Logger.error('getVideos', error)
    }
  }

  const handleOpenHistoryModal = () => {
    setShowHistoryModal(true)
  }

  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false)
  }

  const handleClickTab = (item: EVideoStatus) => {
    setVideoClasses((values) => ({
      ...values,
      filter: {
        ...values?.filter,
        status: item,
      },
    }))
  }

  const handleClickOut = () => {
    handleOpenHistoryModal()
  }

  const handleChangeSort = (valueSelect: ESortVideos) => {
    setVideoClasses((values) => ({
      ...values,
      filter: {
        ...values.filter,
        sort: valueSelect,
      },
    }))
  }

  const loader = (
    <div key='loader' className='loader'>
      Loading ...
    </div>
  )

  const fetchMoreData = async () => {
    if (!hasMore || videoClasses?.videos.length === 0) {
      return
    }

    await getVideos(currentPage)
  }

  const renderHeader = () => {
    if (loadingDetail) {
      return <LoadingBase />
    }

    return (
      <div className='lessons-header px-7 py-10 relative pb-[6rem]'>
        <div className=''>
          <div className='flex flex-row justify-between'>
            <div className='font-[500] text-2xl'>{classDetail?.class?.name}</div>
            <div className='cursor-pointer ml-3' onClick={handleClickOut}>
              <Svg.Exit color='#FFFFFF' />
            </div>
          </div>
          <div className=' flex flex-row mt-3'>
            <div>
              {t('detail_class.detail_class_organizer')}:{' '}
              <span className='font-semibold'>{classDetail?.class?.organizer?.name}</span>
            </div>
            <div className='w-[2px] bg-[#CCCCCC] mx-4' />
            <div>
              {t('detail_class.detail_class_organizer_day')}:{' '}
              <span className='font-semibold'>{formatDate(classDetail?.class?.started_at) || '...'}</span>
            </div>
            <div className='w-[2px] bg-[#CCCCCC] mx-4' />
            <div>
              {t('detail_class.detail_class_end_day')}:{' '}
              <span className='font-semibold'>{formatDate(classDetail?.class?.finished_at) || '...'}</span>
            </div>
          </div>
          <div className='mt-3 mb-5'>
            <TruncatedText text={classDetail?.class?.description} lines={1} />
          </div>
        </div>
        <Row
          className='pt-5 w-full -mb-44'
          gutter={[
            { xs: 16, lg: 30, md: 16 },
            { xs: 16, lg: 0, md: 16 },
          ]}
        >
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              dataClassName='text-[#F9A621]'
              nameClassName='data-item'
              name={
                <div className='flex items-center'>
                  {t('detail_class.detail_class_total_score')}
                  <span className='ml-2'>
                    <Svg.Info />
                  </span>
                </div>
              }
              data={
                <div className='flex flex-row flex-wrap items-center'>
                  <span className='text-[#F9A621] mr-3'>{classDetail?.user?.total_score}</span>
                </div>
              }
              color='#F9A621'
              img={svgAssets.Score}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#E13B19]'
              name={t('detail_class.detail_class_avg_concern_level')}
              data={`${classDetail?.user?.concentration_level}%`}
              color='#E13B19'
              img={svgAssets.Video}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#34A853]'
              name={t('detail_class.detail_class_total_view')}
              data={classDetail?.user?.total_views}
              color='#34A853'
              img={svgAssets.Timing}
            />
          </Col>
          <Col xs={24} lg={6} className='gutter-row text-left item-data'>
            <ItemData
              nameClassName='data-item'
              dataClassName='text-[#1C62B9]'
              name={t('detail_class.detail_class_number_lesson_learned')}
              data={classDetail?.user?.num_lessons_completed}
              color='#1C62B9'
              img={svgAssets.Timing}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const renderTabType = () => {
    return (
      <div className='mt-32 mx-7 flex justify-between'>
        <div className='flex flex-row tab-wrapper flex-1'>
          {tabs().map((item) => (
            <div
              key={item.key}
              onClick={() => handleClickTab(item.key)}
              className={`type-item ${tabActive === item.key ? 'type-active' : ''}`}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className='flex items-center item-sort'>
          <span className='pr-3'>{translateHelper('detail_class.detail_class_sort')}:</span>
          <Select
            onChange={handleChangeSort}
            defaultValue={items()[0].value}
            style={{ width: 120 }}
            bordered={false}
            options={items()}
            value={videoClasses?.filter?.sort}
            className='bold'
          />
        </div>
      </div>
    )
  }

  const renderVideos = () => {
    return (
      <div className='mt-7 mx-7'>
        <div className=''>
          <span className='text-[#1F91FA] font-[700]'>{videoClasses?.total}</span>{' '}
          <span className='text-[#606060]'>{translateHelper('detail_class.detail_class_lesson')}</span>
        </div>
        <div>
          <InfiniteScroll loadMore={fetchMoreData} hasMore={hasMore} loader={loader}>
            <Row className='py-4' gutter={[20, 20]}>
              {videoClasses?.videos.length > 0 ? (
                videoClasses?.videos.map((video) => (
                  <Col key={video.id} className='gutter-row' span={12} xs={24} md={12} lg={8} xl={6}>
                    <VideoLessonsItem videoDetail={video} classes={classDetail} />
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </InfiniteScroll>
        </div>
      </div>
    )
  }

  const renderHistoryModal = () => {
    return <OutLessonsModal idClass={id} onClose={handleCloseHistoryModal} showHistoryModal={showHistoryModal} />
  }

  const renderEmpty = () => {
    return <EmptyComponent description={translateHelper('detail_class.empty_class')} />
  }

  const renderContent = () => {
    if (videoClasses?.loading) {
      return <LoadingBase />
    }

    if (isEmpty(videoClasses?.videos)) {
      return renderEmpty()
    }

    return renderVideos()
  }

  const closeModal = () => {
    history.push(pathUrl.classroom())
    hideModal()
  }

  useEffect(() => {
    const getClassDetail = async () => {
      setLoadingDetail(true)
      try {
        const response = await classesAPIs.detailClasses(id)

        setClassDetail(response)
      } catch (error: any) {
        Logger.error('error getClassDetail', error)

        const errorItem = messageDetailLessons(error.message, error?.data?.class)

        showModal({
          content: (
            <MessageModal
              title={errorItem.title}
              content={<p dangerouslySetInnerHTML={{ __html: errorItem.content }} />}
              icon={errorItem.icon}
              onClose={closeModal}
            />
          ),
          onCancel: closeModal,
        })
      }
      setLoadingDetail(false)
    }

    getClassDetail()
  }, [])

  useEffect(() => {
    const initApi = async () => {
      setVideoClasses((values) => ({
        ...values,
        videos: [],
        loading: true,
      }))

      await getVideos(1)

      setVideoClasses((values) => ({
        ...values,
        loading: false,
      }))
    }
    initApi()
  }, [videoClasses?.filter?.status, videoClasses?.filter?.sort])

  if (isEmpty(classDetail)) {
    return <LoadingBase />
  }

  return (
    <Row className='bg-[#ffffff] lessons'>
      <Col span={24} className='min-h-screen'>
        {renderHeader()}
        {renderTabType()}
        {renderContent()}
        {renderHistoryModal()}
      </Col>
    </Row>
  )
}

export default Lessons
