import { translateHelper } from '@lang/langManager'
import { UpdatePasswordResponse } from '../auth.apis'

export const messageResponse = ({ message }: UpdatePasswordResponse) => {
  let result = ''

  switch (message) {
    case 'INVALID_TOKEN':
      result = `${translateHelper('auth.invalid_token')}`
      break

    case 'PASSWORD_CONFIRMATION_NOT_MATCH':
      result = `${translateHelper('auth.mathPassword')}`
      break

    case 'PASSWORD_NOT_STRONG_ENOUGH':
      result = `${translateHelper('auth.passwordNotStrongEnough')}`
      break

    case 'TOKEN_EXPIRED':
      result = `${translateHelper('auth.tokenExpired')}`
      break

    default:
      break
  }

  return result
}
