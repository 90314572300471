import React from 'react'
import CountUp from 'react-countup'

import { DoughnutChart, IDoughnutData } from '../../../components/Chart'
import ViewTitle from './ViewTitle'

const ConcentrationLevel = () => {
  const fakeData: IDoughnutData[] = [
    {
      value: 95,
      bgColor: '#64B5F6',
      label: 'blue',
    },
    {
      value: 5,
      bgColor: '#BBDEFB',
      label: 'blue-light',
    },
  ]

  return (
    <div className='flex flex-col h-full'>
      <ViewTitle title='Mức độ tập trung' />
      <div className='bg-[white] p-7 flex-[1] flex justify-around items-center'>
        <div className='max-h-[200px] relative'>
          <DoughnutChart data={fakeData} />
          <div className='absolute top-[calc(50%-10px)] w-full text-center select-none'>
            <CountUp end={80} duration={1} />%
          </div>
        </div>
      </div>
      <div className='py-3 px-7 text-[#606060] bg-[#F9F9F9] rounded-b-[10px]'>
        Tỷ lệ được tính dựa trên kết quả bấm nút Deepview và trả lời câu hỏi Quiz.
      </div>
    </div>
  )
}

export default ConcentrationLevel
