import React from 'react'
import imagesAssets from '@assets/imgs'

import Svg from '@components/Svg'
import PrimaryBtn from '@components/Button/PrimaryBtn'
import { useTranslation } from 'react-i18next'

export enum EnoughType {
  FREE = 'free',
  AVAILABLE = 'available',
}

interface Props {
  onCloseModal: () => void
}

const PaidPlanModal: React.FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onCloseModal()
  }

  return (
    <div className='limit-number-wrapper'>
      <div className='title text-[#3083ff] bg-cover flex flex-col justify-center items-center relative'>
        <div className='flex justify-center  '>
          <img src={imagesAssets.humaaans3characters} alt='zz' />
        </div>
        <div className='absolute right-0 top-0 cursor-pointer' onClick={handleClose}>
          <Svg.Close />
        </div>
      </div>

      <div className='font-bold text-4xl text-center text-[#000000]'>{t('plan.submitted')}</div>

      <div className='text-center mt-4'>{t('plan.submittedContent')}</div>

      <div className='flex justify-center gap-6 mb-7 mt-4'>
        <PrimaryBtn
          className='uppercase !font-normal min-w-[200px] !bg-[#2864E9] py-2 h-fit px-12'
          onClick={handleClose}
        >
          {t('plan.close')}
        </PrimaryBtn>
      </div>
    </div>
  )
}

export default PaidPlanModal
