export default {
  'auth.checkResetPassword': 'Vui lòng kiểm tra email để tiến hành khôi phục lại mật khẩu của bạn!',
  'auth.invalid_token': 'Token không đúng.',
  'auth.tokenExpired': 'Token hết hạn',
  'auth.passwordNotStrongEnough': 'Mật khẩu không đủ mạnh.',
  'auth.emailNotVerified': 'Vui lòng vào email để kích hoạt tài khoản.',
  'signUp.userExists': 'Tài khoản đã được sử dụng.',
  'auth.mathPassword': 'Xác nhận mật khẩu không khớp',
  'auth.updatePassword': 'Cập nhật mật khẩu mới',
  'auth.updateSuccessPassword': 'Cập nhật mật khẩu thành công',
}
